import React from 'react'
import {
  Container,
  InputContainer,
  Bar,
  Title,
  InputElement,
  Spacer,
  InnerContainer,
} from './style'

export type IProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  id?: string[],
  totalInput?: number[],
  totalValue?: number[],
  inputPlaceholder?: string[],
  pattern?: string,
  value: string,
  bar: boolean,
  alt?: boolean,
  label?: string,
  hasErrored?: boolean,
}

export const DOBFormInput: React.FC<IProps> = ({
  id = ['day', 'month', 'year'],
  totalInput = [2, 2, 4],
  totalValue = [31, 12, new Date().getFullYear()],
  inputPlaceholder = ['DD', 'MM', 'YYYY'],
  onChange,
  onBlur,
  onFocus,
  pattern,
  value,
  bar,
  alt,
  label,
  hasErrored,
}) =>
  (
    <Container className={alt ? 'alt' : ''}>
      {alt && (<Title>{label}</Title>)}
      <InnerContainer className={alt ? 'alt' : ''}>
        {id.map((i, index) => (
          <React.Fragment key={id[index]}>
            <InputContainer className={alt ? 'alt' : ''} key={`${id[index]}${inputPlaceholder[index]}`}>
              <InputElement
                id={i}
                dataUxId={`Input_DOB_${id[index]}`}
                type="tel"
                maxLength={totalInput[index]}
                maxvalue={totalValue[index]}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                pattern={pattern}
                required
                placeholder={inputPlaceholder[index]}
                tabIndex={0}
                value={value && value[index]}
                hasErrored={hasErrored}
              />
              {bar && <Bar />}
            </InputContainer>
            {!alt && (<Spacer id="dobSpacer">-</Spacer>)}
          </React.Fragment>
        ))}
      </InnerContainer>
    </Container>
  )
