/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React from 'react'
import { Btn, BtnWrap } from './style'

export interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  wrapper?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  kind?: 'primary' | 'underline' | 'ghost' | 'clear' | 'tabGhost' | 'secondary' | 'secondaryGhost',
  as?: 'div' | 'a' | 'link' | 'button',
  to?: string,
  href?: string,
  type?: 'submit' | 'reset' | 'button'
  fixed?: boolean,
  width?: string,
  children: React.ReactNode,
  className?: string,
  style?: object,
  id?: string,
  css?: string,
  inline?: boolean,
  space?: boolean,
  dataUxId?: string,
  disabled?: boolean
  newWindow?: boolean,
}

export const Button: React.FC<IProps & React.RefAttributes<HTMLButtonElement>> = React.forwardRef<HTMLButtonElement, IProps>(
  ({
    wrapper = true,
    onClick,
    kind = 'primary',
    as = 'button',
    to,
    href,
    type,
    fixed,
    width,
    children,
    className = '',
    style,
    id,
    css,
    inline,
    space,
    dataUxId,
    disabled = false,
    newWindow,
    ...props
  }, ref) => wrapper ? (
    <BtnWrap fixed={fixed} className={fixed ? 'fixed' : ''} style={{ display: (inline ? 'inline' : 'block'), width: (inline ? '100%' : 'auto'), marginRight: (space ? '15px' : '0') }}>
      <Btn
        id={`${id}`}
        className={className}
        onClick={onClick}
        kind={kind}
        to={to}
        href={href}
        as={as}
        width={width}
        type={type}
        style={style}
        css={css}
        dataUxId={dataUxId}
        inline={inline}
        disabled={disabled}
        rel="noreferrer noopener"
        ref={ref}
        target={newWindow ? '_blank' : '_self'}
      >
        {children}
      </Btn>
    </BtnWrap>
  ) : (
      <Btn
        {...props}
        id={`${id}`}
        className={className}
        onClick={onClick}
        kind={kind}
        to={to}
        href={href}
        as={as}
        width={width}
        type={type}
        style={style}
        css={css}
        dataUxId={dataUxId}
        inline={inline}
        disabled={disabled}
        rel="noreferrer noopener"
        ref={ref}
        target={newWindow ? '_blank' : '_self'}
      >
        {children}
      </Btn>
    ),
)

Button.displayName = 'Button'
