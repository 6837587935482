import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'

export interface stepInputTypes {
  step?: {
    fontSize?: string
    fontWeight?: number
  },
  stepContainer?: {
    fontSize?: string
    lineHeight?: string
    color?: string
  },
}

const stepInput: stepInputTypes = {
  step: {
    fontSize: typography.fontSizes.fs35,
    fontWeight: typography.fontWeight.light,
  },
  stepContainer: {
    fontSize: typography.fontSizes.fs19,
    lineHeight: typography.lineHeights.lh24,
    color: colors.raisinBlack,
  },
}

export default stepInput
