import React from 'react'
import PropTypes from 'prop-types'
import Btn from './style'

export interface ButtonNewProps extends React.ComponentPropsWithoutRef<'button'> {
  kind?: 'primary' | 'underline' | 'ghost' | 'clear' | 'tabGhost' | 'secondary' | 'secondaryGhost',
  space?: boolean,
  rel?: string
  children: React.ReactNode
}

export const ButtonNew = React.forwardRef<
  HTMLButtonElement, ButtonNewProps
>(({
  children, kind = 'primary', ...props
}, ref) =>
  (
    <Btn
      kind={kind}
      ref={ref}
      {...props}
    >
      {children}
    </Btn>
  ))

ButtonNew.displayName = 'ButtonNew'

ButtonNew.propTypes = {
  kind: PropTypes.oneOf(['primary', 'underline', 'ghost', 'clear', 'tabGhost', 'secondary', 'secondaryGhost']),
}
