import styled, { css } from 'styled-components'
import Input from 'components/Atoms/Input'

type SProps = {
  labelPosition: string,
  hasErrored?: boolean,
  value?: string | string[]
}

const StyledInput = styled(Input) <SProps>`
  ${({ theme: { components: { inputWithLabelAbove: { input } } } }) => css`
    border-radius: ${input.borderRadius};
    padding: ${input.padding};
    border: ${input.border};
    font-size: ${input.fontSize};
    background: ${input.background};
  `}
  width: 100%;
  text-align: left;
  margin: 0;
  -moz-appearance:textfield;
  -webkit-appearance: none;
  &:required, &:invalid {
    outline: none;
    box-shadow: none;
  }
  &:valid + svg {
    background: ${({ theme }) => theme.components.inputWithLabelAbove.input.backgroundValid}
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Label = styled.label<SProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ theme: { components: { inputWithLabelAbove: { label } } } }) => css`
    font-size: ${label.fontSize};
    color: ${label.color};
  `}
  > div {
    ${({ theme: { components: { inputWithLabelAbove: { label } } } }) => css`
      padding: ${label.padding};
    `}
    ${props => props.labelPosition === 'top' || props.labelPosition === 'none' ? css`
      flex: 1 0 100%;
    ` : css`
      flex: 1 0 90%;
    `}
    ${props => props.labelPosition === 'none' && `
      padding: 0;
    `}
  }
  > span {
    ${({ theme: { components: { inputWithLabelAbove: { label: { _span } } } } }) => css`
      font-size: ${_span.fontSize};
      line-height: ${_span.lineHeight};
      text-align: ${_span.textAlign};
      padding: ${_span.padding};
    `}
    flex: 1 0 auto;
    ${props => props.labelPosition === 'top' || props.labelPosition === 'none' ? css`
      flex: 1 0 100%;
    ` : css`
      flex: 1 0 auto;
    `}

  }

`

const Red = styled.span`
  color: ${({ theme }) => theme.components.inputWithLabelAbove.red.color};
`

export {
  StyledInput,
  Label,
  Red,
}
