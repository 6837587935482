import React from 'react'
import { CheckboxItem } from './CheckboxItem'
import { CheckboxWrapper, Container } from './style'

type ItemProps = {
  title: string,
  value: string,
  checked: boolean,
}

export type CheckboxSelectorProps = {
  items: any,
  onChange: () => void,
  label: string,
  content: string,
  id: string,
}

export const CheckboxSelector: React.FC<CheckboxSelectorProps> = ({
  items,
  onChange,
  label,
  content,
  id,
}) => (
  <CheckboxWrapper id={`CheckboxSelector_${id}`}>
    <span>{label}</span>
    <span>{content}</span>
    <Container id={id}>
      {items && items.map((i: ItemProps) => (
        <CheckboxItem
          key={i.title}
          label={i.title}
          value={i.value}
          kind="primary"
          checked={i.checked}
          onChange={onChange}
          id={`${i.value}-checkbox`}
        />
      ))}
    </Container>
  </CheckboxWrapper>
)
