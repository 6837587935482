import React from 'react'
import { isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import { Rating, TrustPilotLogo, TrustpilotStars } from './style'

export type TrustpilotRatingProps = {
  text: string
  hideRatingText?: boolean
  reviewsUrl: string
  alternativeStyle?: boolean
  data: {
    starsImageUrl: string
    numberOfStars: string
    ratingText: string
    trustpilotLogoUrl: string
  }
  id?: string
}

const TrustpilotRating = ({
  text,
  hideRatingText,
  data,
  reviewsUrl = 'https://uk.trustpilot.com/review/beaglestreet.com',
  alternativeStyle,
  id = 'trustpilot',
}: TrustpilotRatingProps) => {
  if (isUndefined(data)) return null

  const {
    starsImageUrl, numberOfStars, ratingText, trustpilotLogoUrl,
  } = data

  return (
    <React.Fragment>
      <a id={`${id}_logoLink`} href={reviewsUrl} target="_blank" rel="noopener noreferrer" data-testid="tpLogoUrl">
        <TrustPilotLogo id={`${id}_tpLogo`} data-testid="tpLogoImg" className="tpLogoImage" src={trustpilotLogoUrl} alt="Trustpilot" />
      </a>
      <TrustpilotStars id={`${id}_tpStarsLink`} href={reviewsUrl} target="_blank" rel="noopener noreferrer" data-testid="tpStarsRating">
        <img id={`${id}_tpStarsImg`} data-testid="tpStarsImg" src={starsImageUrl} className="tpStarsImage" alt={`Trustpilot ${numberOfStars} star rating`} />
      </TrustpilotStars>
      {!hideRatingText && (
        <Rating id={`${id}_text`} alternativeStyle={alternativeStyle}>
          {`${text} `}
          <span>&quot;{ratingText}&quot;</span>
        </Rating>
      )}
    </React.Fragment>
  )
}

TrustpilotRating.propTypes = {
  text: PropTypes.string,
  hideRatingText: PropTypes.bool,
  data: PropTypes.object,
  reviewsUrl: PropTypes.string,
  alternativeStyle: PropTypes.bool,
  id: PropTypes.string,
}

export default TrustpilotRating
