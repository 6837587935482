import colors from 'themes/tokens/colors'
import typeography from 'themes/tokens/typography'
import radius from 'themes/tokens/radius'
import alignment from 'themes/tokens/alignment'
import borders from 'themes/tokens/borders'

export interface formInputTypes {
  labelActiveState?: {
    background?: string
    color?: string
    borderRadius?: string
    fontStyle?: string
    fontWeight?: number
    fontSize?: string
    lineHeight?: string
    borderColor?: string
    svgFill?: string
  },
  item?: {
    lineHeight?: string
    textAlign?: string
    background?: string
    border?: string
    borderRadius?: string
    fontStyle?: string
    fontWeight?: number
    fontSize?: string
    color?: string
    fontSizeSmall?: string
    _hover?: {
      backgroundSmall?: string
      colorSmall?: string
    },
  },
  selectedIcon: {
    border?: string
    borderRadius?: string
    padding: string
    background: string
    fill: string
  },
}

const formInput: formInputTypes = {
  labelActiveState: {
    background: colors.japaneseIndigo,
    color: colors.white,
    borderRadius: radius.r25,
    fontStyle: typeography.fontStyle.normal,
    fontWeight: typeography.fontWeight.bold,
    fontSize: typeography.fontSizes.fs18,
    lineHeight: typeography.lineHeights.lh27,
  },
  item: {
    lineHeight: typeography.lineHeights.lh27,
    textAlign: alignment.text.center,
    background: colors.white,
    border: `1px solid ${colors.japaneseIndigo}`,
    borderRadius: radius.r25,
    fontStyle: typeography.fontStyle.normal,
    fontWeight: typeography.fontWeight.bold,
    fontSize: typeography.fontSizes.fs18,
    color: colors.japaneseIndigo,
    fontSizeSmall: typeography.fontSizes.fs18,
    _hover: {
      backgroundSmall: colors.white,
      colorSmall: colors.black,
    },
  },
  selectedIcon: {
    border: borders.none,
    borderRadius: radius.none,
    padding: '0',
    background: 'none',
    fill: colors.white,
  },
}

export default formInput
