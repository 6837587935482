import React from 'react'
import { Transition } from 'react-transition-group'
import { isArray } from 'lodash'
import Error from 'components/Atoms/Error'
import TransitionContainer from './style'

export type IProps = {
  render: React.ReactElement,
  dataUxId: string,
  isErrorVisible: boolean,
  errorMessage: string | object | string[],
  forceModalSubmit: boolean,
}

export const ReplaceWithError: React.FC<IProps> = ({
  render,
  dataUxId,
  isErrorVisible,
  errorMessage,
  forceModalSubmit,
}) =>
  (
    <React.Fragment>
      <Transition in={!isErrorVisible || forceModalSubmit} timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
        {status => (
          <TransitionContainer animation={status}>
            {React.cloneElement(render)}
          </TransitionContainer>
        )}
      </Transition>
      <Transition in={isErrorVisible} timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
        {status => (
          <TransitionContainer animation={status}>
            <Error dataUx={dataUxId}>
              {isArray(errorMessage) ? errorMessage.map(error => <div className="errorMessage" key={`error${Math.random()}`}>{error}</div>) : errorMessage}
            </Error>
          </TransitionContainer>
        )}
      </Transition>
      {(forceModalSubmit && !isErrorVisible) && (
        <Error dataUx={dataUxId}>
          {errorMessage}
        </Error>
      )}
    </React.Fragment>
  )
