import borders from 'themes/tokens/borders'
import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'
import ThemeBuilder from '../themeBuilder'

const virginMoneyTheme = new ThemeBuilder('VirginMoney')

// global styles will be overwritten by QQ
const themeOverrides = {
  components: {
    inputBreakdown: {
      typography: {
        color: colors.virginMoney.red,
        textColor: colors.virginMoney.onyx,
        guidanceTextColor: colors.virginMoney.onyx,
        buttonColor: colors.virginMoney.bostonUniversityRed,
        iconColor: colors.virginMoney.maximumPurple,
      },
    },
    button: {
      variant: {
        primary: {
          background: colors.virginMoney.red,
        },
        underline: {
          borderColor: colors.virginMoney.bostonUniversityRed,
        },
      },
    },
    pricingCard: {
      wrapperBoxShadow: 'none',
      wrapperBorderRadius: radius.none,
      backgroundColor: colors.virginMoney.brightGray,
      headingColor: colors.virginMoney.charlestonGreen,
      iqPriceColor: colors.virginMoney.bostonUniversityRed,
      paragraphColor: colors.virginMoney.charlestonGreen,
      spanColor: colors.virginMoney.charlestonGreen,
      guidanceMobileTextColor: colors.virginMoney.charlestonGreen,
    },
    formInput: {
      labelActiveState: {
        background: colors.virginMoney.cultured,
        color: colors.virginMoney.bostonUniversityRed,
        borderColor: colors.virginMoney.platinum,
      },
      item: {
        background: colors.virginMoney.cultured,
        border: borders.border1pxPlatinum,
        color: colors.virginMoney.onyx,
        _hover: {
          backgroundSmall: colors.virginMoney.cultured,
          colorSmall: colors.virginMoney.onyx,
        },
      },
      selectedIcon: {
        border: borders.border1pxElectricRed,
        borderRadius: radius.r30,
        padding: '4px',
        background: colors.virginMoney.electricRed,
        fill: colors.white,
      },
    },
    inputBox: {
      poundSign: {
        color: colors.virginMoney.red,
      },
      color: colors.virginMoney.onyx,
    },
  },
}

virginMoneyTheme.merge(themeOverrides)

export default virginMoneyTheme
