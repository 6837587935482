import alignment from 'themes/tokens/alignment'
import borders from 'themes/tokens/borders'
import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'

export interface polarQuestionTypes {
  container?: {
    borderTop?: string
    paddingTop?: string
  },
  paragraph?: {
    fontSize?: string
    fontWeight?: number
    lineHeight?: string
    color?: string
  },
  subParagraph?: {
    color?: string
    fontSize?: string
  },
  questionWrapper?: {
    svgHoverAndFocus?: string
    labelCheckedColor?: string
    svgSelectedCircleFill?: string
    svgSelectedFill?: string
    svgSecondFill?: string
    svgTickGrow?: string
    uncheckedCircleStroke?: string
    checkedCircleStroke?: string
    svgUncheckedDiagonalFill?: string
    svgCheckedDiagonalFill?: string
    activeHoverFill?: string | undefined
    selectedOutlineFill?: string,
  },
  iconText?: {
    textAlign?: string
    fontSize?: string
    lineHeight?: string
    fontWeight?: number
    color?: string
    width?: string
  },
}

const polarQuestion: polarQuestionTypes = {
  container: {
    borderTop: borders.border1pxSolidHeather,
    paddingTop: '30px',
  },
  paragraph: {
    fontSize: typography.fontSizes.fs19,
    fontWeight: typography.fontWeight.regular,
    lineHeight: typography.lineHeights.lh24,
    color: colors.raisinBlack,
  },
  subParagraph: {
    color: colors.raisinBlack,
    fontSize: typography.fontSizes.fs16,
  },
  questionWrapper: {
    svgHoverAndFocus: colors.japaneseIndigo,
    labelCheckedColor: colors.japaneseIndigo,
    svgSelectedCircleFill: colors.amazonite,
    checkedCircleStroke: colors.japaneseIndigo, // circle outline when input is checked
    uncheckedCircleStroke: colors.japaneseIndigo, // circle outline when input is not checked
    svgSelectedFill: colors.japaneseIndigo,
    svgSecondFill: colors.amazonite,
    svgTickGrow: colors.amazonite,
    svgUncheckedDiagonalFill: colors.japaneseIndigo,
    svgCheckedDiagonalFill: colors.japaneseIndigo,
    activeHoverFill: undefined,
    selectedOutlineFill: colors.amazonite,
  },
  iconText: {
    textAlign: alignment.text.center,
    fontSize: typography.fontSizes.fs18,
    lineHeight: typography.lineHeights.lh22,
    fontWeight: typography.fontWeight.semibold,
    color: colors.amazonite,
  },
}

export default polarQuestion
