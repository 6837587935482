import styled, { css } from 'styled-components'
import { query } from 'themes'
import Input from 'components/Atoms/Input'

type TextProps = {
  hasErrored?: boolean,
}

const spanMobileText = css`
  @media (min-width: 768px){
    font-size: ${({ theme }) => theme.components.inputWithLabelTransition.spanMobileText.fontSize};
  }
`

const StyledInput = styled(Input)`
  &:focus ~ span {
      top: -40px;
      ${({ theme: { components: { inputWithLabelTransition: { input } } } }) => css`
        font-size: ${input.fontSize};
        color: ${input.color};
      `}
      ${spanMobileText}
    }
    &:focus ~ i {
      width: 100%;
      left: 0;
    }
    &:valid ~ span {
      top: -40px;
      ${({ theme: { components: { inputWithLabelTransition: { input } } } }) => css`
        font-size: ${input.fontSize};
        color: ${input.color};
      `}
      ${spanMobileText}
    }
    &:focus {
      outline: none;
    }
    ${props => props.isDirty && css`
      &:invalid ~ span {
        top: -40px;
        ${({ theme: { components: { inputWithLabelTransition: { input } } } }) => css`
          font-size: ${input.fontSize};
          color: ${input.color};
        `}
        ${spanMobileText}
      }
      &:invalid ~ i {
        width: 100%;
        left: 0;
        ${({ theme: { components: { inputWithLabelTransition: { input } } } }) => css`
          color: ${input.color};
        `}
        ${spanMobileText}
      }
      &.alt {
        ${({ theme: { components: { inputWithLabelTransition: { input } } } }) => css`
          font-size: ${input.altFontSize};
          color: ${input.altColor};
        `}
      }
    `}
`

const Text = styled.span<TextProps>`
  pointer-events: none;
  position: absolute;
  ${({ theme: { components: { inputWithLabelTransition: { text } } } }) => css`
    font-size: ${text.fontSize};
    color: ${text.color};
  `}
  left: 0;
  top: -5px;
  transition: top 250ms ease-out, font-size 250ms ease-out;
  ${spanMobileText}
`

const StyledLabel = styled.label`
  position: relative;
  span {
    white-space: nowrap;
  }
  &.alt {
    .textHolder {
      ${({ theme: { components: { inputWithLabelTransition: { label } } } }) => css`
        font-size: ${label.fontSize};
        color: ${label.color};
      `}
      ${query.combine(['extraSmall', 'small'], css`
        font-size: ${({ theme }) => theme.components.inputWithLabelTransition.label.fontSizeSmall};
      `)}
    }
    input {
      font-size: ${({ theme }) => theme.components.inputWithLabelTransition.label.inputFontSize};
    }
  }
`

export {
  StyledInput,
  StyledLabel,
  Text,
}
