import React from 'react'

const Legend = React.forwardRef<
  HTMLLegendElement, React.ComponentPropsWithoutRef<'legend'>
>(({
  children, ...props
}, ref) => <legend ref={ref} {...props}>{children}</legend>)

Legend.displayName = 'Legend'

export default Legend
