import React from 'react'
import { uniqueIdentifier } from 'utils'
import { Row, Col } from 'components/Atoms/Grid'
import Heading from 'components/Atoms/Heading'
import Paragraph from 'components/Atoms/Paragraph'
import Button from 'components/Atoms/Button'
import InputWithLabelAbove from 'components/Molecules/InputWithLabelAbove'
import ReplaceWithError from 'components/Molecules/FormComponents/ReplaceWithError'
import Asterisk from './style'

export type IProps = {
  changeAddress: () => void,
  onChange: () => void,
  onSubmit: () => void,
  errorVisible: boolean,
  errorMsg: string,
  addressNo: string,
  postCode: string,
  heading: string,
  children?: string,
}

export const ManualAddress: React.FC<IProps> = ({
  changeAddress,
  onChange,
  onSubmit,
  errorVisible,
  errorMsg,
  addressNo,
  postCode,
  heading,
  children,
}) =>
  (
    <Row>
      <Col xs="12">
        <Heading id="manualAddress" align="center">{heading}</Heading>
      </Col>
      <Col xs="12" md={{ span: 7, offset: 3 }} style={{ position: 'relative' }}>
        <InputWithLabelAbove
          id="addressNo"
          validation
          aria-label="House name / No."
          labelText="House name / No."
          labelPosition="top"
          onChange={onChange}
          hasErrored={errorVisible}
          value={addressNo}
          dataUxId={uniqueIdentifier('addressNo')}
        />
      </Col>
      <Col xs="12" md={{ span: 7, offset: 3 }} style={{ position: 'relative' }}>
        <InputWithLabelAbove
          id="Line2"
          aria-label="Street name"
          labelText="Street name"
          labelPosition="top"
          onChange={onChange}
          dataUxId={uniqueIdentifier('streetName')}
        />
      </Col>
      <Col xs="12" md={{ span: 7, offset: 3 }} style={{ position: 'relative' }}>
        <InputWithLabelAbove
          id="Line3"
          aria-label="Second line (optional)"
          labelText="Second line (optional)"
          labelPosition="top"
          onChange={onChange}
          dataUxId={uniqueIdentifier('secondLine')}
        />
      </Col>
      <Col xs="12" md={{ span: 7, offset: 3 }} style={{ position: 'relative' }}>
        <InputWithLabelAbove
          id="Line4"
          aria-label="Third line (optional)"
          labelText="Third line (optional)"
          labelPosition="top"
          onChange={onChange}
          dataUxId={uniqueIdentifier('thirdLine')}
        />
      </Col>
      <Col xs="12" md={{ span: 7, offset: 3 }} style={{ position: 'relative' }}>
        <InputWithLabelAbove
          id="Line5"
          aria-label="County"
          labelText="County"
          labelPosition="top"
          onChange={onChange}
          dataUxId={uniqueIdentifier('fourthLine')}
        />
      </Col>
      <Col xs="12" md={{ span: 7, offset: 3 }} style={{ position: 'relative' }}>
        <InputWithLabelAbove
          id="addressPostCode"
          validation
          aria-label="Postcode"
          labelText="Postcode"
          labelPosition="top"
          onChange={onChange}
          value={postCode}
          dataUxId={uniqueIdentifier('postcode')}
        />
      </Col>
      <Col xs="12" md={{ span: 7, offset: 3 }}>
        <Paragraph id="requiredAsterisk"><Asterisk>*</Asterisk>required</Paragraph>
        {children}
      </Col>
      <Col xs="12" md={{ span: 7, offset: 3 }} style={{ position: 'relative' }}>
        <ReplaceWithError
          forceModalSubmit={false}
          isErrorVisible={errorVisible}
          errorMessage={errorMsg}
          dataUxId={uniqueIdentifier('manualAddress')}
          render={(
            <Button dataUxId={uniqueIdentifier('manualAddressContinue')} onClick={onSubmit} style={{ marginTop: 20 }} id="continue" kind="primary">Continue</Button>
          )}
        />
        <Button id="returnAddressFinderBtn" dataUxId={uniqueIdentifier('manualReturnToFinder')} onClick={changeAddress} kind="underline" style={{ marginTop: 20 }}>Return to address finder</Button>
      </Col>
    </Row>

  )
