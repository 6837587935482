import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'

export interface headingWithIconTypes {
  headingFontSize?: string
  headingLineHeight?: string
  headingColor?: string
  headingFontWeight?: number
}

const headingWithIcon: headingWithIconTypes = {
  headingFontSize: typography.fontSizes.fs20,
  headingLineHeight: typography.lineHeights.lh24,
  headingColor: colors.darkGrey,
  headingFontWeight: typography.fontWeight.bold,
}

export default headingWithIcon
