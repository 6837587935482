import React from 'react'
import { uniqueIdentifier } from 'utils'
import { Col } from 'components/Atoms/Grid'
import Heading from 'components/Atoms/Heading'
import Button from 'components/Atoms/Button'
import DropDown from 'components/Atoms/DropDown'

type item = {
  title: string,
  value: string,
}

export type IProps = {
  changeAddress: () => void,
  onSubmit: () => void,
  onChange: () => void,
  items: item[] | undefined,
  heading: string,
  sessioncamHide?: boolean,
  sessioncamClassList?: string[] | string,
}

export const SelectAddress: React.FC<IProps> = ({
  changeAddress,
  onSubmit,
  onChange,
  sessioncamHide,
  sessioncamClassList,
  items,
  heading,
}) => {
  const DropdownData = [
    {
      title: '',
      value: '',
    },
  ]
  return (
    <div>
      <Col xs="12">
        <Heading align="center" id="selectAddress">
          {heading}
        </Heading>
      </Col>
      <Col xs="12" md={{ span: 6, offset: 3 }} style={{ position: 'relative' }}>
        <DropDown dataUxId={uniqueIdentifier('findAddress')} onChange={onChange} items={items || DropdownData} placeholder="Select your address..." sessioncamHide={sessioncamHide} sessioncamClassList={sessioncamClassList} />
      </Col>
      <Col xs="12" md={{ span: 6, offset: 3 }}>
        <Button dataUxId={uniqueIdentifier('selectAddressContinue')} onClick={onSubmit} style={{ marginTop: 20 }} id="continue" width="212px" kind="primary">Continue</Button>
        <Button dataUxId={uniqueIdentifier('selectReturnToFinder')} onClick={changeAddress} kind="underline" style={{ marginTop: 30 }}>Return to address finder</Button>
      </Col>
    </div>

  )
}
