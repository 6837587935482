import styled from 'styled-components'
import { query } from 'themes'

type PathProps = {
  transparent?: boolean,
  withStroke?: boolean,
}

const PrimaryPath = styled.path<PathProps>`
  fill: ${props => props.transparent ? 'transparent' : props.theme.components.largeIcon.primaryPathFill};
  transition: fill 100ms ease;
`

const LightPath = styled.path`
  fill: ${props => props.theme.components.largeIcon.lightPathFill};
  transition: fill 100ms ease;
`

const CircleFill = styled.path`
  fill: ${props => props.theme.components.largeIcon.circleFill};
  transition: fill 100ms ease;
  stroke: ${props => props.theme.components.largeIcon.circleStroke};
  stroke-width: 2;
`

const CircleFillSmall = styled.circle`
  fill: ${props => props.theme.components.largeIcon.primaryPathFill};
  &.stroke {
    fill: transparent;
    stroke: ${props => props.theme.components.largeIcon.strokeColor};
  }
`

const CircleLine = styled.line`
  stroke: ${props => props.theme.components.largeIcon.circleLineColor};;
`

const CirclePathLine = styled.path`
  stroke: ${props => props.theme.components.largeIcon.circleLineColor};;
`

const GrowPath = styled.path<PathProps>`
  transition: fill 100ms ease;
  fill: ${props => props.transparent ? 'transparent' : props.theme.components.largeIcon.growPathFill};
  stroke: ${props => props.withStroke ? props.theme.components.largeIcon.growPathStroke : 'transparent'};
`

const GrowPathTrans = styled.path`
  fill: transparent;
`

const GrowPathSmall = styled.path<PathProps>`
  transition: fill 100ms ease;
  fill: ${props => props.transparent ? 'transparent' : props.theme.components.largeIcon.shadowFill};
  &.shadowFill {
    fill: ${props => props.theme.components.largeIcon.shadowFill};
  }
  &.stroke {
    stroke: ${props => props.theme.components.largeIcon.strokeColor};
    fill: ${props => props.transparent ? 'transparent' : props.theme.components.largeIcon.strokeColor};
  }
`

const GrowPathWide = styled.path`
  transition: fill 100ms ease;
  stroke-width: 2;
  &.stroke {
    stroke: ${props => props.theme.components.largeIcon.strokeColor};
  }
`

const GrowPathAlt = styled.path`
  transform-origin: center;
  fill: ${props => props.theme.components.largeIcon.primaryPathFill};
  &.stroke {
    stroke: ${props => props.theme.components.largeIcon.strokeColor};
    fill: transparent;
  }
`

const SVG = styled.svg`
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover ${GrowPath}, &:hover ${GrowPathTrans}, &:focus ${GrowPathTrans}, &:focus ${GrowPath} {
    transform-origin: center;
    transform: scale(1.2);
    transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
  }

  &:hover ${GrowPathWide}, &:focus ${GrowPathWide} {
    transform: scale(1.2)  translate(4%, 5%);
    transform-origin: center;
    transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
  }

  &:hover ${GrowPathAlt} {
    transform: scale(1.2);
    transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
    ${query.combine(['xs', 'sm'], `
      transform: none;
    `)}
  }
`

const SVGSmall = styled.svg`
  cursor: pointer;


  &.smallSVG {

    .greyBlueColor {
      fill: ${props => props.theme.components.largeIcon.svgFill};
    }

    .selectedOutline {
      fill: ${props => props.theme.components.largeIcon.strokeColor};
    }

    .stroke::not(.diagonalLine) {
      stroke: ${props => props.theme.components.largeIcon.strokeColor};
    }

    &:hover ${GrowPathSmall} {
      transform-origin: center;
    }

    // add the icon animations only on desktop.
    @media only screen and (min-width: 1025px) {
      &:hover .animate {
        transform-origin: center;
        transform: scale(1.1);
        transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
      }

      &:hover ${PrimaryPath},
      &:focus ${PrimaryPath} {
        transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
              transform-origin: center;
      transform: scale(1.1);
      }

      &:hover ${GrowPathSmall} {
        transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
        transform: scale(1.1);
      }

      &:hover ${GrowPathWide},
      &:focus ${GrowPathWide} {
        transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
        transform: scale(1.2)  translate(4%, 5%);
      }
    }
  }
`
export {
  PrimaryPath,
  LightPath,
  GrowPath,
  GrowPathTrans,
  GrowPathWide,
  SVG,
  SVGSmall,
  GrowPathSmall,
  CircleFill,
  CircleFillSmall,
  CircleLine,
  CirclePathLine,
  GrowPathAlt,
}
