import styled, { css } from 'styled-components'
import { query } from 'themes'
import { componentTypes } from 'themes/themeTypes'
import { ButtonNewProps } from './ButtonNew'

const base = css`
  cursor: pointer;
  display: block;
  border-top: 0;
  border-left: 0;
  border-right:0;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  font-weight: 400;
  appearance: none;
  &:active, &:focus, &:hover {
    transform: translateY(2px);
    outline: 0;
  }
`

const kind = (t: string, theme: componentTypes) => {
  const {
    components:
    {
      buttonNew: {
        variant: {
          underline, ghost, tabGhost, clear, secondary, secondaryGhost, primary,
        },
      },
    },
  } = theme
  switch (t) {
    case 'underline':
      return css`
        color: ${underline.color};
        background: ${underline.background};
        box-shadow: none;
        border: none;
        padding: ${underline.padding};
        text-decoration: underline;
        font-size: ${underline.fontSize};
        display: inline-block;
        &:active, &:focus, &:hover {
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
        }
      `
    case 'ghost':
      return css`
        color: ${ghost.color};
        background: ${ghost.background};
        border: ${ghost.border};
        box-shadow: ${ghost.boxShadow};
        padding: ${ghost.padding};
        &:active, &:focus, &:hover {
          box-shadow: none;
        }
      `
    case 'tabGhost':
      return css`
        color: ${tabGhost.color};
        background: ${tabGhost.background};
        border: ${tabGhost.border}};
        box-shadow: ${tabGhost.boxShadow};
        padding: ${tabGhost.padding};
        font-size: ${tabGhost.fontSize};
        width: ${tabGhost.width};
        &:active, &:focus, &:hover {
          box-shadow: none;
        }
        &.active {
          background: ${tabGhost._active.background};
          color: ${tabGhost._active.color};
          border-color: ${tabGhost._active.borderColor};
          box-shadow: ${tabGhost._active.boxShadow};
        }
      `
    case 'clear':
      return css`
        border: ${clear.border};
        background: ${clear.background};
        font-weight: ${clear.fontWeight};
        font-size: ${clear.fontSize};
        display: inline-block;
        &:active, &:focus, &:hover {
          opacity: ${clear._active.opacity};
          transform: ${clear._active.transform};
        }
      `
    case 'secondary':
      return css`
        background-color: ${secondary.backgroundColor};
        min-width: 90px;
        border: ${secondary.border};
        box-sizing: border-box;
        border-radius: ${secondary.borderRadius};
        color: ${secondary.color};
        font-size: ${secondary.fontSize};
        font-weight: ${secondary.fontWeight};
        line-height: ${secondary.lineHeight};
        text-align: ${secondary.textAlign};
        padding: ${secondary.padding};
        width: ${secondary.width};
        &:disabled, &[disabled] {
          background-color: ${secondary._disabled.backgroundColor};
          border: ${secondary._disabled.border};
          color: ${secondary._disabled.color};
        }
      `
    case 'secondaryGhost':
      return css`
        background-color: ${secondaryGhost.backgroundColor};
        min-width: 90px;
        border: ${secondaryGhost.border};
        box-sizing: border-box;
        border-radius: ${secondaryGhost.borderRadius};
        color: ${secondaryGhost.color};
        font-size: ${secondaryGhost.fontSize};
        font-weight: ${secondaryGhost.fontWeight};
        line-height: ${secondaryGhost.lineHeight};
        text-align: ${secondaryGhost.textAlign};
        padding: ${secondaryGhost.padding};
        width: ${secondaryGhost.width};
      `
    case 'primary':
    default:
      return css`
        font-size: ${primary.fontSize};
        border-bottom: ${primary.borderBottom};
        color: ${primary.color};
        padding: ${primary.padding};
        &:active, &:focus, &:hover {
          border-color: transparent;
        }
        background: ${primary.background};
        border: none;
        border-radius: ${primary.borderRadius};
        font-weight: ${primary.fontWeight};;
        transform: translate(0);
        box-shadow: ${primary.boxShadow};
        max-width: ${primary.maxWidth};
      `
  }
}

const Btn = styled.button<ButtonNewProps>`
  ${base}
  ${props => props.kind && kind(props.kind, props.theme)}
  ${props => props.kind === 'tabGhost' ? 'display:inline;' : ''}
  ${props => props.kind === 'secondary' ? 'display:inline;' : ''}
  ${props => props.kind === 'secondaryGhost' ? 'display:inline;' : ''}
  ${props => props.disabled && `
    background: #B9C4D3;
    &:active, &:focus, &:hover {
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  `}
  ${query.combine(['extraSmall', 'small'], `
    width: 100%;
  `)}
`

export default Btn
