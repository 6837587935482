import * as query from './mediaQueries'
import * as keyframes from './keyframes'
import ThemeBuilder from './themeBuilder'
import beaglestreet from './quickquote/beaglestreet'
import budgetlife from './quickquote/budgetlife'
import virginmoney from './quickquote/virginmoney'

const themes = {
  beaglestreet,
  budgetlife,
  virginmoney,
}

export {
  query,
  keyframes,
  ThemeBuilder,
  themes,
}
