import React from 'react'
import Icon from 'components/Atoms/Icon'

import {
  LabelBox,
  InputHidden,
  Text,
} from './style'

export type CheckboxInputItemProps = {
  label: string,
  value: string,
  kind: string,
  checked: boolean,
  onChange: () => void,
  id: string,
}

export const CheckboxItem: React.FC<CheckboxInputItemProps> = ({
  label,
  value,
  kind,
  checked,
  onChange,
  id,
}) => (
  // Wrapped in a div for firefox flexbox fix, as it does like to flex items that arent div
  <div>
    <InputHidden type="checkbox" name={value} value={value} id={id} checked={checked} onChange={onChange} kind={kind} />
    <LabelBox id={`Checkbox_LabelBox_${id}`} htmlFor={id} kind={kind}>
      <div><Icon kind="check" size="18" /></div>
      <Text>{label}</Text>
    </LabelBox>
  </div>
)
