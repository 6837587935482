import styled, { css } from 'styled-components'
import Input from 'components/Atoms/Input'
import { query } from 'themes'

type IProps = {
  className?: string;
};

type InputProps = {
  pattern?: string;
};

const Container = styled.div`
  &:not(.alt) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    > div {
      display: flex;
    }
    > div > * {
      flex: 1 0 23.333%;
      @supports (flex: 1 0 calc(25% - 10px)) {
        flex: 1 0 calc(25% - 10px);
      }
      display: block;
    }
    > div > *:nth-child(even) {
      flex: 1 0 15%;
      @supports (flex: 1 0 calc(17% - 50px)) {
        flex: 1 0 calc(17% - 50px);
      }
    }
  }

  #dobSpacer {
    &:last-child {
      display: none;
    }
  }
`

const InnerContainer = styled.div`
  &.alt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    > * {
      flex: 1 0 23.333%;
      @supports (flex: 1 0 calc(25% - 10px)) {
        flex: 1 0 calc(25% - 10px);
      }
      display: block;
    }
  }
`

const Title = styled.div`
  margin: 20px 0 5px 0;
  font-size: ${({ theme }) => theme.components.DOB.title.fontSize};
  font-weight: ${({ theme }) => theme.components.DOB.title.fontWeight};
  color: ${({ theme }) => theme.components.DOB.title.color};
`

const InputContainer = styled.div<IProps>`
  position: relative;
  i {
    bottom: -1px;
  }
  &.alt {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    input {
      ${({ theme: { components: { DOB: { inputContainer } } } }) => css`
        color: ${inputContainer.color};
        font-size: ${inputContainer.fontSize};
        text-align: ${inputContainer.textAlign};
        line-height: ${inputContainer.lineHeight};
        border-bottom: ${inputContainer.borderBottom};
      `};
    }
  }
  &:not(.alt) {
    i {
      bottom: 2px;
      ${query.combine(['extraSmall', 'small'], `
        bottom: 22px;
      `)}
    }
  }
`

const InputElement = styled(Input) <InputProps>`
  border: none;
  ${({ theme: { components: { DOB: { inputElement } } } }) => css`
    font-size: ${inputElement.fontSize};
    text-align: ${inputElement.textAlign};
    border-bottom: ${inputElement.borderBottom};
    border-radius: ${inputElement.borderRadius};
    line-height: ${inputElement.lineHeight};
  `};

  padding: 2px 2px 1px;
  margin:0;
  width: 100%;

  &:focus {
    outline: none;
  }
  &:focus ~ i {
    width: 100%;
    left: 0;
    bottom: -1px;
  }
  ${query.combine(['extraSmall', 'small'], css`
    font-size: ${props => props.theme.components.DOB.inputElement.fontSizeSmall};
    line-height: ${props => props.theme.components.DOB.inputElement.lineHeightSmall};
  `)}
`

const Bar = styled.i`
  width: 0%;
  position: absolute;
  left: 50%;
  bottom: -12px;
  height: 3px;
  background: ${props => props.theme.components.DOB.bar.background};
  transition: width 250ms ease-out, left 250ms ease-out;
`

const Spacer = styled.div`
  ${({ theme: { components: { DOB: { spacer } } } }) => css`
    color: ${spacer.color};
    font-size: ${spacer.fontSize};
    text-align: ${spacer.textAlign};
  `};
`

export {
  InputContainer,
  Bar,
  Container,
  Title,
  InputElement,
  Spacer,
  InnerContainer,
}
