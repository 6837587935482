import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'

export interface inputLabelRightTypes {
    inputRightContainer?: {
      fontSize?: string
      lineHeight?: string
      color?: string
    },
    styledInput?: {
      borderBottom?: string
      fontSize?: string
      _guidance?: {
        fontSize?: string
      },
      _validSvg?: {
        background?: string
      },
    },
    text?: {
      fontSize?: string
      _guidance?: {
        fontSize?: string
      },
    },
}

const inputLabelRight: inputLabelRightTypes = {
  inputRightContainer: {
    fontSize: typography.fontSizes.fs19,
    lineHeight: typography.lineHeights.lh24,
    color: colors.raisinBlack,
  },
  styledInput: {
    borderBottom: `1px solid ${colors.pastelGrey}`,
    fontSize: typography.fontSizes.fs42,
    _guidance: {
      fontSize: typography.fontSizes.fs24,
    },
    _validSvg: {
      background: colors.purpureus,
    },
  },
  text: {
    fontSize: typography.fontSizes.fs36,
    _guidance: {
      fontSize: typography.fontSizes.fs16,
    },
  },
}

export default inputLabelRight
