import styled from 'styled-components'
import { query } from 'themes'

type RatingProps = {
  alternativeStyle?: boolean
}

const Rating = styled.div<RatingProps>`
  color: ${props => props.theme.components.trustpilotRating.textColor};
  font-size: 15px;
  font-weight: 900;
  ${props => (props.alternativeStyle && `
    font-weight: normal;
    span {
      color: ${props.theme.components.trustpilotRating.ratingTextColor};
    }
  `)}
  ${query.combine(['extraSmall', 'small', 'medium'], `
    font-size:12px;
  `)}
`

const TrustPilotLogo = styled.img`
  margin-right: 15px;
  width: auto;
`

const TrustpilotStars = styled.a`
  margin-bottom: 0;

  img {
    max-width: 135px;
  }
`

export {
  Rating,
  TrustPilotLogo,
  TrustpilotStars,
}
