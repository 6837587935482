import React from 'react'
import { InputElement, Bar } from './style'

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: string,
  id: string,
  className?: string,
  dataUxId?: string,
  pattern?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  isDirty?: boolean,
  bottomBar?: boolean, // for bottom border animation on click.
  css?: string,
  value?: string | number,
  minLength?: number,
  maxLength?: number,
  placeholder?: string,
  maxvalue?: number,
  hasErrored?: boolean,
  defaultValue?: any,
  name?: string,
  checked?: boolean,
  ariaLabel?: string,
  tabIndex?: number,
}

export const Input: React.FC<IProps> = ({
  type,
  id,
  dataUxId,
  pattern,
  onChange,
  onFocus,
  onInput,
  onBlur,
  isDirty,
  minLength,
  maxLength,
  className,
  css,
  bottomBar,
  value,
  placeholder,
  maxvalue,
  hasErrored,
  defaultValue,
  onKeyUp,
  onKeyDown,
  name,
  checked,
  ariaLabel,
  tabIndex,
  required = true,
}) =>
  (
    <React.Fragment>
      <InputElement
        type={type}
        id={id}
        className={className}
        data-ux={dataUxId}
        pattern={pattern}
        required={required}
        onChange={onChange}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onInput={onInput}
        onBlur={onBlur}
        isDirty={isDirty}
        minLength={minLength}
        maxLength={maxLength}
        css={css}
        bottomBar={bottomBar}
        value={value}
        placeholder={placeholder}
        data-maxvalue={maxvalue}
        hasErrored={hasErrored}
        defaultValue={defaultValue}
        name={name}
        checked={checked}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
      />
      {bottomBar && <Bar />}
    </React.Fragment>
  )
