import React from 'react'
import { join } from 'lodash'
import { Col } from 'components/Atoms/Grid'
import Heading from 'components/Atoms/Heading'
import Paragraph from 'components/Atoms/Paragraph'
import Button from 'components/Atoms/Button'
import ReplaceWithError from 'components/Molecules/FormComponents/ReplaceWithError'
import { uniqueIdentifier } from 'utils'
import ConfirmAddressContainer from './style'

export type IProps = {
  handleSubmit: () => void,
  changeAddress: () => void,
  address: object | string,
  sessioncamHide?: boolean,
  heading: string,
  headingAlign?: 'left' | 'center' | 'right',
  sessioncamClassList?: string | string[],
  errorVisible: boolean,
  errorMsg: string,
  children?: string,
}

export const ConfirmAddress: React.FC<IProps> = ({
  handleSubmit,
  changeAddress,
  address,
  sessioncamHide,
  heading,
  headingAlign,
  sessioncamClassList,
  errorVisible,
  errorMsg,
  children,
}) =>
  (
    <ConfirmAddressContainer data-testid="test">
      <Col xs="12">
        <Heading id="confirmAddress" align={headingAlign}>{heading}</Heading>
      </Col>
      <Col xs="12" style={{ position: 'relative', textAlign: 'center' }}>
        <Paragraph
          className={sessioncamHide ? join(sessioncamClassList, ' ') : undefined}
          id="confirmAddressText"
        >
          {address}
        </Paragraph>
        {children}
      </Col>
      <Col xs="12" md={{ span: 6, offset: 3 }}>
        <ReplaceWithError
          forceModalSubmit={false}
          isErrorVisible={errorVisible}
          errorMessage={errorMsg}
          dataUxId={uniqueIdentifier('FindAddress')}
          render={(
            <Button
              dataUxId={uniqueIdentifier('selectAddress')}
              onClick={handleSubmit}
              style={{ marginTop: 20 }}
              id="continue"
              kind="primary"
            >
              Continue
            </Button>
          )}
        />
        <Button
          id={`Button_${uniqueIdentifier('selectReturnToFinder')}`}
          onClick={changeAddress}
          kind="underline"
          style={{ marginTop: 30 }}
        >
          Return to address finder
        </Button>
      </Col>
    </ConfirmAddressContainer>

  )
