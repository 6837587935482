import React from 'react'
import ParagraphElement from './style'

export interface ParagraphProps {
  className?: string,
  id?: string,
  font?: string,
  colour?: string,
  align?: string,
  css?: string, // optional object for added custom styles.
  children: React.ReactNode, // for any text and elements.
}

export const Paragraph: React.FC<ParagraphProps & React.HTMLAttributes<HTMLParagraphElement>> = ({
  id,
  className,
  font,
  colour,
  align,
  css,
  children,
  ...props
}) => (
  <ParagraphElement
    className={className}
    id={id}
    css={css}
    font={font}
    align={align}
    colour={colour}
    {...props}
  >
    {children}
  </ParagraphElement>
)
