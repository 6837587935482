import React from 'react'
import { join, isUndefined } from 'lodash'
import { truncateStr } from 'utils'
import { StyledSelect, SelectWrapper, StyledIcon } from './style'

type item = {
  title: string | undefined,
  value: string,
}

export type IProps = {
  onChange: () => void,
  items: item[],
  sessioncamHide?: boolean,
  sessioncamClassList?: string[] | string,
  dataUxId?: string,
  selected?: string,
  placeholder: string,
}

export const DropDown: React.FC<IProps & React.HTMLAttributes<HTMLSelectElement>> = ({
  onChange,
  items,
  sessioncamHide,
  sessioncamClassList,
  dataUxId,
  selected,
  placeholder,
}) => {
  const styledObj = {
    position: 'absolute',
    right: '10px',
    top: '17px',
    fill: '#00C2A2',
  }

  return (
    <SelectWrapper>
      <StyledSelect
        className={sessioncamHide ? join(sessioncamClassList, ' ') : undefined}
        id={`Dropdown_${dataUxId}`}
        onChange={onChange}
        defaultValue={selected || '0'}
      >
        <option value="0" disabled>{placeholder}</option>
        {items && items.map(i => (
          <option {...i} key={i.value} value={i.value} className={sessioncamHide ? join(sessioncamClassList, ' ') : undefined}>{truncateStr((isUndefined(i.title) ? i.value : i.title), 51)}</option>
        ))}
      </StyledSelect>
      <StyledIcon
        kind="sort-desc"
        size={10}
        style={styledObj}
      />
    </SelectWrapper>
  )
}
