import styled, { css } from 'styled-components'

type IProps = {
  isDirty?: boolean,
  dataUxId?: string,
  hasErrored?: boolean,
  bottomBar?: boolean,
  css?: string,
}

const InputElement = styled.input <IProps>`
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border: 0;
  margin: 30px 0 20px;

  ${({ theme: { components: { input } } }) => css`
    border-bottom: ${input.borderBottom};
    width: ${input.width};
    font-size: ${input.fontSize};
    padding: ${input.padding};
    border-radius: ${input.borderRadius};
  `}

  &:focus ~ span {
    ${({ theme: { components: { input: { _focus: { span } } } } }) => css`
      top: ${span.top};
      font-size: ${span.fontSize};
      color: ${span.color};
    `}
  }
  &:focus ~ i {
    ${({ theme: { components: { input: { _focus: { i } } } } }) => css`
      width: ${i.width};
      left: ${i.left};
    `}
  }
  &:valid ~ span {
    ${({ theme: { components: { input: { _valid: { span } } } } }) => css`
      top: ${span.top};
      font-size: ${span.fontSize};
      color: ${span.color};
    `}
  }
  &:focus {
    outline: ${({ theme }) => theme.components.input._focus.outline};
  }
  ${props => props.isDirty && css`
    &:invalid ~ span {
      ${({ theme: { components: { input: { _invalid: { span } } } } }) => css`
        top: ${span.top};
        font-size: ${span.fontSize};
        color: ${span.color};
      `}
    }
    &:invalid ~ i {
      ${({ theme: { components: { input: { _invalid: { i } } } } }) => css`
        width: ${i.width};
        left: ${i.left};
        color: ${i.color};
      `}
    }
    &.alt {
      ${({ theme: { components: { input: { _alt } } } }) => css`
        color: ${_alt.color};
        font-size: ${_alt.fontSize};
      `}
    }
  `}
  ${props => props.css};
`

const Bar = styled.i`
  ${({ theme: { components: { input: { bar } } } }) => css`
    width: ${bar.width};
    left: ${bar.left};
    bottom: ${bar.bottom};
    height: ${bar.height};
    background: ${bar.background};
    transition: ${bar.transition};
  `}
  position: absolute;
`

export {
  InputElement,
  Bar,
}
