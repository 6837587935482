import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'
import alignment from 'themes/tokens/alignment'
import radius from 'themes/tokens/radius'

export interface dobTypes {
  title?: {
    fontSize?: string
    fontWeight?: number
    color?: string
  },
  inputContainer?: {
    color?: string
    fontSize?: string
    textAlign?: string
    lineHeight?: string
    borderBottom?: string
  },
  inputElement?: {
    fontSize?: string
    textAlign?: string
    borderBottom?: string
    borderRaidus?: string
    lineHeight?: string
    fontSizeSmall?: string
    lineHeightSmall?: string
  },
  bar?: {
    background?: string
  },
  spacer?: {
    color?: string
    fontSize?: string
    textAlign?: string
  },
}

const DOB: dobTypes = {
  title: {
    fontSize: typography.fontSizes.fs20,
    fontWeight: typography.fontWeight.regular,
    color: colors.black,
  },
  inputContainer: {
    color: colors.japaneseIndigo,
    fontSize: typography.fontSizes.fs24,
    textAlign: alignment.text.left,
    lineHeight: typography.lineHeights.lh40,
    borderBottom: `1px solid ${colors.pastelGrey}`,
  },
  inputElement: {
    fontSize: typography.fontSizes.fs35,
    textAlign: alignment.text.center,
    borderBottom: `1px solid ${colors.japaneseIndigo}`,
    borderRaidus: radius.none,
    lineHeight: typography.lineHeights.lh60,
    fontSizeSmall: typography.fontSizes.fs22,
    lineHeightSmall: typography.lineHeights.lh40,
  },
  bar: {
    background: colors.amazonite,
  },
  spacer: {
    color: colors.opal,
    fontSize: typography.fontSizes.fs45,
    textAlign: alignment.text.center,
  },
}

export default DOB
