interface alignmentTypes {
  text: {
    left: string
    center: string
    right: string
  },
}

const alignment: alignmentTypes = {
  text: {
    left: 'left',
    center: 'center',
    right: 'right',
  },
}

export default alignment
