import colors from 'themes/tokens/colors'
import { InputBreakdownTokens } from './types'

const inputBreakdown: InputBreakdownTokens = {
  typography: {
    color: colors.japaneseIndigo,
    buttonColor: colors.royalPurple,
    iconColor: colors.vitaminC,
    textColor: colors.black,
    guidanceTextColor: colors.japaneseIndigo,
  },
}

export default inputBreakdown
