interface radiusTypes {
  none: string
  r3: string
  r5: string
  r10: string
  r16: string
  r25: string
  r30: string
}

const radius: radiusTypes = {
  none: '0',
  r3: '3px',
  r5: '5px',
  r10: '10px',
  r16: '16px',
  r25: '25px',
  r30: '30px',
}

export default radius
