import colors from '../../../themes/tokens/colors'
import typography from '../../../themes/tokens/typography'
import alignment from '../../../themes/tokens/alignment'

export interface labelTypes {
  color?: string
  fontSize?: string
  textAlign?: string
  fontWeight?: number
}

const label: labelTypes = {
  color: colors.amazonite,
  fontSize: typography.fontSizes.fs24,
  textAlign: alignment.text.left,
  fontWeight: typography.fontWeight.regular,
}

export default label
