import React from 'react'
import LargeIcon from 'components/Atoms/LargeIcon'
import StyledLabel from './style'

export type IconWithLabelProps = {
  dataUxId?: string,
  text: string,
  icon: string,
  onClick?: () => void,
  id?: string,
  tabIndex?: number,
  size?: string[] | number[] | string | number,
}

export const IconWithLabel = ({
  dataUxId,
  text,
  icon,
  onClick,
  id,
  size = 200,
  tabIndex = 0,
}: IconWithLabelProps) =>
  (
    <StyledLabel
      htmlFor={icon}
      role="button"
      tabIndex={tabIndex}
      onClick={onClick}
      onKeyPress={onClick}
      id={id || `Label_${dataUxId}`}
    >
      <LargeIcon
        id={`LargeIcon_${dataUxId}`}
        kind={icon}
        style={{ margin: '0 auto 10px', display: 'block' }}
        size={size}
      />
      {text}
    </StyledLabel>
  )
