import styled, { css } from 'styled-components'
import { query } from 'themes'

type InputHiddenProps = {
  type: string,
  name: string,
  value: string,
  onChange: () => void,
  id: string,
  activeColor: string,
  checkedContentColor: string,
}

type LabelProps = {
  backgroundColor: string,
}

type TextProps = {
  textColor: string,
}

const InputHidden = styled.input <InputHiddenProps>`
  position: absolute;
  left: -9999px;
`

const LabelBox = styled.label <LabelProps>`
  display: flex;
  cursor: pointer;
  margin-bottom: 15px;

  ${({ backgroundColor, theme: { components: { checkboxButton } } }) => css`
    justify-content: ${checkboxButton.justifyContent};
    align-items: ${checkboxButton.alignItems};
    color: ${checkboxButton.justifyContent};
    padding: ${checkboxButton.padding};
    line-height: ${checkboxButton.lineHeight};
    font-size: ${checkboxButton.fontSize};
    background-color: ${backgroundColor || checkboxButton.backgroundColor};
    border-radius: ${checkboxButton.borderRadius};
    border: ${checkboxButton.border};
    font-weight: ${checkboxButton.fontWeight};
    height: ${checkboxButton.height};
  `}

  div {
    &:first-child {
      display: flex;
      position: absolute;
      ${({ theme: { components: { checkboxButton: { _checkIcon } } } }) => css`
        left: ${_checkIcon.left};
        width: ${_checkIcon.width};
      `}
    }

    svg {
      fill: ${({ theme }) => theme.components.checkboxButton._checkIcon.fill};
    }
  }

  ${query.combine(['extraSmall', 'small', 'medium'], css`
    font-size: ${({ theme }) => theme.components.checkboxButton.fontSizeSmallDevices};
  `)}

  &::selection {
    background: transparent;
  }
`

const StyledInputHidden = styled(InputHidden)`
  &:checked ~ label {
    ${props => props.activeColor && css`
      color: ${({ theme }) => theme.components.checkboxButton._checked.color};
      background: ${props.activeColor};
    `}

    div {
      &:last-child {
        color: ${props => props.checkedContentColor ? props.checkedContentColor : props.theme.components.checkboxButton._checked.color};
      }
    }
  }

  &:checked ~ label svg {
    fill-opacity: 1;
  }

`

const Text = styled.div<TextProps>`
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  margin: auto;
  line-height: 20px;
  width: 75%;
  color: ${({ textColor, theme }) => textColor || theme.components.checkboxButton.textColor};

  // manual device width to fix text dropping
  @media only screen
    and (min-width: 468px)
    and (max-width: 767px){
      line-height: unset;
  }

`

export {
  LabelBox,
  StyledInputHidden as InputHidden,
  Text,
}
