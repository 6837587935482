import styled, { css } from 'styled-components'

const DivStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 37px;
    height: 37px;
  }

  h2 {
    margin: 0;
    ${({ theme: { components: { headingWithIcon } } }) => css`
      font-size: ${headingWithIcon.headingFontSize};
      line-height: ${headingWithIcon.headingLineHeight};
      color: ${headingWithIcon.headingColor};
      font-weight: ${headingWithIcon.headingFontWeight};
    `}
  }
`

export default DivStyled
