import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'
import typography from 'themes/tokens/typography'

export interface tabsTypes {
  item: {
    fontFamily?: string
    fontStyle?: string
    fontWeight?: number
    fontSize?: string
    lineHeight?: string
    borderRadius?: string
    color?: string
    colorActive?: string
    paddingLeft?: string
    paddingRight?: string
    focusOutline?: string
    height?: string
  },
}

const tabs: tabsTypes = {
  item: {
    fontFamily: typography.fontFamily,
    fontStyle: typography.fontStyle.normal,
    fontWeight: typography.fontWeight.bold,
    fontSize: typography.fontSizes.fs14,
    lineHeight: typography.lineHeights.lh27,
    borderRadius: radius.r25,
    color: colors.japaneseIndigo,
    colorActive: colors.white,
    paddingLeft: '16px',
    paddingRight: '16px',
    focusOutline: '1px auto darkblue',
    height: '40px',
  },
}

export default tabs
