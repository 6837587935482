import styled, { css } from 'styled-components'
import { query } from 'themes'
import Button from 'components/Atoms/Button'

type SProps = {
  onClick: () => void,
  linkAlign?: string,
}

type WrapperProps = {
  top?: string,
}
const TooltipWrapper = styled.div`
  ${query.combine(['extraSmall', 'small', 'medium'], `
    margin-top: 5px;
  `)}
`

const TooltipLink = styled(Button) <SProps>`
  display: none;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  margin-top: 10px;
  text-decoration: underline;
  ${props => props.linkAlign === 'center' ? 'justify-content: center' : 'null'};
  ${query.combine(['extraSmall', 'small', 'medium'], `
    display: block;
    height: 45px;
    display: flex;
    align-items: center;
  `)}
  ${({ theme: { components: { tooltip: { tooltipLink } } } }) => css`
    color: ${tooltipLink.color};
    font-size: ${tooltipLink.fontSize};
  `}
`

const TooltipText = styled.div<WrapperProps>`
  margin-top: 8px;
  position: absolute;
  width: 240px;
  top: 0;

  ${({ theme: { components: { tooltip: { tooltipText } } } }) => css`
    box-shadow: ${tooltipText.boxShadow};
    border-radius: ${tooltipText.borderRadius};
    padding: ${tooltipText.padding};
    border: ${tooltipText.border};
    background: ${tooltipText.background};
    font-size: ${tooltipText.fontSize};
    color: ${tooltipText.color};
  `}

  // only add custom top property where it is visible (so it doesn't effect button expand on mobile)
  @media only screen and (min-width: 992px) {
    top: ${props => props.top ? props.top : null};
  }

  right: -250px;
  ${query.combine(['extraSmall', 'small', 'medium'], `
    &#centeredArrow {
      &:before {
        left: 48%;
      }
    }
  `)}
  ${query.combine(['extraSmall', 'small', 'medium'], `
    position: relative;
    right: 0;
    width: 100%;
  `)}
  &.hidden {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      display: none;
    `)}
  }
  &.visible {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      display: block;
    `)}
  }
  &:before {
    content: '';
    position: absolute;
    width: 12.75px;
    height: 12.75px;
    display: block;
    transform: matrix(0.71, 0.68, -0.74, 0.71, 0, 0);
    left: -8px;
    top: 17px;

    ${({ theme: { components: { tooltip: { tooltipText: { _before } } } } }) => css`
      background: ${_before.background};
      border-left: ${_before.borderLeft};
      border-bottom: ${_before.borderBottom};
    `}

    ${query.combine(['extraSmall', 'small', 'medium'], css`
      transform: matrix(0.71, 0.68, -0.74, 0.71, 0, 0);
      left: 25px;
      top: -8px;
      ${({ theme: { components: { tooltip: { tooltipText: { _before } } } } }) => css`
        border-left: ${_before.borderLeft};
        border-top: ${_before.borderTop};
        border-bottom: ${_before.borderBottomSmalllDevice};
      `}
    `)}
  }
`

type StyleProps = {
  css?: string
}

const BasicTooltipWrapper = styled.div<StyleProps>`

  width: 100%;
  max-width: 177px;
  margin: 0;
  position: relative;

  ${({ theme: { components: { tooltip: { basicTooltipWrapper } } } }) => css`
    font-family: ${basicTooltipWrapper.fontFamily};
    font-size: ${basicTooltipWrapper.fontSize};
    padding: ${basicTooltipWrapper.padding};
    background: ${basicTooltipWrapper.background};
    border: ${basicTooltipWrapper.border};
    border-radius: ${basicTooltipWrapper.borderRadius};
    box-shadow: ${basicTooltipWrapper.boxShadow};
  `}

  &:before {
    bottom: 100%;
    left: 25px;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;


    margin-left: -13px;

    ${({ theme: { components: { tooltip: { basicTooltipWrapper: { _before } } } } }) => css`
      border: ${_before.border};
      border-color: ${_before.borderColor};
      border-bottom-color: ${_before.borderBottomColor};
      border-width: ${_before.borderWidth};
    `}
  }

  &:after {
    bottom: 100%;
    left: 22px;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-left: -9px;

    ${({ theme: { components: { tooltip: { basicTooltipWrapper: { _after } } } } }) => css`
      border: ${_after.border};
      border-color: ${_after.borderColor};
      border-bottom-color: ${_after.borderBottomColor};
      border-width: ${_after.borderWidth};
    `}
  }

  p {
    margin: 0;
    padding: 0;
    line-height: 17px;
  }

  ${props => props.css}
`

export {
  TooltipWrapper,
  TooltipLink,
  TooltipText,
  BasicTooltipWrapper,
}
