interface typographyTypes {
  fontFamily: string

  fontSizes: {
    fs13: string
    fs14: string
    fs16: string
    fs17: string
    fs18: string
    fs19: string
    fs20: string
    fs22: string
    fs24: string
    fs26: string
    fs28: string
    fs29: string
    fs35: string
    fs36: string
    fs42: string
    fs45: string
    fs52: string
  },

  fontStyle: {
    normal: string
  },

  fontWeight: {
    light: number
    regular: number
    semibold: number
    bold: number
  },

  lineHeights: {
    none: string
    lh21: string
    lh22: string
    lh24: string
    lh26: string
    lh27: string
    lh30: string
    lh33: string
    lh34: string
    lh40: string
    lh60: string
  },

  letterSpacing: {
    normal: string
  },

  textDecoration: {
    underline: string
  },
}

const typography: typographyTypes = {
  fontFamily: 'Montserrat, sans-serif',

  fontSizes: {
    fs13: '0.8125rem', // 13px
    fs14: '0.875rem', // 14px
    fs16: '1rem', // 16px
    fs17: '1.0625rem', // 17px
    fs18: '1.125rem', // 18px
    fs19: '1.1875rem', // 19px
    fs20: '1.25rem', // 20px
    fs22: '1.375rem', // 22px
    fs24: '1.5rem', // 24px
    fs26: '1.625rem', // 26px
    fs28: '1.75rem', // 29px
    fs29: '1.8125rem', // 29px
    fs35: '2.1875rem', // 35px
    fs36: '2.25rem', // 36px
    fs42: '2.625rem', // 42px
    fs45: '2.8125rem', // 45px
    fs52: '3.25rem', // 52px
  },

  fontStyle: {
    normal: 'normal',
  },

  fontWeight: {
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700,
  },

  lineHeights: {
    none: '1.2',
    lh21: '1.3125rem', // 21px
    lh22: '1.375rem', // 22px
    lh24: '1.5rem', // 24px
    lh26: '1.625rem', // 24px
    lh27: '1.6875rem', // 27px
    lh30: '1.875rem', // 30px
    lh33: '2.1rem', // 33.6px
    lh34: '2.175rem', // 34.8px
    lh40: '2.5rem', // 40px
    lh60: '3.75rem', // 60px
  },

  letterSpacing: {
    normal: '0',
  },

  textDecoration: {
    underline: 'underline',
  },
}

export default typography
