import styled, { css } from 'styled-components'
import Heading from 'components/Atoms/Heading'

const PricingCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  border-radius: 5px;
  padding: 15px 25px;
  background: #fff;
  margin-bottom: 20px;

  ${({ theme: { components: { pricingCard } } }) => (
    css`
      color: ${pricingCard.typography.color};
      box-shadow: ${pricingCard.wrapperBoxShadow};
      background: ${pricingCard.backgroundColor};
      border-radius: ${pricingCard.wrapperBorderRadius};

      h1 {
        font-weight: 700;
        color: ${pricingCard.typography.color};
      }
    `
  )}

  @media (max-width: 768px) {
    padding: 0px 25px;
  }
`

const PricingHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 8rem;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  p {
    font-weight: normal;
  }

  div {
    min-height: 5rem;
    display: flex;
    align-items: center;
  }

  #iq_price {
    margin: 0;
    font-size: 4.5rem;
    font-weight: bold;
  }

  #iq_pennies {
    font-size: 2.5rem;
    font-weight: bold;
  }
`

const PricingBody = styled.div`
  display: inline-flex;
  align-items: flex-start;

  &#guidancePricing {
    span, svg {
      @media (max-width: 768px) {
          display: none
      }
    }
    p {
       @media (min-width: 769px) {
          display: none
      }
    }
  }

  span {
    margin: 0;
  }

  p {
    text-align: center;

    ${({ theme: { components: { pricingCard } } }) => (
    css`
          font-size: ${pricingCard.typography.fontMobile};
          margin-bottom: 15px;
          color: ${pricingCard.guidanceMobileTextColor}
        `
  )}
  }


  @media (max-width: 768px) {
    ${({ theme: { components: { pricingCard } } }) => (
    css`
        font-size: ${pricingCard.typography.fontMobile};
        margin-bottom: 15px;
      `
  )}
  }

  @media (min-width: 768px) and (max-width: 950px) {
    flex-wrap: wrap;
    width: 80%;
    span {
      width: 80%;
      text-align: center;
    }
  }
`

const PricingFooter = styled.div`
  @media (max-width: 767.5px) {
    display: none;
  }

  margin-top: 25px;

  p {
    font-weight: bold;
    margin-top: 45px;
  }

  a {
    text-decoration: none;
  }
`

const MobileContinueWrapper = styled.div`

  @media (min-width: 768px) {
    display: none;
  }

  background-color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0px -2px 4px 0px;
  margin: 0;
  a {
    margin: 0!important;
    text-decoration: none;
  }
`

const HeadingStyled = styled(Heading)`
  && {
    ${({ theme: { components: { pricingCard } } }) => (
    css`
          color: ${pricingCard.headingColor};
        `
  )}
  }
`

const IqPriceStyled = styled.p`
      ${({ theme: { components: { pricingCard } } }) => (
    css`
          color: ${pricingCard.iqPriceColor};
        `
  )}
`

const ParagraphStyled = styled.p`
      ${({ theme: { components: { pricingCard } } }) => (
    css`
          color: ${pricingCard.paragraphColor};
        `
  )}
`

const SpanStyled = styled.span`
      ${({ theme: { components: { pricingCard } } }) => (
    css`
          color: ${pricingCard.spanColor};
        `
  )}
`

export {
  PricingCardWrapper,
  PricingHeader,
  PricingBody,
  PricingFooter,
  MobileContinueWrapper,
  HeadingStyled,
  IqPriceStyled,
  ParagraphStyled,
  SpanStyled,
}
