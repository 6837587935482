import styled, { css } from 'styled-components'
import { GrowPath, GrowPathTrans, GrowPathWide } from 'components/Atoms/LargeIcon/style'
import { query } from 'themes'

const Label = styled.label`
  ${({ theme: { components: { iconWithLabel } } }) => css`
    color: ${iconWithLabel.color};
    font-size: ${iconWithLabel.fontSize};
    text-align: ${iconWithLabel.textAlign};
    font-weight: ${iconWithLabel.fontWeight};
  `}

  margin-top: 10px;
  display: block;
  cursor: pointer;

  & ${GrowPath} {
    transform: scale(1);
    transform-origin: center;
    transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
  }

  & ${GrowPathWide} {
    transform: scale(1) translate3d(0);
    transform-origin: center;
    transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
  }

  &:focus,
  &:hover {
    outline: none;
  }

  ${query.combine(['extraSmall', 'small'], css`
    font-size: ${({ theme }) => theme.components.iconWithLabel.fontSizeSmallDevice};
  `)}

  &:hover ${GrowPath}, &:hover ${GrowPathTrans}, &:focus ${GrowPathTrans}, &:focus ${GrowPath} {
    transform: scale(1.2);
    transform-origin: center;
    transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
  }

  &:hover ${GrowPathWide}, &:focus ${GrowPathWide} {
    transform: scale(1.2) translate(4%, 5%);
    transform-origin: center;
    transition: transform ease-in-out 0.4s, opacity 0.3s ease-in-out;
  }

  > svg {
    width: 65%;
    height: auto;
    ${query.combine(['large', 'tabletPortrait'], `
      width: 75%;
    `)}
    ${query.combine(['medium'], `
      width: 100%;
    `)}
    ${query.combine(['extraSmall'], `
      width: 50%;
    `)}
    ${query.combine(['small'], `
      width: 80%;
    `)}
    ${query.combine(['mobileLandscape'], `
      width: 45%;
    `)}
  }
`

export default Label
