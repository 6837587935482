import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'

export interface checkboxButtonTypes {
    justifyContent?: string
    alignItems?: string
    color?: string
    padding?: string
    lineHeight?: string
    fontSize?: string
    borderRadius?: string
    border?: string
    fontWeight?: number
    height?: string
    _checkIcon?: {
      left?: string
      width?: string
      fill?: string
    },
    _checked?: {
      color?: string
    },
    fontSizeSmallDevices?: string
    textColor?: string
}

const checkboxButton: checkboxButtonTypes = {
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.japaneseIndigo,
  padding: '6px',
  lineHeight: typography.lineHeights.lh30,
  fontSize: typography.fontSizes.fs14,
  borderRadius: radius.r25,
  border: `1px solid ${colors.japaneseIndigo}`,
  fontWeight: typography.fontWeight.bold,
  height: '50px',
  _checkIcon: {
    left: '25px',
    width: '20px',
    fill: colors.white,
  },
  _checked: {
    color: colors.white,
  },
  fontSizeSmallDevices: typography.fontSizes.fs13,
  textColor: colors.amazonite,
}

export default checkboxButton
