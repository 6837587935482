import alignment from 'themes/tokens/alignment'
import radius from 'themes/tokens/radius'
import typography from 'themes/tokens/typography'
import colors from '../../../themes/tokens/colors'

export interface modalTypes {
  container?: {
    background?: string
    boxShadow?: string
    borderRadius?: string
  },
  heading?: {
    fontWeight?: number
    fontSize?: string
    lineHeight?: string,
    textAlign?: string,
    color?: string,
  },
  closeBtn?: {
    background?: string
    borderRadius?: string
    svgFill?: string
  },
  content?: {
    textColor?: string
  },
}

const modal: modalTypes = {
  container: {
    background: colors.white,
    boxShadow: `0px 4px 10px ${colors.japaneseIndigo}`,
    borderRadius: radius.r10,
  },
  heading: {
    fontWeight: 700,
    fontSize: typography.fontSizes.fs24,
    lineHeight: typography.lineHeights.none,
    textAlign: alignment.text.center,
    color: colors.japaneseIndigo,
  },
  closeBtn: {
    background: colors.japaneseIndigo,
    borderRadius: '50%',
    svgFill: colors.white,
  },
  content: {
    textColor: colors.darkGrey,
  },
}

export default modal
