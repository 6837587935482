import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'

export interface inputWithLabelTransitionTypes {
  spanMobileText?: {
    fontSize?: string
  },
  input?: {
    fontSize?: string
    color?: string
    altColor?: string
    altFontSize?: string
  },
  text?: {
    fontSize?: string
    color?: string
  },
  label?: {
    fontSize?: string
    color?: string
    fontSizeSmall?: string
    inputFontSize?: string
  },
}

const inputWithLabelTransition: inputWithLabelTransitionTypes = {
  spanMobileText: {
    fontSize: typography.fontSizes.fs19,
  },
  input: {
    fontSize: typography.fontSizes.fs20,
    color: colors.amazonite,
    altColor: colors.japaneseIndigo,
    altFontSize: typography.fontSizes.fs24,
  },
  text: {
    fontSize: typography.fontSizes.fs24,
    color: colors.japaneseIndigo,
  },
  label: {
    fontSize: typography.fontSizes.fs18,
    color: colors.japaneseIndigo,
    fontSizeSmall: typography.fontSizes.fs16,
    inputFontSize: typography.lineHeights.none,
  },
}

export default inputWithLabelTransition
