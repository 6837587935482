const colors = {
  inherit: 'inherit',
  transparent: 'transparent',
  royalPurple: '#8043B3',
  darkCharcoal: '#333333',
  spaceCadet: '#28334B',
  amazonite: '#00C2A2',
  pearlAqua: '#87d8c9',
  azureishWhite: '#DDF0EE',
  fulvous: '#E97707',
  raisinBlack: '#222222',
  pastelGrey: '#CCCCCC',
  white: '#FFFFFF',
  japaneseIndigo: '#28334A',
  japaneseIndigo80: 'rgba(40,51,74, 0.8)',
  cadetGrey: '#89A5AB',
  purpureus: '#925EAE',
  purpleDark: '#8F38C3',
  purpleLight: '#7F3DA8',
  black: '#000000',
  black60: 'rgba(0,0,0,0.6)',
  sunsetOrange: '#ff4f4f',
  iconLightGreen: '#DDF7F4',
  darkGrey: '#373737',
  milanoRed: '#B20A0A',
  heather: 'rgb(185, 196, 211)',
  cloudBurst: '#1D374E',
  gainsboro: '#D2D9E4',
  columbiaBlue: '#CED5DF',
  silverSand: '#C4C4C4',
  beauBlue0: 'rgba(194, 225, 245, 0)',
  policeBlue: '#3B4966',
  vividGamboge: '#FF9800',
  link: '#8038A9',
  opal: '#A7CCBE',
  vitaminC: '#ff9800',
  budget: {
    purple: '#6859A3',
    darkGrey: '#4A4A4A',
    spanishGray: '#949494',
    honoluluBlue: '#006BAD',
  },
  virginMoney: {
    red: '#CE0000',
    onyx: '#373737',
    maximumPurple: '#793570',
    brightGray: '#EDEAEB',
    charlestonGreen: '#282728',
    cultured: '#F6F4F5',
    bostonUniversityRed: '#CC0000',
    platinum: '#E4E0E0',
    sapGreen: '#44842F',
    electricRed: '#DE0000',
  },
}

// const budgetLifeColors: {

// }

// const virginMoneyColors: {

// }

export default colors
