import styled, { css } from 'styled-components'
import { query } from 'themes'

type InputProps = {
  isDirty?: boolean,
  hasErrored?: boolean,
  sessioncamHide?: boolean,
  dataUxId?: string,
}

type TextProps = {
  errored?: boolean,
}

const spanMobileText = css`
  @media (min-width: 768px){
    font-size: ${({ theme }) => theme.components.textInput.spanMobile.fontSize};
  }
`

const Input = styled.input<InputProps>`
  border: 0;
  width: 100%;
  padding: 0 0 10px;
  margin: 30px 0 20px;
  border-radius:0;

  ${({ theme: { components: { textInput: { input } } } }) => css`
    border-bottom: ${input.borderBottom};
    font-size: ${input.fontSize};
  `}

  &:focus ~ span {
    ${({ theme: { components: { textInput: { input: { _focus } } } } }) => css`
      font-size: ${_focus.fontSize};
      color: ${_focus.color};
    `}
    top: -40px;
    ${spanMobileText}
  }
  &:focus ~ i {
    width: 100%;
    left: 0;
  }
  &:valid ~ span {
    top: -40px;
    ${({ theme: { components: { textInput: { input: { _focus } } } } }) => css`
      font-size: ${_focus.fontSize};
      color: ${_focus.color};
    `};
    ${spanMobileText}

  }
  &:focus {
    outline: none;
  }
  ${props => props.isDirty && css`
  &:invalid ~ span {
    top: -40px;
    ${({ theme: { components: { textInput: { input: { _focus } } } } }) => css`
      font-size: ${_focus.fontSize};
      color: ${_focus.color};
    `}
    ${spanMobileText}
  }
  &:invalid ~ i {
    width: 100%;
    left: 0;
    ${({ theme: { components: { textInput: { input: { _focus } } } } }) => css`
      color: ${_focus.color};
    `}
    ${spanMobileText}
  }
  &.alt {
    ${({ theme: { components: { textInput: { input: { _alt } } } } }) => css`
      color: ${_alt.color};
      font-size: ${_alt.fontSize};
    `}
  }
`}
`

const Label = styled.label`
  position: relative;
  span {
    white-space: nowrap;
  }
  &.alt {
    .textHolder {
      ${({ theme: { components: { textInput: { label: { textHolder } } } } }) => css`
        font-size: ${textHolder.fontSize};
        color: ${textHolder.color};
      `}
      ${query.combine(['extraSmall', 'small'], css`
        font-size: ${({ theme }) => theme.components.textInput.label.textHolder.fontSizeSmall};
      `)}
    }
    input {
      font-size: ${({ theme }) => theme.components.textInput.label.input.fontSize};
    }
  }
`

const Text = styled.span<TextProps>`
  pointer-events: none;
  position: absolute;
  font-size: ${({ theme }) => theme.components.textInput.text.fontSize};
  left: 0;
  top: -5px;
  transition: top 250ms ease-out, font-size 250ms ease-out;
  ${spanMobileText}
`

const Bar = styled.i`
  width: 0%;
  position: absolute;
  left: 50%;
  bottom: -12px;
  height: 3px;
  background: ${({ theme }) => theme.components.textInput.bar.background};
  transition: width 250ms ease-out, left 250ms ease-out;
`

export {
  Input,
  Label,
  Text,
  Bar,
}
