import alignment from 'themes/tokens/alignment'
import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'
import typography from 'themes/tokens/typography'

export interface inputWithLabelAboveTypes {
  input?: {
    borderRadius?: string
    padding?: string
    border?: string
    fontSize?: string
    background?: string
    backgroundValid?: string
  },
  label?: {
    fontSize?: string
    padding?: string
    _span?: {
      fontSize?: string
      lineHeight?: string
      textAlign?: string
      padding?: string
    },
    color?: string
  },
  red?: {
    color?: string
  },
}

const inputWithLabelAbove: inputWithLabelAboveTypes = {
  input: {
    borderRadius: radius.r3,
    padding: '10px',
    border: `1px solid ${colors.pastelGrey}`,
    fontSize: typography.fontSizes.fs16,
    background: 'transparent',
    backgroundValid: colors.purpureus,
  },
  label: {
    fontSize: typography.fontSizes.fs16,
    padding: '5px 0',
    _span: {
      fontSize: typography.fontSizes.fs16,
      lineHeight: typography.lineHeights.lh24,
      textAlign: alignment.text.left,
      padding: '13px 3px 5px 0',
    },
    color: colors.japaneseIndigo,
  },
  red: {
    color: colors.sunsetOrange,
  },
}

export default inputWithLabelAbove
