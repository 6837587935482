import React, { ReactNode } from 'react'
import Fieldset from './Fieldset'
import Legend from './Legend'
import { RadioItem } from './RadioItem'

export interface InputProps {
  Fieldset: typeof Fieldset
  Legend: typeof Legend
  RadioItem: typeof RadioItem
}

export const Input: React.FC & InputProps = ({ children }: { children?: ReactNode }) => <React.Fragment>{children}</React.Fragment>

Input.Fieldset = Fieldset
Input.Legend = Legend
Input.RadioItem = RadioItem
