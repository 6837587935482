import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'
import alignment from 'themes/tokens/alignment'

export interface paragraphTypes {
  fontFamily?: string
  fontSize?: string
  lineHeight?: string
  color?: string
  textAlign?: string
  _link?: {
    textDecoration?: string
    color?: string
  },
}

const paragraph: paragraphTypes = {
  fontFamily: typography.fontFamily,
  fontSize: typography.fontSizes.fs14,
  lineHeight: typography.lineHeights.lh24,
  color: colors.black,
  textAlign: alignment.text.left,
  _link: {
    textDecoration: typography.textDecoration.underline,
    color: colors.inherit,
  },
}

export default paragraph
