import styled, { css } from 'styled-components'

const Message = styled.div`
  ${({ theme: { components: { error } } }) => css`
    background: ${error.background};
    color: ${error.color};
    text-align: ${error.textAlign};
    width: ${error.width};
    padding: ${error.padding};
    margin: 10px 0;
    border-radius: ${error.borderRadius};
  `}
`

export default Message
