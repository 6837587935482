import radius from 'themes/tokens/radius'
import colors from 'themes/tokens/colors'
import borders from 'themes/tokens/borders'
import typography from 'themes/tokens/typography'

export interface inputTypes {
    borderBottom?: string
    width?: string
    fontSize?: string
    padding?: string
    borderRadius?: string
    _focus?: {
      outline?: string
      span?: {
        top?: string
        fontSize?: string
        color?: string
      },
      i?: {
        width?: string
        left?: number
      },
    },
    _valid?: {
      span?: {
        top?: string
        fontSize?: string
        color?: string
      },
    },
    _invalid?: {
      span?: {
        top?: string
        fontSize?: string
        color?: string
      },
      i?: {
        width?: string
        left?: number
        color?: string
      },
    },
    _alt?: {
      color?: string
      fontSize?: string
    },
    bar?: {
      width?: string
      left?: string
      bottom?: string
      height?: string
      background?: string
      transition?: string
    },
}

const input: inputTypes = {
  borderBottom: borders.border1pxSolidPastelGrey,
  width: '100%',
  fontSize: typography.fontSizes.fs24,
  padding: '0 0 10px 0',
  borderRadius: radius.none,
  _focus: {
    outline: borders.outline.none,
    span: {
      top: '-40px',
      fontSize: typography.fontSizes.fs20,
      color: colors.amazonite,
    },
    i: {
      width: '100%',
      left: 0,
    },
  },
  _valid: {
    span: {
      top: '-40px',
      fontSize: typography.fontSizes.fs20,
      color: colors.amazonite,
    },
  },
  _invalid: {
    span: {
      top: '-40px',
      fontSize: typography.fontSizes.fs20,
      color: colors.amazonite,
    },
    i: {
      width: '100%',
      left: 0,
      color: colors.amazonite,
    },
  },
  _alt: {
    color: colors.japaneseIndigo,
    fontSize: typography.fontSizes.fs24,
  },
  bar: {
    width: '0%',
    left: '50%',
    bottom: '-12px',
    height: '3px',
    background: colors.amazonite,
    transition: 'width 250ms ease-out, left 250ms ease-out',
  },
}

export default input
