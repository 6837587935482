import React from 'react'
import { InputHidden, Item } from './style'

export type RadioItemProps = {
  value: string,
  group: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  checked: boolean,
  id: string
}

export const RadioItem: React.FC<RadioItemProps & React.HTMLAttributes<HTMLInputElement>> = ({
  children,
  value,
  group,
  onChange,
  checked,
  id,
  ...props
}) => (
  <div id={`RadioItemContainer_${id}`} {...props}>
    <InputHidden
      type="radio"
      name={group}
      value={value}
      onChange={onChange}
      id={`RadioItemInput_${id}`}
      checked={checked}
    />
    <Item htmlFor={`RadioItemInput_${id}`} id={`RadioItemLabel_${id}`}>
      {children}
    </Item>
  </div>
)
