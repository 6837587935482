/* eslint-disable react/no-array-index-key */
import React from 'react'
import { isEmpty, join } from 'lodash'
import Heading from 'components/Atoms/Heading'
import Paragraph from 'components/Atoms/Paragraph'
import Icon from 'components/Atoms/Icon'
import { formatCurrency } from 'utils'
import { InputBreakdownProps } from './types'
import {
  BreakdownHeader, BreakdownBody, BreakdownFooter, BreakdownWrapper, ModalButton, Section, ParagraphStyled, SpanStyled,
} from './style'

const InputBreakdown: React.FC<InputBreakdownProps> = ({
  heading,
  description,
  fields,
  handleClick,
  children,
  guidance,
  sessioncamHide,
  sessioncamClassList,
}) => {
  const {
    policy, genders, dobs, term, amount, type, ci, ciAmount, cciAmount, smoker,
  } = fields

  return (
    <BreakdownWrapper data-testid="inputBreakdown">
      <BreakdownHeader>
        <Heading align="left" id="breakdownTitle" heading="h4">{heading}</Heading>
        {guidance && (<Paragraph data-testid="guidanceBreakdownText" id="breakdownText">{description}</Paragraph>)}
      </BreakdownHeader>

      {!isEmpty(fields) && (
        <BreakdownBody>

          <Section>
            <ParagraphStyled>{`I'd like a ${policy} policy`}</ParagraphStyled>
          </Section>

          {
            genders.map((i, index) => {
              const text = index === 0 ? 'I\'m' : 'My partner is'
              return (
                <Section key={`person_${index}`}>
                  <SpanStyled>
                    <span data-testid={index}>
                      {`${text} `}
                      <span className={sessioncamHide ? join(sessioncamClassList, ' ') : undefined}>{i}</span>
                      {', born '}
                      <span className={sessioncamHide ? join(sessioncamClassList, ' ') : undefined}>{dobs[index]}</span>
                      {', a '}
                    </span>
                    <ModalButton id={`iq_smoker_${index}`} aria-label={`change smoker status for person ${index + 1}`} data-type="smoker" data-person={index + 1} wrapper={false} kind="underline" onClick={handleClick} type="button">
                      {smoker[index]}
                      <Icon kind="sort-desc" size="10" />
                    </ModalButton>
                  </SpanStyled>
                </Section>
              )
            })
          }

          <Section>
            <SpanStyled>
              with
              <ModalButton id="iq_type" data-type="type" wrapper={false} kind="underline" onClick={handleClick} type="button">
                {type}
                <Icon kind="sort-desc" size="10" />
              </ModalButton>
              term cover of
              <ModalButton id="iq_amount" data-type="amount" wrapper={false} kind="underline" onClick={handleClick} type="button">
                {`£${formatCurrency(amount)}`}
                <Icon kind="sort-desc" size="10" />
              </ModalButton>
            </SpanStyled>
          </Section>

          {ci !== 'noci'
            ? (
              <Section>
                <SpanStyled>
                  and
                  <ModalButton id="iq_critical_illness" data-type="ci" wrapper={false} kind="underline" onClick={handleClick} type="button">
                    with Critical Illness
                    <Icon kind="sort-desc" size="10" />
                  </ModalButton>
                  cover of
                  <ModalButton id="iq_ciAmount" data-type="ciAmount" wrapper={false} kind="underline" onClick={handleClick} type="button">
                    {`£${formatCurrency(ciAmount)}`}
                    <Icon kind="sort-desc" size="10" />
                  </ModalButton>
                </SpanStyled>
              </Section>
            )
            : (
              <Section>
                <SpanStyled>
                  and
                  <ModalButton id="iq_noci" data-type="ci" wrapper={false} kind="underline" onClick={handleClick} type="button">
                    without Critical Illness
                    <Icon kind="sort-desc" size="10" />
                  </ModalButton>
                  cover
                </SpanStyled>
              </Section>
            )}

          {ci !== 'noci' && (
            ci === 'cci'
              ? (
                <Section>
                  <SpanStyled>
                    and
                    <ModalButton id="iq_cci" data-type="cci" wrapper={false} kind="underline" onClick={handleClick} type="button">
                      with Child Critical Illness
                      <Icon kind="sort-desc" size="10" />
                    </ModalButton>
                    {`cover of £${formatCurrency(cciAmount)}`}
                  </SpanStyled>
                </Section>
              )
              : (
                <Section>
                  <SpanStyled>
                    and
                    <ModalButton id="iq_nocci" data-type="cci" wrapper={false} kind="underline" onClick={handleClick} type="button">
                      without Child Critical Illness
                      <Icon kind="sort-desc" size="10" />
                    </ModalButton>
                  </SpanStyled>
                </Section>
              )
          )}

          <Section>
            <SpanStyled>
              over
              <ModalButton id="iq_term" data-type="term" wrapper={false} kind="underline" onClick={handleClick} type="button">
                {term}
                <Icon kind="sort-desc" size="10" />
              </ModalButton>
              years
            </SpanStyled>
          </Section>

        </BreakdownBody>
      )}

      <BreakdownFooter>{children}</BreakdownFooter>
    </BreakdownWrapper>
  )
}

export default InputBreakdown
