import borders from 'themes/tokens/borders'
import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'
import typography from 'themes/tokens/typography'

export interface checkboxSelectorTypes {
  labelKinds: {
    primary: {
      fontWeight?: number
      borderRadius?: string
      background?: string
      border?: string
    },
  },
  iconKind: {
    primary: {
      background?: string
      borderColor?: string
      fill?: string
    },
    ghost: {
      fill?: string
    },
  },
  labelBox?: {
    color?: string
    padding?: string
    lineHeight?: string
    fontSize?: string
    smallDeviceFontSize?: string
  },
  styledInputHidden?: {
    checkedLabel?: {
      color?: string
      background?: string
    },
    hoverLabel?: {
      fill?: string
      fillOpacity?: string
    },
    focusLabel?: {
      fill?: string
      fillOpacity?: number
    },
    checkedSvg?: {
      fillOpacity?: number
    },
  },
  checkbox?: {
    padding?: string
    fontSize?: string
    color?: string
    fontFamily?: string
    heading?: {
      fontSize?: string
    },
    marketingOptions?: {
      _input?: {
        fill?: string
        background?: string
        borderColor?: string
      },
    },
    svg?: {
      padding?: string
      border?: string
    },
  },
  container?: {
    _input?: {
      fill?: string
      background?: string
      borderColor?: string
    },
    _label?: {
      fontSize?: string
    },
  },
}

const checkboxSelector: checkboxSelectorTypes = {
  labelKinds: {
    primary: {
      fontWeight: typography.fontWeight.bold,
      borderRadius: radius.r5,
      background: colors.white,
      border: borders.border1pxSolidPastelGrey,
    },
  },
  iconKind: {
    primary: {
      background: colors.amazonite,
      borderColor: colors.white,
      fill: colors.white,
    },
    ghost: {
      fill: colors.raisinBlack,
    },
  },
  labelBox: {
    color: colors.japaneseIndigo,
    padding: '6px',
    lineHeight: typography.lineHeights.lh30,
    fontSize: typography.fontSizes.fs14,
    smallDeviceFontSize: typography.fontSizes.fs13,
  },
  styledInputHidden: {
    checkedLabel: {
      color: colors.white,
      background: colors.amazonite,
    },
    hoverLabel: {
      fill: colors.raisinBlack,
      fillOpacity: '0.2',
    },
    focusLabel: {
      fill: colors.raisinBlack,
      fillOpacity: 0,
    },
    checkedSvg: {
      fillOpacity: 1,
    },
  },
  checkbox: {
    padding: '20px 0',
    fontSize: typography.fontSizes.fs14,
    color: colors.spaceCadet,
    fontFamily: typography.fontFamily,
    heading: {
      fontSize: typography.fontSizes.fs17,
    },
    marketingOptions: {
      _input: {
        fill: colors.japaneseIndigo,
        background: colors.white,
        borderColor: colors.japaneseIndigo,
      },
    },
    svg: {
      padding: '3px',
      border: `1px solid ${colors.japaneseIndigo80}`,
    },
  },
  container: {
    _input: {
      fill: colors.japaneseIndigo,
      background: colors.white,
      borderColor: colors.japaneseIndigo,
    },
    _label: {
      fontSize: typography.fontSizes.fs14,
    },
  },
}

export default checkboxSelector
