import radius from 'themes/tokens/radius'
import borders from 'themes/tokens/borders'
import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'

export interface helpTextTypes {
  container: {
    padding?: string
    borderRadius?: string
    fontSize?: string
    default?: {
      backgroundColor?: string
      boxShadow?: string
      border?: string
      _before?: {
        background?: string
        width?: string
        height?: string
      },
    },
    basic?: {
      backgroundColor?: string
      borderStyle?: string
    },
    position: {
      right: {
        maxWidth?: string
        width?: string
        left?: string
        top?: string
      },
    },
  },
  headings?: {
    color?: string
    fontSize?: string
    fontWeight?: number
    lineHeight?: string
  },
  span?: {
    color?: string
    _before?: {
      color?: string
    },
  },
  trianglePos: {
    right: {
      borderLeft?: string
      borderBottom?: string
    },
    bottom: {
      borderLeft?: string
      borderTop?: string
    },
    topLeft: {
      basic: {
        borderColor?: string
        borderWidth?: string
      },
    },
  },
}

const helpText: helpTextTypes = {
  container: {
    padding: '15px',
    borderRadius: radius.r10,
    fontSize: typography.fontSizes.fs16,
    default: {
      backgroundColor: colors.white,
      boxShadow: `0px 4px 10px ${colors.gainsboro}`,
      border: borders.border1pxSolidGainsboro,
      _before: {
        background: colors.white,
        width: '12.75px',
        height: '12.75px',
      },
    },
    basic: {
      backgroundColor: colors.azureishWhite,
      borderStyle: borders.style.solid,
    },
    position: {
      right: {
        maxWidth: '275px',
        width: '275px',
        left: '50px',
        top: '-15px',
      },
    },
  },
  headings: {
    color: colors.amazonite,
    fontSize: typography.fontSizes.fs18,
    fontWeight: typography.fontWeight.bold,
    lineHeight: typography.lineHeights.lh22,
  },
  span: {
    color: colors.purpureus,
    _before: {
      color: colors.amazonite,
    },
  },
  trianglePos: {
    right: {
      borderLeft: borders.border1pxSolidGainsboro,
      borderBottom: borders.border1pxSolidGainsboro,
    },
    bottom: {
      borderLeft: borders.border1pxSolidGainsboro,
      borderTop: borders.border1pxSolidGainsboro,
    },
    topLeft: {
      basic: {
        borderColor: colors.azureishWhite,
        borderWidth: '0 17px 17px 17px',
      },
    },
  },
}

export default helpText
