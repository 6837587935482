import React from 'react'
import Heading from 'components/Atoms/Heading'
import { SubTitle, SubHeading } from './style'

export type IProps = {
  heading: string,
  headingText: string,
  subheadingText?: string,
  subTitle?: string,
  dataUxId?: string,
  headingAlign?: 'left' | 'center' | 'right',
  subheadingAlign?: string,
  subTitleAlign?: string,
  css?: string,
  subtitleId?: string,
  subheadingId?: string,
  subheadingCss?: string,
  subtitleCss?: string,
}

export const HeadingWithSubheading: React.FC<IProps> = ({
  heading,
  subheadingText,
  headingText,
  subTitle,
  dataUxId,
  headingAlign,
  subheadingAlign,
  subTitleAlign,
  subtitleId,
  subheadingId,
  css,
  subheadingCss,
  subtitleCss,
}) =>
  (
    <React.Fragment>
      <Heading
        heading={heading}
        id={dataUxId}
        align={headingAlign}
        css={css}
      >
        {headingText}
      </Heading>
      {subheadingText && (
        <SubHeading
          id={subheadingId}
          align={subheadingAlign}
          subheadingCss={subheadingCss}
        >
          {subheadingText}
        </SubHeading>
      )}
      {subTitle && (
        <SubTitle
          id={subtitleId}
          align={subTitleAlign}
          subtitleCss={subtitleCss}
        >
          {subTitle}
        </SubTitle>
      )}
    </React.Fragment>
  )
