// testing
import styled, { css } from 'styled-components'

type StyleProps = {
  align?: string,
  fonts?: string,
  weight?: number,
  colour?: string,
  css?: string,
  margin?: string,
  lineHeight?: string,
}

const HeadingOne = styled.h1<StyleProps>`
  ${({
    fonts, weight, colour, align, margin, lineHeight, theme: { components: { heading: { headingOne } } },
  }) => css`
    font-size: ${fonts || headingOne.fontSize};
    font-weight: ${weight || headingOne.fontWeight};
    color: ${colour || headingOne.color};
    text-align: ${align || headingOne.textAlign};
    margin: ${margin || '30px 0 30px 0'};
    line-height: ${lineHeight || headingOne.lineHeight};
  `}
  @media only screen and (max-width: 767.98px) {
    font-size: ${props => props.theme.components.heading.headingOne._mediaMobile.fontSize};
    margin-bottom: 22px;
  }
  ${props => props.css};
`

const HeadingTwo = styled.h2<StyleProps>`
  ${({
    fonts, weight, colour, align, margin, lineHeight, theme: { components: { heading: { headingTwo } } },
  }) => css`
    font-size: ${fonts || headingTwo.fontSize};
    font-weight: ${weight || headingTwo.fontWeight};
    color: ${colour || headingTwo.color};
    text-align: ${align || headingTwo.textAlign};
    margin: ${margin || '30px 0 30px 0'};
    line-height: ${lineHeight || headingTwo.lineHeight};
  `}
  ${props => props.css}
`

const HeadingThree = styled.h3<StyleProps>`
  ${({
    fonts, weight, colour, align, margin, lineHeight, theme: { components: { heading: { headingThree } } },
  }) => css`
    font-size: ${fonts || headingThree.fontSize};
    font-weight: ${weight || headingThree.fontWeight};
    color: ${colour || headingThree.color};
    text-align: ${align || headingThree.textAlign};
    margin: ${margin || '30px 0 30px 0'};
    line-height: ${lineHeight || headingThree.lineHeight};
  `}
  ${props => props.css};
`

const HeadingFour = styled.h4<StyleProps>`
  ${({
    fonts, weight, colour, align, margin, lineHeight, theme: { components: { heading: { headingFour } } },
  }) => css`
    font-size: ${fonts || headingFour.fontSize};
    font-weight: ${weight || headingFour.fontWeight};
    color: ${colour || headingFour.color};
    text-align: ${align || headingFour.textAlign};
    margin: ${margin || '30px 0 30px 0'};
    line-height: ${lineHeight || headingFour.lineHeight};
  `}
  ${props => props.css};
`

const HeadingFive = styled.h5<StyleProps>`
  ${({
    fonts, weight, colour, align, margin, lineHeight, theme: { components: { heading: { headingFive } } },
  }) => css`
    font-size: ${fonts || headingFive.fontSize};
    font-weight: ${weight || headingFive.fontWeight};
    color: ${colour || headingFive.color};
    text-align: ${align || headingFive.textAlign};
    margin: ${margin || '30px 0 30px 0'};
    line-height: ${lineHeight || headingFive.lineHeight};
  `}
  ${props => props.css}
`

const HeadingSix = styled.h6<StyleProps>`
  ${({
    fonts, weight, colour, align, margin, lineHeight, theme: { components: { heading: { headingSix } } },
  }) => css`
    font-size: ${fonts || headingSix.fontSize};
    font-weight: ${weight || headingSix.fontWeight};
    color: ${colour || headingSix.color};
    text-align: ${align || headingSix.textAlign};
    margin: ${margin || '30px 0 30px 0'};
    line-height: ${lineHeight || headingSix.lineHeight};
  `}
  ${props => props.css};
`

export {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  HeadingFour,
  HeadingFive,
  HeadingSix,
}
