import React from 'react'
import { isArray } from 'lodash'
import { LargeIconList, LargeIconObjects } from 'common/consts'

// import { LargeIconList, LargeIconObjects } from 'common/consts'
import * as Svg from './svgLoader'

const IconSelector = (i: string) => {
  if (LargeIconList.includes(i)) {
    return Svg[LargeIconObjects.filter(o => o.value === i)[0].key]()
  }
  return (<img alt="missing Icon" />)
}
//
export type IconProps = {
  /**
   * The name of svg to use
   * Available Icons:
   * ci, cci, nocci, noci, decreasing, level, female, male, joint, single, non-smoking, smoking
  */
  kind: string,
  /**
 * The Size in pixels
 * @type {string|number|array[width, height]}
 */
  size?: number | string | string[] | number[],
  id?: string,
  style?: object,
}

export const Icon = ({
  kind,
  size = 200,
  style,
  id,
}: IconProps) => React.cloneElement(
  IconSelector(kind),
  {
    style: { margin: 10, ...style },
    width: isArray(size) ? size[0] : size,
    height: isArray(size) ? size[1] : size,
    id,
    'data-testid': kind,
  },
)
