import radius from 'themes/tokens/radius'
import colors from 'themes/tokens/colors'
import alignment from 'themes/tokens/alignment'

export interface errorTypes {
    background?: string
    color?: string
    textAlign?: string
    width?: string
    padding?: string
    borderRadius?: string
}

const error: errorTypes = {
  background: colors.sunsetOrange,
  color: colors.white,
  textAlign: alignment.text.center,
  width: '100%',
  padding: '16px 10px',
  borderRadius: radius.r3,
}

export default error
