import styled from 'styled-components'

type ProgressValueProps = {
  progressValue: number,
  color: string,
  name: string,
}

const ProgressBarWrapper = styled.ul<{ css: string[] | undefined }>`
  width: 100%;
  height:5px;
  overflow:hidden;
  display:block;
  margin:0;
  padding:0;
  li:first-child {
    border-radius:100px 0 0 100px;
  }
  li:last-child {
    border-radius:0 100px 100px 0;
  }

  li:only-child {
    border-radius: 100px;
  }

  &:empty {
    border: 1px solid #B9C4D3;
    border-radius: 100px 100px 100px 100px;
    margin:0;
    padding:0;
    height: 5px;
    box-sizing: border-box;
    width: calc(100% - 5px);
  }
`

const ProgressValue = styled.li<ProgressValueProps>`

  width: calc(${props => props.progressValue}% - 3px);
  @media screen and (min-width: 992px) {
    width: calc(${props => props.progressValue}% - 5px);
  }
  background: ${props => props.color};
  height: 5px;
  float:left;
  list-style:none;
  display:inline;
  margin-right:5px;
`

export {
  ProgressBarWrapper,
  ProgressValue,
}
