import styled, { css } from 'styled-components'
import Paragraph from 'components/Atoms/Paragraph'
import Input from 'components/Atoms/Input'

interface Props {
  mobileSize?: string[],
}

interface ContainerProps {
  marginBtm?: string,
  borderTop?: boolean,
}

interface QuestionWrapperProps {
  align?: 'flex-start' | 'center' | 'flex-end',
}

interface TextProps {
  size: string[],
}

const PolarQuestionContainer = styled.div<ContainerProps>`
  ${({ borderTop, theme: { components: { polarQuestion: { container } } } }) => borderTop ? css`
    border-top: ${container.borderTop};
    padding-top: ${container.paddingTop};
  ` : null}

  @media only screen and (max-width: 768px) {
    ${props => props.marginBtm ? `margin-bottom: ${props.marginBtm}` : null};
  }
`

const StyledParagraph = styled(Paragraph)`
  position: relative;
  margin-bottom: 10px;
  text-align: ${props => props.align || 'left'};

  ${({ theme: { components: { polarQuestion: { paragraph } } } }) => css`
    font-size: ${paragraph.fontSize};
    line-height: ${paragraph.lineHeight};
    color: ${paragraph.color};
    font-weight: ${paragraph.fontWeight};
  `}
`

const StyleSubParagraph = styled(Paragraph)`
  ${({ theme: { components: { polarQuestion: { subParagraph } } } }) => css`
    font-size: ${subParagraph.fontSize};
    color: ${subParagraph.color};
  `}
`

const QuestionWrapper = styled.div<QuestionWrapperProps>`
  position: relative;
  display: flex;
  justify-content: ${props => props.align || 'flex-start'};

  label {
    ${props => props.align === 'center' ? `text-align: ${props.align}` : null};
    margin-bottom: 0;

    &:hover, &:focus {
      svg {
        .svgBackground {
          fill: ${props => props.theme.components.polarQuestion.questionWrapper.svgHoverAndFocus};
        }
      }
    }
  }
`

const InputHidden = styled(Input)`
  position: absolute;
  left: -9999px;
`

const IconWrapper = styled.div<Props>`
  position: relative;

  &:first-child {
    margin-right: 30px;
  }

  // change icon background color when selected
  input {
    ~ label {
      svg {
        margin: 0px auto 0px 0px!important;
        path {
          &.selectedCircle {
            stroke: ${props => props.theme.components.polarQuestion.questionWrapper.uncheckedCircleStroke};
          }
          &.diagonalLine {
            fill: ${props => props.theme.components.polarQuestion.questionWrapper.svgUncheckedDiagonalFill};
          }
          &.selectedOutline {
            fill: ${props => props.theme.components.polarQuestion.questionWrapper.checkedCircleStroke};
          }
        }

        @media only screen and (max-width: 478px) {
          svg {
            ${props => props.mobileSize ? `height: ${props.mobileSize[1]}` : ''}
            ${props => props.mobileSize ? `width: ${props.mobileSize[0]}` : ''}
          }
        }
      }
    }
    &:checked {
      ~ label {
        ~ div.PolarQuestion_label {
          color: ${props => props.theme.components.polarQuestion.questionWrapper.labelCheckedColor};
        }
        svg {
          path {
            &.selectedCircle {
              fill: ${props => props.theme.components.polarQuestion.questionWrapper.svgSelectedCircleFill};
              stroke: ${props => props.theme.components.polarQuestion.questionWrapper.checkedCircleStroke};
            }

            &.selectedOutline {
              display: none;
            }

            &.selectedFill {
              fill: ${props => props.theme.components.polarQuestion.questionWrapper.svgSelectedFill};

            }

            &.stroke {
              stroke: ${props => props.theme.components.polarQuestion.questionWrapper.svgSelectedFill};
            }

            &.selectedFill.greyBlueColor {
              fill: ${props => props.theme.components.polarQuestion.questionWrapper.svgSecondFill}!important;
            }

            &.tickGrow {
              stroke: ${props => props.theme.components.polarQuestion.questionWrapper.svgTickGrow};
              transform: scale(1.01) translate(2px, 0px);
            }

            &.diagonalLine {
              fill: ${props => props.theme.components.polarQuestion.questionWrapper.svgCheckedDiagonalFill};
              &.stroke {
                stroke: ${props => props.theme.components.polarQuestion.questionWrapper.checkedCircleStroke};
              }
            }
          }
          &:hover, &:focus {
            path {
              &.selectedFill {
                fill: ${props => props.theme.components.polarQuestion.questionWrapper.activeHoverFill};
              }
            }

            .tickGrow {
              @media screen and (min-width: 1025px) {
                transform: scale(1.1) translate(4px, 1px);
              }
            }
          }
        }
      }
    }
  }
`

// Text div has the same width as the icon so it doesn't push the parent div wider if there is a lot of text.
const IconText = styled.div<TextProps>`
  ${({ theme: { components: { polarQuestion: { iconText } } } }) => css`
    text-align: ${iconText.textAlign};
    font-size: ${iconText.fontSize};
    line-height: ${iconText.lineHeight};
    font-weight: ${iconText.fontWeight};
    color: ${iconText.color};
    width: ${iconText.width};
  `}
`

export {
  PolarQuestionContainer,
  StyledParagraph,
  StyleSubParagraph,
  QuestionWrapper,
  InputHidden,
  IconWrapper,
  IconText,
}
