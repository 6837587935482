import React from 'react'

import StyledLabel from './style'

export type LabelProps = {
  text?: string,
  htmlFor: string,
  onClick?: () => void,
  dataUxId?: string,
  children?: React.ReactNode,
  id?: string,
  css?: string,
}

export const Label: React.FC<LabelProps & React.HTMLAttributes<HTMLLabelElement>> = ({
  htmlFor,
  text,
  onClick,
  dataUxId,
  children,
  id,
  css,
}) =>
  (
    <StyledLabel
      htmlFor={htmlFor}
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
      id={id || `Label_${dataUxId}`}
      css={css}
    >
      {text}
      {children}
    </StyledLabel>
  )
