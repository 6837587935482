import styled from 'styled-components'

const Container = styled.div`
  // ARROW
  button {
    cursor: pointer;
    border: none;
    background: none;
    box-shadow: none;
    font-size: 1.2rem;
    min-width: 20px;
    width: 20px;
    color: #373737;
    top: 100px;

    &:hover, &:focus {
      &:enabled {
        background: none;
        box-shadow: none;
        color: #373737;
      }
    }

    @media screen and (max-width: 767px) {
      top: 120px;
    }

    @media screen and (min-width: 401px) and (max-width: 575px) {
      top: 100px;
    }

    @media screen and (max-width: 400px) {
      top: 110px;
    }
  }

  .glider-contain {
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 575px) {
      width: 90%;
    }
  }

  .glider-track {
    > div {
      width: 100%;
      max-width: 320px;

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        min-height: 219px;
      }
      @media screen and (min-width: 576px) and (max-width: 767px) {
        min-height: 265px;
      }
      @media screen and (max-width: 575px) {
        max-width: 479px;
      }

      @media screen and (min-width: 540px) and (max-width: 575px) {
        height: 100%!important;
        min-height: 185px;
      }
      @media screen and (min-width: 501px) and (max-width: 539px) {
        height: 100%!important;
        min-height: 150px;
      }
    }
  }

  .glider {
    /* Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`

type SliderProps = {
  boxShadowEnabled?: boolean,
}

const Slider = styled.div<SliderProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  min-height: 204px;
  background-color: white;
  margin: 10px;
  font-size: 14px;
  padding: 17px 16px;

  @media screen and (max-width: 991px) {
    ${props => props.boxShadowEnabled && 'box-shadow: 2px 2px 10px #DBE1EB;'}
  }

  p {
    &[id^="review_"] {
      margin: 0;

      @media screen and (min-width: 501px) and (max-width: 539px) {
        margin-bottom: 15px;
      }

      @media screen and (min-width: 320px) and (max-width: 500px) {
        margin-bottom: 15px;
      }
    }
  }

  div {
    &[id^="tpStars"] {
      svg {
        height: 21px;
        margin: 0!important;
      }
    }
  }

  div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: auto;
    p {
      &[id^="reviewName_"] {
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 8px;
      }
      &[id^="company_"] {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
`

export {
  Container,
  Slider,
}
