import styled, { css } from 'styled-components'
// import { theme } from 'themes'
import Input from 'components/Atoms/Input'
import { componentTypes } from 'themes/themeTypes'

interface ContainerProps {
  isError?: boolean
  css?: string
}

const Container = styled.div<ContainerProps>`
  display: flex;
  min-height: 60px;

  ${({ isError, theme: { components: { inputGroup: { container } } } }) => css`
    background: ${container.background};
    border: ${isError ? `${container.borderError}!important` : container.border};
    border-radius: ${container.borderRadius};
  `}

  &.active {
    border-color: ${({ theme }) => theme.components.inputGroup.container._active.borderColor};
  }
  ${props => props.css};
`

interface InputGroupStyledProps {
  isError?: boolean
}

const InputGroupStyled = styled.div<InputGroupStyledProps>`
  align-self: center;

  ${({ theme: { components: { inputGroup } } }) => css`
    text-align: ${inputGroup.textAlign};
    padding-left: ${inputGroup.paddingLeft};
    padding-right: ${inputGroup.paddingRight};
  `}

  span {
    ${({ theme: { components: { inputGroup } } }) => css`
      font-weight: ${inputGroup.fontWeight};
      font-size: ${inputGroup.fontSize};
      line-height: ${inputGroup.lineHeight};
      color: ${inputGroup.color};
    `}
  }

  ${props => props.isError && css`
    span {
      color: ${({ theme }) => theme.components.inputGroup.colorError}!important;
    }
  `}
`

interface InputStyledProps {
  position?: 'left' | 'right'
  isError?: boolean
  id?: any
}

// idea, a function that gets correct styles
const getPositionStyles = (position: string | undefined, isError: boolean | undefined, theme: componentTypes) => {
  const { components: { inputGroup: { _position } } } = theme
  switch (position) {
    case 'left':
      return `
        border-right: 0.5px solid ${isError ? _position.borderColorError : _position.borderColor};
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 3.5px;
        border-top-left-radius: 3.5px;
      `
    // Default position is right
    default:
      return `
        border-left: 0.5px solid ${isError ? _position.borderColorError : _position.borderColor};
        border-bottom-right-radius: 3.5px;
        border-top-right-radius: 3.5px;
      `
  }
}

const InputStyled = styled(Input) <InputStyledProps>`
    /* Overriding input default styles */
    margin: 0;
    padding-bottom: 0;

    /* New styles */
    width: 100%;
    min-width: 0;
    border: none;
    ${props => getPositionStyles(props.position, props.isError, props.theme)}
    ${({ theme: { components: { inputGroup: { input } } } }) => css`
      font-size: ${input.fontSize};
      line-height: ${input.lineHeight};
      text-align: ${input.textAlign};
      color: ${input.color};
      font-weight: ${input.fontWeight};
    `}
`

export {
  Container,
  InputGroupStyled,
  InputStyled,
}
