import styled, { css } from 'styled-components'
import { query } from 'themes'

type InputHiddenProps = {
  type: string,
  name: string,
  value: string,
  onChange: () => void,
  onInput: () => void,
  id: string,
  checked: boolean,
}

const InputHidden = styled.input <InputHiddenProps>`
  position: absolute;
  left: -9999px;
`

const LabelActiveState = css`
  background: ${props => props.theme.components.radioInput.activeLabel.background};
  border-color: ${props => props.theme.components.radioInput.activeLabel.borderColor};
  color: ${props => props.theme.components.radioInput.activeLabel.color};
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    flex: 1 0 auto;
    margin: 5px;
    ${query.extraSmall`
      margin: 3px;
    `}
  }
`

const StyledInputHidden = styled(InputHidden)`
  &:checked + label {
    ${LabelActiveState}
  }
  &:focus + label {
    ${LabelActiveState}
  }
`

const Item = styled.label`
  ${({ theme: { components: { radioInput: { item } } } }) => css`
    padding: ${item.padding};
    border: ${item.border};
    font-size: ${item.fontSize};
    line-height: ${item.lineHeight};
    text-align: ${item.textAlign};
    border-radius: ${item.borderRadius};
    color: ${item.color};
  `}
  display: block;
  cursor: pointer;
  ${query.combine(['extraSmall'], css`
    font-size: ${({ theme }) => theme.components.radioInput.item.fontSizeExtraSmall};
  `)}
  &:hover {
    ${LabelActiveState}   
    background: ${({ theme }) => theme.components.radioInput.item._hover.background};
    color: ${({ theme }) => theme.components.radioInput.item._hover.color};
    border-color: ${({ theme }) => theme.components.radioInput.item._hover.borderColor};
  }
`

const Legend = styled.legend`
  margin: 0 0 5px;
  font-size: ${({ theme }) => theme.components.radioInput.legend.fontSize};
  font-weight: ${({ theme }) => theme.components.radioInput.legend.fontWeight};
  color: ${({ theme }) => theme.components.radioInput.legend.color};


`

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`

export {
  Container,
  Item,
  StyledInputHidden as InputHidden,
  Legend,
  Fieldset,
}
