import colors from './colors'

interface borderTypes {
  none: string
  border1pxSolidAmazonite: string
  border1pxSolidPastelGrey: string
  border1pxSolidDarkCharcoal: string
  border1pxSolidJapaneseIndigo: string
  border1pxSolidGainsboro: string
  border1pxSolidSilverSandy:string
  border1pxSolidHeather: string
  border1pxSpanishGray: string
  border1pxPlatinum: string
  border1pxElectricRed: string
  border2pxSolidFulvous: string
  style: {
    solid: string
  },
  outline: {
    none: string
  },
}

const borders: borderTypes = {
  none: 'none',
  border1pxSolidAmazonite: `1px solid ${colors.amazonite}`,
  border1pxSolidPastelGrey: `1px solid ${colors.pastelGrey}`,
  border1pxSolidDarkCharcoal: `1px solid ${colors.darkCharcoal}`,
  border1pxSolidJapaneseIndigo: `1px solid ${colors.japaneseIndigo}`,
  border1pxSolidGainsboro: `1px solid ${colors.gainsboro}`,
  border1pxSolidSilverSandy: `1px solid ${colors.silverSand}`,
  border1pxSolidHeather: `1px solid ${colors.heather}`,
  border1pxSpanishGray: `1px solid ${colors.budget.spanishGray}`,
  border1pxPlatinum: `1px solid ${colors.virginMoney.platinum}`,
  border1pxElectricRed: `1px solid ${colors.virginMoney.electricRed}`,
  border2pxSolidFulvous: `2px solid ${colors.fulvous}`,
  style: {
    solid: 'solid',
  },
  outline: {
    none: 'none',
  },
}

export default borders
