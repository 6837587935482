import ThemeBuilder from '../themeBuilder'

const beaglestreetTheme = new ThemeBuilder('BeagleStreet')

// global styles will be overwritten by QQ
// populated by tokens files inside each component folder
const themeOverrides = {
  components: {
    button: {
      variant: {
        underline: {
          color: 'rgb(142, 90, 173)',
          borderColor: 'rgb(142, 90, 173)',
        },
      },
    },
  },
}

beaglestreetTheme.merge(themeOverrides)

export default beaglestreetTheme
