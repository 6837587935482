import styled, { css } from 'styled-components'
import Icon from '../Icon'

const SelectWrapper = styled.div`
  position: relative;
`

const StyledSelect = styled.select`
  border: none;
  appearance: none;
  background: transparent;
  cursor: pointer;

  ${({ theme: { components: { dropdown } } }) => css`
    border-bottom: ${dropdown.borderBottom};
    font-size: ${dropdown.fontSize};
    color: ${dropdown.color};
    padding: ${dropdown.padding};
    width: ${dropdown.width};
  `}

  &:after {
    content: '';
  }
  option {
    font-size: ${({ theme }) => theme.components.dropdown._option.fontSize};
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  ${({ theme: { components: { dropdown } } }) => css`
    right: ${dropdown.pos.right};
    top: ${dropdown.pos.top};
    fill: ${dropdown.fill}
  `}
`

export {
  StyledSelect,
  SelectWrapper,
  StyledIcon,
}
