import borders from 'themes/tokens/borders'
import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'
import ThemeBuilder from '../themeBuilder'

const budgetLifeTheme = new ThemeBuilder('BudgetLife')

// global styles will be overwritten by QQ
const themeOverrides = {
  components: {
    inputBreakdown: {
      typography: {
        color: colors.budget.purple,
        textColor: colors.budget.darkGrey,
        guidanceTextColor: colors.budget.darkGrey,
        buttonColor: colors.budget.honoluluBlue,
        iconColor: colors.budget.purple,
      },
    },
    button: {
      variant: {
        primary: {
          background: colors.budget.honoluluBlue,
        },
        underline: {
          borderColor: colors.budget.honoluluBlue,
        },
      },
    },
    pricingCard: {
      wrapperBoxShadow: '0px 0px 25px 5px rgba(0, 0, 0, 0.05)',
      wrapperBorderRadius: radius.r5,
      backgroundColor: colors.white,
      headingColor: colors.budget.darkGrey,
      iqPriceColor: colors.budget.purple,
      paragraphColor: colors.budget.darkGrey,
      spanColor: colors.budget.darkGrey,
      guidanceMobileTextColor: colors.budget.darkGrey,
    },
    formInput: {
      labelActiveState: {
        background: '#44842F',
        color: colors.white,
        borderColor: '#44842F',
        svgFill: colors.white,
      },
      item: {
        background: colors.white,
        border: borders.border1pxSpanishGray,
        color: '#685BA1',
        _hover: {
          backgroundSmall: '#44842F',
          colorSmall: colors.white,
        },
      },
      selectedIcon: {
        border: borders.none,
        borderRadius: radius.none,
        padding: '0',
        background: 'none',
        fill: colors.white,
      },
    },
    inputBox: {
      poundSign: {
        color: colors.budget.purple,
      },
      color: colors.budget.purple,
    },
  },
}

budgetLifeTheme.merge(themeOverrides)

export default budgetLifeTheme
