import typography from 'themes/tokens/typography'

export interface iconWithTextTypes {
  textWrapper?: {
    lineHeight?: string
    fontSize?: string
  },
}

const iconWithText: iconWithTextTypes = {
  textWrapper: {
    lineHeight: typography.lineHeights.lh24,
    fontSize: typography.fontSizes.fs16,
  },
}

export default iconWithText
