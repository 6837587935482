import styled from 'styled-components'
import Paragraph from 'components/Atoms/Paragraph'

type IProps = {
  subheadingCss?: string,
  subtitleCss?: string,
}

const SubHeading = styled(Paragraph) <IProps>`
  font-size: ${({ theme }) => theme.components.headingWithSubheading.subheadingFontSize};
  text-align: center;
  ${props => props.subheadingCss}
`

const SubTitle = styled(Paragraph) <IProps>`
  color: ${({ theme }) => theme.components.headingWithSubheading.subtitleColor};
  text-align: center;
  font-size: ${({ theme }) => theme.components.headingWithSubheading.subtitleFontSize};
  width: 100%;
  margin: 10px 0 1rem;
  ${props => props.subtitleCss}
`

export {
  SubHeading,
  SubTitle,
}
