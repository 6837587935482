import borders from 'themes/tokens/borders'
import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'
import alignment from 'themes/tokens/alignment'

export interface inputPoundTypes {
  label?: {
    fontSize?: string
    lineHeight?: string
    color?: string
  },
  regularPound?: {
    signFontSize?: string
    signColor?: string
  },
  largePound?: {
    signFontSize?: string
    signColor?: string
    inputFontSize?: string
    inputPadding?: string
  },
  input?: {
    padding?: string
    borderBottom?: string
    fontSize?: string
    width?: string
    textAlign?: string
    color?: string
    _valid?: {
      background?: string
    },
  },
}

const inputPound: inputPoundTypes = {
  label: {
    fontSize: typography.fontSizes.fs19,
    lineHeight: typography.lineHeights.lh24,
    color: colors.raisinBlack,
  },
  regularPound: {
    signFontSize: typography.fontSizes.fs24,
    signColor: colors.black,
  },
  largePound: {
    signColor: colors.black,
    signFontSize: typography.fontSizes.fs52,
    inputFontSize: typography.fontSizes.fs42,
    inputPadding: '0 0 12px 50px',
  },
  input: {
    padding: '10px 0 10px 25px',
    borderBottom: borders.border1pxSolidPastelGrey,
    fontSize: typography.fontSizes.fs24,
    width: '100%',
    textAlign: alignment.text.left,
    color: colors.japaneseIndigo,
    _valid: {
      background: colors.purpureus,
    },
  },
}

export default inputPound
