import React from 'react'
import { IProps } from 'components/Atoms/Input/Input'
import { Wrapper, StyledInput, PoundSign } from './style'

/**
 * Component is an input with border, hidden label. If boolean set true, pound signs appears before input.
 */

export interface InputBoxProps extends IProps {
  borderColor: string,
  textAlign?: 'center' | 'left',
  pound?: boolean,
}

const InputBox: React.FC<InputBoxProps> = ({
  type,
  id,
  borderColor,
  textAlign = 'center',
  value,
  onChange,
  name,
  pound = false,
}) =>
  (
    <Wrapper>
      {pound && <PoundSign>£</PoundSign>}
      <StyledInput
        type={type}
        id={id}
        name={name}
        className={`${id}_inputBox`}
        borderColor={borderColor}
        textAlign={textAlign}
        title="This is an input"
        value={value}
        onChange={onChange}
        required={false}
      />
    </Wrapper>
  )

export default InputBox
