import typography from '../../../themes/tokens/typography'
import colors from '../../../themes/tokens/colors'

export interface anchorTypes {
  fontStyle?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  textDecorationLine?: string
  color?: string
}

const anchor: anchorTypes = {
  fontStyle: typography.fontStyle.normal,
  fontWeight: typography.fontWeight.regular,
  fontSize: typography.fontSizes.fs14,
  lineHeight: typography.lineHeights.lh21,
  textDecorationLine: typography.textDecoration.underline,
  color: colors.royalPurple,
}

export default anchor
