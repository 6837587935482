import colors from 'themes/tokens/colors'

export interface manualAddressTypes {
  colorError?: string
}

const manualAddress: manualAddressTypes = {
  colorError: colors.sunsetOrange,
}

export default manualAddress
