import React from 'react'
import { FieldsetStyled } from './style'

const Fieldset = React.forwardRef<
  HTMLFieldSetElement, React.ComponentPropsWithoutRef<'fieldset'>
>(({
  children, ...props
}, ref) => <FieldsetStyled ref={ref} {...props}>{children}</FieldsetStyled>)

Fieldset.displayName = 'Fieldset'

export default Fieldset
