import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'

export interface wrapperTypes {
  borderRadius?: string
  boxShadow?: string
  padding?: string
}

const wrapper: wrapperTypes = {
  borderRadius: radius.r16,
  boxShadow: `0 0.4em 1em ${colors.gainsboro}`,
  padding: '24px',
}

export default wrapper
