import React from 'react'
import classNames from 'classnames'
import { isUndefined, isObject } from 'lodash'

import styles from './style.module.css'

const DEVICE_SIZES = ['xl', 'lg', 'md', 'sm', 'xs']

type offsetP = {
  offset?: number,
  span?: number,
}

export type ColProps = {
  xs?: string | number | object | offsetP,
  sm?: string | number | object | offsetP,
  md?: string | number | object | offsetP,
  lg?: string | number | object | offsetP,
  xl?: string | number | object | offsetP,
  children: React.ReactNode,
  className?: string,
  span?: string | number,
  offset?: string | number,
  order?: string | number,
  style?: React.CSSProperties,
}

export type Test = {
  span: boolean,
}

export const Col: React.FC<ColProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  children,
  className,
  style,
  ...props
}) => {
  const classes: string[] = []
  const spans = []
  const sizeObj = {
    xs, sm, md, lg, xl,
  }

  DEVICE_SIZES.forEach(size => {
    const value: string = sizeObj[size]
    let span
    let offset
    let order

    if (!isUndefined(value) && isObject(value)) {
      ({ span = true, offset, order } = value)
    } else {
      span = value
    }

    if (!isUndefined(span)) {
      spans.push(
        span === true || size === 'xs' ? styles[`col-${span}`] : styles[`col-${size}-${span}`],
      )
    }

    if (!isUndefined(offset)) {
      classes.push(
        styles[`offset${size === 'xs' ? '' : `-${size}`}-${offset}`],
      )
    }

    if (!isUndefined(order)) {
      classes.push(
        styles[`order${size === 'xs' ? '' : `-${size}`}-${order}`],
      )
    }
  })

  spans.push(styles.col)

  return (
    <div className={classNames(className, ...classes, ...spans, '')} style={style} {...props}>
      {children}
    </div>
  )
}

export default Col
