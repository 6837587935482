import React from 'react'
import Label from 'components/Atoms/Label'
import { InputPoundContainer, InputWrapper, StyledInput } from './style'

export type IProps = {
  id: string,
  type: string,
  labelText?: string,
  hasErrored?: boolean,
  name: string,
  onChange: () => void,
  onFocus?: () => void,
  onBlur?: () => void,
  onKeyDown?: () => void,
  dataUxId?: string,
  pattern?: string,
  value?: string,
  largePound?: boolean,
  maxLength?: number,
}

export const InputPound: React.FC<IProps> = ({
  id,
  type,
  labelText,
  hasErrored,
  name,
  onChange,
  onBlur,
  onFocus,
  dataUxId,
  pattern,
  value,
  largePound,
  onKeyDown,
  maxLength,
}) =>
  (
    <InputPoundContainer className={largePound ? 'largePound' : undefined}>
      {labelText && (
        <Label
          htmlFor={id}
          id={`PoundLabel_${id}`}
          text={labelText}
        />
      )}
      <InputWrapper>
        <span className="poundSign">£</span>
        <StyledInput
          type={type}
          id={id}
          name={name}
          hasErrored={hasErrored}
          dataUxId={`InputPound_${dataUxId}`}
          pattern={pattern}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onChange}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          ariaLabel={labelText || undefined}
        />
      </InputWrapper>
    </InputPoundContainer>
  )
