import Paragraph from 'components/Atoms/Paragraph'
import React, { useState } from 'react'
import {
  TooltipWrapper,
  TooltipLink,
  TooltipText,
  BasicTooltipWrapper,
} from './style'

export interface TooltipProps {
  id?: string,
  text?: string,
  title?: string,
  kind?: string,
  top?: string,
  children?: React.ReactNode,
  css?: string,
  color?: string,
  align?: string,
  className?: string,
  linkAlign?: string,
  centeredArrow?: boolean,
}

export const Tooltip: React.FC<TooltipProps & React.HTMLAttributes<HTMLElement>> = ({
  id,
  title,
  text,
  kind,
  children,
  css,
  top,
  color,
  align,
  className,
  linkAlign,
  centeredArrow,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  switch (kind) {
    case 'basic':
      return (
        <BasicTooltipWrapper id={id} css={css}>
          <Paragraph colour={color} align={align}>{children}</Paragraph>
        </BasicTooltipWrapper>
      )
    default:
      return (
        <TooltipWrapper
          id={id}
          className={className}
        >
          <TooltipLink as="a" onClick={() => setIsVisible(!isVisible)} linkAlign={linkAlign}>
            {title}
          </TooltipLink>
          <TooltipText top={top} className={isVisible ? 'visible' : 'hidden'} id={centeredArrow ? 'centeredArrow' : undefined}>
            {text}
          </TooltipText>
        </TooltipWrapper>
      )
  }
}
