import styled, { css } from 'styled-components'

type SProps = {
  animation: string,
}

const animation = (state: string) => {
  switch (state) {
    case 'entering':
      return css`
        display: block;
      `
    case 'entered':
      return css`
        display: block;
        opacity: 1;
      `
    case 'exited':
      return css`
        display: none;
      `
    default:
      return ''
  }
}

const TransitionContainer = styled.div<SProps>`
  transition: opacity 1000ms ease;
  opacity: 0;
  display: none;
  ${props => animation(props.animation)}

  .errorMessage {
    padding-bottom: 10px;
  }
`

export default TransitionContainer
