import borders from 'themes/tokens/borders'
import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'

export interface textInputTypes {
  spanMobile?: {
    fontSize?: string
  },
  input?: {
    borderBottom?: string
    fontSize?: string
    _focus?: {
      fontSize?: string
      color?: string
    },
    _alt?: {
      color?: string
      fontSize?: string
    },
  },
  label?: {
    textHolder?: {
      fontSize?: string
      color?: string
      fontSizeSmall?: string
    },
    input?: {
      fontSize?: string
    },
  },
  text?: {
    fontSize?: string
  },
  bar?: {
    background?: string
  },
}

const textInput: textInputTypes = {
  spanMobile: {
    fontSize: typography.fontSizes.fs19,
  },
  input: {
    borderBottom: borders.border1pxSolidPastelGrey,
    fontSize: typography.fontSizes.fs24,
    _focus: {
      fontSize: typography.fontSizes.fs20,
      color: colors.amazonite,
    },
    _alt: {
      color: colors.japaneseIndigo,
      fontSize: typography.fontSizes.fs24,
    },
  },
  label: {
    textHolder: {
      fontSize: typography.fontSizes.fs18,
      color: colors.japaneseIndigo,
      fontSizeSmall: typography.fontSizes.fs16,
    },
    input: {
      fontSize: typography.fontSizes.fs24,
    },
  },
  text: {
    fontSize: typography.fontSizes.fs24,
  },
  bar: {
    background: colors.amazonite,
  },
}

export default textInput
