import alignment from 'themes/tokens/alignment'
import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'
import typography from 'themes/tokens/typography'

export interface radioInputTypes {
  activeLabel?: {
    background?: string
    borderColor?: string
    color?: string
  },
  item?: {
    padding?: string
    border?: string
    fontSize?: string
    lineHeight?: string
    textAlign?: string
    borderRadius?: string
    fontSizeExtraSmall?: string
    color?: string
    _hover?: {
      background?: string
      color?: string
      borderColor?: string
    },
  },
  legend?: {
    fontSize?: string,
    fontWeight?: number,
    color?: string,
  },
}

const radioInput: radioInputTypes = {
  activeLabel: {
    background: colors.amazonite,
    borderColor: colors.amazonite,
    color: colors.white,
  },
  item: {
    padding: '7px 5px',
    border: `1px solid ${colors.raisinBlack}`,
    fontSize: typography.fontSizes.fs20,
    lineHeight: typography.lineHeights.lh26,
    textAlign: alignment.text.center,
    borderRadius: radius.r5,
    fontSizeExtraSmall: typography.fontSizes.fs18,
    color: colors.black,
    _hover: {
      background: colors.white,
      color: colors.black,
      borderColor: colors.black,
    },
  },
  legend: {
    fontSize: typography.fontSizes.fs20,
    fontWeight: typography.fontWeight.regular,
    color: colors.black,
  },
}

export default radioInput
