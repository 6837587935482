import { isUndefined } from 'lodash'

/*
  This file contains any helper functions that are necessary to generate correct output of work.
*/

const getHelpTextArrowPositionOnMobile = (containerCssSelector: string, element: HTMLElement, tabsCssSelector: string) => {
  const containerWidth = document.querySelector(containerCssSelector)?.getBoundingClientRect().width

  let fromLeft = 0
  if (containerWidth) {
    if ((element.getBoundingClientRect().left + (element.getBoundingClientRect().width / 2)) > containerWidth) {
      const tabs = document.querySelector(tabsCssSelector)
      if (tabs) tabs.scrollLeft += element.getBoundingClientRect().width + 10
    }

    if ((element.getBoundingClientRect().left) < element.getBoundingClientRect().width) {
      const tabs = document.querySelector(tabsCssSelector)
      if (tabs) tabs.scrollLeft -= element.getBoundingClientRect().width + 15
    }

    fromLeft = (element.getBoundingClientRect().left + (element.getBoundingClientRect().width / 2))
  }
  return fromLeft
}

const getElementOffset = (cssSelector: string) => document.querySelector(cssSelector)?.getBoundingClientRect().left

const getBoxTopArrowPos = (item: HTMLElement, tabBodyContainerCssSelector: string, tabItemsWrapperCssSelector: string) => {
  const position = getHelpTextArrowPositionOnMobile(tabBodyContainerCssSelector, item, tabItemsWrapperCssSelector)
  const offset = getElementOffset(tabItemsWrapperCssSelector)
  let left = '0'
  if (offset) left = (position - offset).toString()
  return left
}

/**
 * Truncates a strong and adds an elipsis
 * @param {string} string to truncate
 * @param {number} length to truncate
 * @returns {sting} return string truncated with elipsis
 */
const truncateStr = (input: string, length: number) => !isUndefined(input) && input.length > length ? `${input.substring(0, length)}...` : input

/**
 * Removes whitespace from string
 * @param {string} str - string
 * @returns {string} string without
 */
const removeWhitespace = (str: string) =>
  str ? str.toString().replace(/\s/g, '') : str

/*
 * Create unique id based on component and location
 * @param {string} string, this is a key/value cookie
 * @returns {string}
 */
const uniqueIdentifier = (id = '') => {
  const { location } = window
  return `${location.pathname.replace('/', '')}_${id}`
}

const formatCurrency = (str: string) => {
  const x = (parseFloat(str)).toString().split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? `.${x[1]}` : ''
  while (/(\d+)(\d{3})/.test(x1)) {
    x1 = x1.replace(/(\d+)(\d{3})/, '$1,$2')
  }
  return x1 + x2
}

export {
  getHelpTextArrowPositionOnMobile,
  getElementOffset,
  getBoxTopArrowPos,
  truncateStr,
  removeWhitespace,
  uniqueIdentifier,
  formatCurrency,
}
