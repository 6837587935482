import React, { useState, useEffect } from 'react'
import { isUndefined } from 'lodash'
import Button from 'components/Atoms/Button'
import Icon from 'components/Atoms/Icon'
import {
  PricingCardWrapper, PricingHeader, PricingBody, PricingFooter, MobileContinueWrapper, HeadingStyled, IqPriceStyled, ParagraphStyled, SpanStyled,
} from './style'
import { PricingCardProps } from './types'

const priceError = ['0.00', '0.0', '0']

const PricingCard: React.FC<PricingCardProps> = ({
  price,
  expiry,
  url,
  loading,
  guidance,
  description,
  showInterstitialModal,
}) => {
  const [pricePounds, setPricePounds] = useState<string>('')
  const [pricePennies, setPricePennies] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    setError(false)
    const thePrice = typeof price === 'number' ? price.toFixed(2).toString() : price

    if (priceError.indexOf(thePrice) !== -1 || isUndefined(thePrice)) setError(true)
    else {
      const arr = thePrice && thePrice.split('.')
      const pounds = arr[0]
      const pennies = arr[1] ? arr[1] : '00'

      setPricePounds(pounds)
      setPricePennies(pennies)
    }
  }, [price])
  return (
    <PricingCardWrapper data-testid="pricingCard">
      <HeadingStyled heading="h1">Your Quote</HeadingStyled>
      <PricingHeader>
        <div>
          {loading || error
            ? (<Icon kind="loader" size={50} data-testid="spinner" />
            )
            : (
              <IqPriceStyled id="iq_price">{`£${pricePounds}.`}<span id="iq_pennies">{pricePennies}</span></IqPriceStyled>
            )}
        </div>
        <ParagraphStyled>per month</ParagraphStyled>
      </PricingHeader>
      <PricingBody id={guidance ? 'guidancePricing' : undefined}>
        {(!error && (
          <React.Fragment>
            <Icon kind="clock" style={{ marginRight: '10px' }} />
            <SpanStyled>Only 10 minutes away from being covered!</SpanStyled>
          </React.Fragment>
        ))}
        {guidance && (
          <p data-testid="mobileGuidanceBreakdownText" id="mobileBreakdownText">{description}</p>
        )}
      </PricingBody>
      <PricingFooter>
        {(!error && (
          <React.Fragment>
            <Button
              id="iq_continue"
              href={url}
              as="a"
              kind="primary"
              onClick={showInterstitialModal}
            >
              Continue
            </Button>
            <ParagraphStyled>{`Quote valid until: ${expiry}`}</ParagraphStyled>
          </React.Fragment>
        ))}
      </PricingFooter>
      {(!error && (
        <MobileContinueWrapper>
          <Button
            id="iq_continue_mobile"
            href={url}
            as="a"
            style={{ margin: '20px 0', width: '15rem' }}
            kind="primary"
            onClick={showInterstitialModal}
          >
            Continue
          </Button>
        </MobileContinueWrapper>
      ))}
    </PricingCardWrapper>
  )
}

export default React.memo(PricingCard)
