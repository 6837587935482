import alignment from 'themes/tokens/alignment'
import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'

export interface iconWithLabelTypes {
  color?: string
  fontSize?: string
  textAlign?: string
  fontWeight?: number
  fontSizeSmallDevice?: string
}

const iconWithLabel: iconWithLabelTypes = {
  color: colors.amazonite,
  fontSize: typography.fontSizes.fs24,
  textAlign: alignment.text.center,
  fontWeight: typography.fontWeight.bold,
  fontSizeSmallDevice: typography.fontSizes.fs20,
}

export default iconWithLabel
