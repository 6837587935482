import styled, { css } from 'styled-components'
import Heading from 'components/Atoms/Heading'
import { componentTypes } from 'themes/themeTypes'

const containerStyle = (pos: string, theme: componentTypes) => {
  const { components: { helpText: { container: { position } } } } = theme

  if (pos === 'right') {
    return css`
      max-width: ${position.right.maxWidth};
      width: ${position.right.width};
      position: absolute;
      left: ${position.right.left};
      top: ${position.right.top};
    `
  }
  return ''
}

const trianglePosition = (pos: string, boxColor: string | undefined, toLeftPx: string | undefined, kind: string | undefined, theme: componentTypes) => {
  const { components: { helpText: { trianglePos } } } = theme
  if (pos === 'right') {
    return css`
      top: 44%;
      left: -8px;
      border-left: ${trianglePos.right.borderLeft};
      border-bottom: ${trianglePos.right.borderBottom};
    `
  }
  if (pos === 'bottom') {
    return css`
      top: -7px;
      left: 50%;
      border-top: ${trianglePos.bottom.borderTop};
      border-left: ${trianglePos.right.borderLeft};
    `
  }
  if (pos === 'top-left') {
    if (kind === 'basic') {
      return css`
        top: 0;
        left: ${toLeftPx ? `${toLeftPx}px` : '23.8%'};
        margin: -17px 0 0 -17px;
        border-width: ${trianglePos.topLeft.basic.borderWidth};
        border-color: transparent  transparent  ${boxColor || trianglePos.topLeft.basic.borderColor} transparent;
      `
    }
    return css`
      top: 0;
      left: ${toLeftPx ? `${toLeftPx}px` : '23.8%'};
      margin: -17px 0 0 -17px;
    `
  }
  return `
    margin: 0;
    border-width: 0;
    border-color: transparent;
  `
}

type ContainerProps = {
  position: string
  boxColor?: string,
  borderRadius?: string,
  css?: string,
  toLeftPx?: string,
  kind?: string,
}

const Container = styled.div<ContainerProps>`
  ${({ kind, boxColor, theme: { components: { helpText } } }) => (
    kind === 'basic' ? css`
      background-color: ${boxColor || helpText.container.basic.backgroundColor};
      &:before {
        border-style: ${helpText.container.basic.borderStyle};
      }
    ` : css`
      background-color: ${boxColor || helpText.container.default.backgroundColor};
      box-shadow: ${helpText.container.default.boxShadow};
      border: ${helpText.container.default.border};
      &:before {
        background: ${helpText.container.default._before.background};
        transform: matrix(0.71, 0.68, -0.74, 0.71, 0, 0);
        width: ${helpText.container.default._before.width};
        height: ${helpText.container.default._before.height};
      }
    `
  )}

  position: relative;

  ${({ borderRadius, theme: { components: { helpText } } }) => css`
    padding: ${helpText.container.padding};
    border-radius: ${borderRadius || helpText.container.borderRadius};
    font-size: ${helpText.container.fontSize};
    margin-top: 20px;
  `}

  ${props => containerStyle(props.position, props.theme)}

  &:before {
    content: "";
    position: absolute;
    ${props => trianglePosition(props.position, props.boxColor, props.toLeftPx, props.kind, props.theme)}
  }

  p {
    margin: 5px 0;
    font-size: 16px;
  }
  ${props => props.css}
`

interface HeadingStyledProps {
  heading?: string
}

const HeadingStyled = styled(Heading) <HeadingStyledProps>`
  ${({ theme: { components: { helpText: { headings } } } }) => css`
    color: ${headings.color};
    font-size: ${headings.fontSize};
    font-weight: ${headings.fontWeight};
    line-height: ${headings.lineHeight};
  `}
  margin-top: 3px;
  margin-bottom: 18px;

  ${props => props.css}
`

const HeadingH3 = styled(Heading)`
  ${({ theme: { components: { helpText: { headings } } } }) => css`
    color: ${headings.color};
    font-size: ${headings.fontSize};
    font-weight: ${headings.fontWeight};
    line-height: ${headings.lineHeight};
  `}
  margin-top: 3px;
  margin-bottom: 10px;

  ${props => props.css}
`

interface SpanProps {
  css?: string,
  separator?: boolean | string,
}

const Span = styled.span<SpanProps>`
  ${({ separator, theme: { components: { helpText } } }) => css`
    color: ${helpText.span.color};
    margin-left: 10px;
    ${separator ? `
      margin-left: 0;
      &:before {
        content: " - ";
        color: ${helpText.span._before.color};
      }
    ` : `
      margin-left: 10px;
    `}
  `}

  ${props => props.css}
`

export {
  Container,
  HeadingH3,
  HeadingStyled,
  Span,
}
