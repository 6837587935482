import styled, { css } from 'styled-components'

type StyleProps = {
  font?: string,
  /**
   * @default 16px
   */
  colour?: string,
  align?: string,
  css?: string,
}

const ParagraphElement = styled.p<StyleProps>`
  ${({
    font, colour, align, theme: { components: { paragraph } },
  }) => css`
    font-family: ${paragraph.fontFamily};
    font-size: ${font || paragraph.fontSize};
    color: ${colour || paragraph.color};
    text-align: ${align || paragraph.textAlign};
  `}

  a {
    ${({ theme: { components: { paragraph } } }) => css`
      text-decoration: ${paragraph._link.textDecoration};
      color: ${paragraph._link.color};
    `}
  }

  ${props => props.css}
`

export default ParagraphElement
