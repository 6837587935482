import React from 'react'
import { isArray } from 'lodash'
import { IconList, IconObjects } from 'common/consts'
import * as Svg from './svgLoader'

export type IProps = {
  kind: string,
  size?: number | string | string[] | number[],
  id?: string,
  style?: object,
  color?: string
}

const IconSelector = (i: string) => {
  if (IconList.includes(i)) {
    return Svg[IconObjects.filter(o => o.value === i)[0].key]()
  }
  return (<img alt="missing Icon" />)
}

export const Icon = ({
  kind,
  size = 20,
  id,
  style,
  color,
}: IProps) => React.cloneElement(
  IconSelector(kind),
  {
    width: isArray(size) ? size[0] : size,
    height: isArray(size) ? size[1] : size,
    style: { ...style, fill: color },
    id,
  },
)
