import styled, { css } from 'styled-components'
import { query } from 'themes'
import { componentTypes } from 'themes/themeTypes'

type InputHiddenProps = {
  type: string,
  name: string,
  value: string,
  checked: boolean,
  onChange: () => void,
  id: string,
  kind: string,
}

type LabelProps = {
  kind: string,
}

const InputHidden = styled.input <InputHiddenProps>`
  position: absolute;
  left: -9999px;
`
const labelThemeSelector = (kind: string, theme: componentTypes) => {
  const { components: { checkboxSelector: { labelKinds: { primary } } } } = theme
  switch (kind) {
    case 'primary':
      return css`
        font-weight: ${primary.fontWeight};
        border-radius: ${primary.borderRadius};
        background: ${primary.background};
        border: ${primary.border};
      `
    case 'ghost':
    default:
      return ''
  }
}

const IconHoverThemeSelector = (kind: string, theme: componentTypes) => {
  const { components: { checkboxSelector: { iconKind: { primary, ghost } } } } = theme

  switch (kind) {
    case 'primary':
      return css`
        background: ${primary.background};
        border-color: ${primary.borderColor};
        fill: ${primary.fill};
      `
    case 'ghost':
    default:
      return css`
        fill: ${ghost.fill};
      `
  }
}

const LabelBox = styled.label <LabelProps>`
  display: block;
  cursor: pointer;

  ${({ theme: { components: { checkboxSelector: { labelBox } } } }) => css`
    color: ${labelBox.color};
    padding: ${labelBox.padding};
    line-height: ${labelBox.lineHeight};
    font-size: ${labelBox.fontSize};
  `}

  ${query.combine(['extraSmall', 'small', 'medium'], css`
    font-size: ${({ theme }) => theme.components.checkboxSelector.labelBox.smallDeviceFontSize};
  `)}
  &::selection {
    background: transparent;
  }
  ${props => labelThemeSelector(props.kind, props.theme)}
`

const StyledInputHidden = styled(InputHidden)`
  &:checked ~ label {
    ${({ kind, theme: { components: { checkboxSelector: { styledInputHidden } } } }) => kind !== 'ghost' && css`
      color: ${styledInputHidden.checkedLabel.color};
      background: ${styledInputHidden.checkedLabel.background};
    `}
  }
  &:hover ~ label svg {
    ${({ kind, theme: { components: { checkboxSelector: { styledInputHidden } } } }) => kind !== 'circle' && css`
      fill: ${styledInputHidden.hoverLabel.fill};
      fill-opacity: ${styledInputHidden.hoverLabel.fillOpacity};
    `}
  }
  &:focus ~ label svg {
    ${({ kind, theme: { components: { checkboxSelector: { styledInputHidden } } } }) => kind !== 'circle' && css`
      fill: ${styledInputHidden.focusLabel.fill};
      fill-opacity: ${styledInputHidden.focusLabel.fillOpacity};
    `}
  }
  &:checked ~ label svg {
    fill-opacity: 1;
    ${({ kind, theme: { components: { checkboxSelector: { styledInputHidden } } } }) => kind !== 'circle' && css`
      fill-opacity: ${styledInputHidden.checkedSvg.fillOpacity};
    `}
    ${props => IconHoverThemeSelector(props.kind, props.theme)}
  }
`

const Text = styled.div`
  display: flex;
  word-wrap: break-word;

  // manual device width to fix text dropping
  @media only screen
    and (min-width: 468px)
    and (max-width: 767px){
      line-height: unset;
  }

`

const CheckboxWrapper = styled.div`

  ${({ theme: { components: { checkboxSelector: { checkbox } } } }) => css`
    padding: ${checkbox.padding};
    font-size: ${checkbox.fontSize};
    color: ${checkbox.color};
    font-family: ${checkbox.fontFamily};
    padding: ${checkbox.padding};
    padding: ${checkbox.padding};
    padding: ${checkbox.padding};
  `}

  white-space: pre-line;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;

  > span {
    flex: 1 0 100%;
    &:first-child {
      font-size: ${({ theme }) => theme.components.checkboxSelector.checkbox.heading.fontSize};
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      margin-bottom: 20px;
    }
  }
  #marketingOptions {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: auto;

    ${query.combine(['small'], `
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
    `)}
    ${query.combine(['medium'], `
      width: 100%;
    `)}

    > div {
      width: 20%;
      margin-right: 10px;

      ${query.combine(['large'], `
        width: 30%;
      `)}
      ${query.combine(['small'], `
        width: 48%;
        margin-right: 0;
        margin-bottom: 12px;
      `)}

      &:last-child {
        margin-right: 0;
      }

      input {
        &:checked {
          ~ label {
            -webkit-tap-highlight-color: transparent;
            svg {
              ${({ theme: { components: { checkboxSelector: { checkbox: { marketingOptions } } } } }) => css`
                fill: ${marketingOptions._input.fill};
                background: ${marketingOptions._input.background};
                border-color: ${marketingOptions._input.borderColor};
              `}
              padding: 3px;
            }
          }
        }
      }

      label {

        ${query.combine(['small'], `
          justify-content: center!important;
          padding-top: 10px;
          padding-bottom: 10px;
        `)}

        font-size: 14px;
        &:last-child {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

      }
    }
  }
  svg {
    fill: transparent;
    border-radius: 5px;
    margin-right: 10px;
    ${query.extraSmall`
      margin-right: 5px;
    `}
    ${({ theme: { components: { checkboxSelector: { checkbox } } } }) => css`
      padding: ${checkbox.svg.padding};
      border: ${checkbox.svg.border};
    `}

    float: left;
  }
`

const Container = styled.div`
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: auto;

    ${query.combine(['small'], `
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
    `)}
    ${query.combine(['medium'], `
      width: 100%;
    `)}

    > div {
      width: 20%;
      margin-right: 10px;

      ${query.combine(['large'], `
        width: 30%;
      `)}
      ${query.combine(['small'], `
        width: 48%;
        margin-right: 0;
        margin-bottom: 12px;
      `)}

      &:last-child {
        margin-right: 0;
      }

      input {
        &:checked {
          ~ label {
            -webkit-tap-highlight-color: transparent;
            svg {
              ${({ theme: { components: { checkboxSelector: { container: { _input } } } } }) => css`
                fill: ${_input.fill};
                background: ${_input.background};
                border-color: ${_input.borderColor};
              `}
              padding: 3px;
            }
          }
        }
      }

      label {

        ${query.combine(['small'], `
          justify-content: center!important;
          padding-top: 10px;
          padding-bottom: 10px;
        `)}

        ${({ theme: { components: { checkboxSelector: { container: { _label } } } } }) => css`
          font-size: ${_label.fontSize}
        `};
        &:last-child {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

      }
    }
`

export {
  LabelBox,
  StyledInputHidden as InputHidden,
  Text,
  CheckboxWrapper,
  Container,
}
