import React, { useState, useEffect } from 'react'
import Heading from 'components/Atoms/Heading'
import IconToggle from 'components/Molecules/IconToggle'
import Tooltip from 'components/Molecules/Tooltip'
import { IoIosArrowDown } from 'react-icons/io'

import {
  CollapseComponent, ToggleArea, Toggle, Label, Checkbox, Value, DivStyled, BasicHeadingWrapper,
} from './style'
import HeadingWithIcon from '../HeadingWithIcon'

/*
- pass state for text 'addCover' and when added, 'remove cover'
- add so only on ci and cci
- pass function onClick to open modal
*/

export type CollapseProps = {
  heading: string,
  headingText: string,
  dataUxId?: string,
  css?: string,
  children?: React.ReactNode,
  id: string,
  value?: string | SVGElement,
  headingChildren?: React.ReactNode,
  tooltip?: boolean,
  tooltipContent?: string,
  linkText?: boolean,
  tooltipKind?: string,
  showValidation?: boolean,
  validationText?: React.ReactNode,
  className?: string,
  onClick?: (arg0: boolean, e: React.ChangeEvent<HTMLInputElement>) => void,
  dlClick: (arg0: boolean) => void,
  dataId?: string,
  kind?: string,
  iconKind?: string,
  checked?: boolean,
  coverButton?: boolean,
  coverModalFn?: (arg0: string, e: React.MouseEvent<HTMLButtonElement>) => void,
  addedStatus?: boolean, // used to check product added/not added to decide the text between 'add cover' or 'remove'
  collapseProduct?: string // determine what the id of the button is (ciModal or cciModal) to determine which modals are selected within app.
  breakdownText?: boolean // prop to show breakdown of cover text
}

export const Collapse: React.FC<CollapseProps> = ({
  heading,
  headingText,
  dataUxId,
  css,
  children,
  id,
  value,
  headingChildren,
  tooltip,
  tooltipContent,
  linkText,
  tooltipKind = 'basic',
  showValidation,
  validationText,
  className,
  onClick,
  dataId,
  dlClick,
  kind = 'default',
  iconKind = '',
  checked = false,
  coverButton,
  coverModalFn,
  addedStatus,
  collapseProduct,
  breakdownText = true,
}) => {
  const [isChecked, setIsChecked] = useState(false)
  useEffect(() => {
    if (checked) setIsChecked(true)
  }, [checked])
  return (
    <CollapseComponent css={css} className={className} data-id={dataId} id={dataId}>
      {tooltip && (
        <IconToggle dlClick={dlClick} id={`${id}_tooltip`} css="position:absolute;">
          <Tooltip kind={tooltipKind} color="#28334A" align="left" css="margin-left: -10px; margin-top: 10px">{tooltipContent}</Tooltip>
        </IconToggle>
      )}
      <Checkbox
        type="checkbox"
        checked={isChecked}
        name={`${id}_check`}
        id={`${id}_check`}
        onChange={() => setIsChecked(!isChecked)}
        iconKind={iconKind}
      />
      <Label htmlFor={`${id}_check`} onClick={onClick ? (e: any) => onClick(isChecked, e) : undefined}>
        <Toggle tooltip={tooltip}>
          {kind === 'basic' && (
            <BasicHeadingWrapper>
              <HeadingWithIcon
                id={id}
                iconKind={iconKind}
              >
                {headingText}
              </HeadingWithIcon>
              <IoIosArrowDown />
            </BasicHeadingWrapper>
          )}
          {kind === 'default' && (
            <Heading
              heading={heading}
              id={dataUxId}
              align="left"
              css="font-weight:700;margin-bottom:0;"
            >
              {headingText}
              <IoIosArrowDown />
              {value && <Value>{value}</Value>}
            </Heading>
          )}
          {headingChildren}
          {linkText && (
            <DivStyled>
              {breakdownText && <span id="viewCoverBtn">{isChecked ? 'Hide breakdown' : 'View and edit cover'}</span>}
              {coverButton && (<button id={`addCover_${collapseProduct}`} type="button" onClick={coverModalFn && ((e: React.MouseEvent<HTMLButtonElement>) => coverModalFn('', e))}>{addedStatus ? 'Remove cover' : 'Add cover'}</button>)}
              {showValidation && !coverButton && validationText}
            </DivStyled>
          )}
        </Toggle>
      </Label>
      <ToggleArea className="collapseToggleArea">
        {showValidation && coverButton && validationText}
        {children}
      </ToggleArea>
    </CollapseComponent>
  )
}
