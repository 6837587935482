import React from 'react'
import Message from './style'

export type IProps = {
  message?: string,
  dataUx?: string,
  children?: React.ReactNode,
}

export const Error: React.FC<IProps> = ({
  message,
  dataUx,
  children,
}) =>
  (
    <Message id={dataUx ? `${dataUx}_error` : 'error'}>
      {message}
      {children}
    </Message>
  )
