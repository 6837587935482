import styled, { css } from 'styled-components'

type IconToggleProps = {
  css?: string,
}

const IconToggleElement = styled.div<IconToggleProps>`
  ${({ theme: { components: { iconToggle } } }) => css`
    font-size: ${iconToggle.iconToggleFontSize};
    font-family: ${iconToggle.iconToggleFontFamily || "'Montserrat', sans-serif"};
  `}
  ${props => props.css}
`

const Toggle = styled.a`
  display:block;
  svg {
    font-size: ${({ theme }) => theme.components.iconToggle.toggleFontSize};
    color: ${({ theme }) => theme.components.iconToggle.toggleColor};
  }
`

const ToggleArea = styled.div`
  text-align:left;
`

type LabelProps = {
  ref?: any,
}

const Label = styled.label<LabelProps>`
  cursor: pointer;

  a {
    display: inline-block;
  }
`

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  & ~div {
    display:none;
  }
  @media screen and (max-width: 991px) {
    &:checked{
      &~ div {
        display:block;
      }
    }
  }

  @media screen and (min-width: 992px) {
    &:hover{
      &~ div {
        display:block;
      }
    }
  }
`

export {
  IconToggleElement,
  ToggleArea,
  Toggle,
  Label,
  Checkbox,
}
