import styled, { css } from 'styled-components'
import { query } from 'themes'

type TextProps = {
  errored?: boolean,
}

const spanMobileText = css`
  @media (min-width: 768px){
    font-size: ${({ theme }) => theme.components.findAddress.spanMobileText.fontSize};
  }
`

const AddressContainer = styled.div`
  .disclaimerPara {
    margin: 50px 0 0 0;
      ${query.combine(['extraSmall', 'small'], `
        text-align: left;
      `)}
  }
  &:hover {
    .tooltip_container {
      div:last-child {
        @media only screen and (min-width: 991px) {
          display: block;
        }
      }
    }
  }
`

const Text = styled.span<TextProps>`
  pointer-events: none;
  position: absolute;
  font-size: ${({ theme }) => theme.components.findAddress.text.fontSize};
  left: 0;
  top: -5px;
  transition: top 250ms ease-out, font-size 250ms ease-out;
  ${spanMobileText}
`

const Label = styled.label`
  position: relative;
  span {
    white-space: nowrap;
  }
  &.alt {
    .textHolder {
      ${({ theme: { components: { findAddress: { label } } } }) => css`
        font-size: ${label.fontSize};
        color: ${label.color};
      `}
      ${query.combine(['extraSmall', 'small'], css`
        font-size: ${({ theme }) => theme.components.findAddress.label.fontSizeSmall};
      `)}
    }
    input {
      font-size: ${({ theme }) => theme.components.findAddress.label.inputFontSize};
    }
  }
`

export {
  Text,
  Label,
  AddressContainer,
}
