import React from 'react'
import PropTypes from 'prop-types'
import AnchorStyled from './style'

export interface AnchorProps extends React.ComponentPropsWithoutRef<'a'> {
  href: string
  target?: string
  rel?: string
  children: React.ReactNode
}

export const Anchor = React.forwardRef<
  HTMLAnchorElement, AnchorProps
>(({
  children, href, target = '_blank', rel = 'noopener noreferrer', ...props
}, ref) => (
  <AnchorStyled
    href={href}
    target={target}
    rel={rel}
    ref={ref}
    {...props}
  >
    {children}
  </AnchorStyled>
))

Anchor.displayName = 'Anchor'

Anchor.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  children: PropTypes.node.isRequired,
}
