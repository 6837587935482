import styled, { css } from 'styled-components'
import { query } from 'themes'

const ConfirmAddressContainer = styled.div`
  h1 {
    width: 100%;
    line-height: ${({ theme }) => theme.components.confirmAddress.heading.lineHeight};
    ${query.combine(['extraSmall', 'small'], css`
      font-size: ${({ theme }) => theme.components.confirmAddress.heading.fontSizeSmall};
      margin-bottom: 22px;
    `)}
  }

  #confirmAddressText {
    ${({ theme: { components: { confirmAddress: { addressText } } } }) => css`
      border-bottom: ${addressText.borderBottom};
      padding: ${addressText.padding};
      font-size: ${addressText.fontSize};
    `}
    margin-bottom: 30px;
    display: inline-block;
  }
`

export default ConfirmAddressContainer
