import radius from 'themes/tokens/radius'
import typography from '../../../themes/tokens/typography'
import colors from '../../../themes/tokens/colors'
import alignment from '../../../themes/tokens/alignment'
import borders from '../../../themes/tokens/borders'

export interface buttonTypes {
  variant: {
    primary: {
      borderBottom?: string
      background?: string
      color?: string
      padding?: string
      borderRadius?: string
      fontSize?: string
      fontWeight?: number
      boxShadow?: string
      maxWidth?: string
    },
    secondaryGhost: {
      backgroundColor?: string
      border?: string
      borderRadius?: string
      color?: string
      fontSize?: string
      fontWeight?: number
      lineHeight?: string
      textAlign?: string
      padding?: string
      width?: string
    },
    secondary: {
      backgroundColor?: string
      border?: string
      borderRadius?: string
      color?: string
      fontSize?: string
      fontWeight?: number
      lineHeight?: string
      textAlign?: string
      padding?: string
      width?: string
      _disabled: {
        backgroundColor?: string
        border?: string
        color?: string
      },
    },
    underline: {
      color?: string
      borderColor?: string
      background?: string
      padding?: string
      fontSize?: string
    },
    ghost: {
      color?: string
      background?: string
      border?: string
      boxShadow?: string
      padding?: string
    },
    tabGhost: {
      color?: string
      background?: string
      border?: string
      boxShadow?: string
      padding?: string
      fontSize?: string
      width?: string
      _active: {
        background?: string
        color?: string
        borderColor?: string
        boxShadow?: string
      },
    },
    clear: {
      border?: string
      background?: string
      fontWeight?: number
      fontSize?: string
      _active: {
        opacity?: number
        transform?: string
      },
    },
  },
}

const button: buttonTypes = {
  variant: {
    primary: {
      borderBottom: borders.border2pxSolidFulvous,
      background: `linear-gradient(180deg, ${colors.purpleDark} 0%, ${colors.purpleLight} 100%)`,
      color: colors.white,
      padding: '10px 0',
      borderRadius: radius.r30,
      fontSize: typography.fontSizes.fs18,
      fontWeight: typography.fontWeight.bold,
      boxShadow: `0 2px 14px 0 ${colors.columbiaBlue}`,
      maxWidth: '212px',
    },
    secondaryGhost: {
      backgroundColor: colors.white,
      border: borders.border1pxSolidJapaneseIndigo,
      borderRadius: radius.r5,
      color: colors.japaneseIndigo,
      fontSize: typography.fontSizes.fs18,
      fontWeight: typography.fontWeight.regular,
      lineHeight: typography.lineHeights.lh22,
      textAlign: alignment.text.center,
      padding: '10px 10px',
      width: 'auto',
    },
    secondary: {
      backgroundColor: colors.amazonite,
      border: borders.border1pxSolidAmazonite,
      borderRadius: radius.r5,
      color: colors.white,
      fontSize: typography.fontSizes.fs18,
      fontWeight: typography.fontWeight.regular,
      lineHeight: typography.lineHeights.lh22,
      textAlign: alignment.text.center,
      padding: '10px 10px',
      width: 'auto',
      _disabled: {
        backgroundColor: colors.amazonite,
        border: borders.border1pxSolidAmazonite,
        color: colors.white,
      },
    },
    underline: {
      color: colors.raisinBlack,
      borderColor: colors.raisinBlack,
      background: colors.white,
      padding: '10px 0',
      fontSize: typography.fontSizes.fs16,
    },
    ghost: {
      color: colors.raisinBlack,
      background: colors.white,
      border: borders.border1pxSolidPastelGrey,
      boxShadow: `0 1px 0 0 ${colors.pastelGrey}`,
      padding: '10px 0',
    },
    tabGhost: {
      color: colors.raisinBlack,
      background: colors.white,
      border: borders.border1pxSolidPastelGrey,
      boxShadow: `0 1px 0 0 ${colors.pastelGrey}`,
      padding: '10px 15px',
      fontSize: typography.fontSizes.fs14,
      width: '100%',
      _active: {
        background: colors.amazonite,
        color: colors.white,
        borderColor: colors.amazonite,
        boxShadow: `0 1px 0 0 ${colors.amazonite}`,
      },
    },
    clear: {
      border: borders.none,
      background: colors.transparent,
      fontWeight: typography.fontWeight.bold,
      fontSize: typography.fontSizes.fs16,
      _active: {
        opacity: 0.5,
        transform: 'none',
      },
    },
  },
}

export default button
