import styled, { css } from 'styled-components'
import { AnchorProps } from './Anchor'

const AnchorStyled = styled.a<AnchorProps>`
  ${({ theme: { components: { anchor } } }) => css`
    font-style: ${anchor.fontStyle};
    font-weight: ${anchor.fontWeight};
    font-size: ${anchor.fontSize};
    line-height: ${anchor.lineHeight};
    text-decoration-line: ${anchor.textDecorationLine};
    color: ${anchor.color}};
  `}
`

export default AnchorStyled
