import styled, { css } from 'styled-components'
import Button from 'components/Atoms/Button'
import typography from 'themes/tokens/typography'

const BreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  font-size: ${typography.fontSizes.fs18};
  margin-bottom: 50px;


  outline: {
    display: block;
    color: red;
  }

  h3, h4 {
    margin-bottom: 0;
  }
`

const BreakdownHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 15px;
  text-align: left;

  h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-weight: 700;
    font-size: 1.2rem;

    ${({ theme: { components: { inputBreakdown } } }) => css`
      color: ${inputBreakdown.typography.color}!important;
    `}
  }

  p {
    margin-bottom: 0;
    font-size: ${typography.fontSizes.fs16};

    ${({ theme: { components: { inputBreakdown } } }) => css`
      color: ${inputBreakdown.typography.guidanceTextColor};
    `}

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 767.5px) {
    h1, h2, h3, h4, h5, p {
      text-align: center;
    }
    padding-bottom: 5px;
  }

`

const BreakdownBody = styled.ol`
  width: 100%;
  padding: 0;
  margin-top: 0;
  font-size: ${typography.fontSizes.fs18};

  @media (max-width: 768px) {
    font-size: ${typography.fontSizes.fs17};
  }
`

const BreakdownFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  flex-direction: column;
`

const ModalButton = styled(Button)`
  width: auto;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  padding: 3px 0;
  font-size: ${typography.fontSizes.fs18};

  ${({ theme: { components: { inputBreakdown } } }) => css`
    color: ${inputBreakdown.typography.buttonColor};
  `}

  svg {
    margin-left: 5px;

    ${({ theme: { components: { inputBreakdown } } }) => css`
      fill: ${inputBreakdown.typography.iconColor};
    `}
  }

  @media (max-width: 768px) {
    font-size: ${typography.fontSizes.fs17};
  }

`

const Section = styled.li`
  display: inline-flex;
  align-items: center;
  margin: 8px 0;
  width: 100%;

  p {
    margin: 0;
  }

  button {
    margin: 2px 5px 0;
    background: none;
  }
`

const ParagraphStyled = styled.p`
  ${({ theme: { components: { inputBreakdown } } }) => css`
    color: ${inputBreakdown.typography.textColor};
  `}
`

const SpanStyled = styled.span`
  ${({ theme: { components: { inputBreakdown } } }) => css`
    color: ${inputBreakdown.typography.textColor};
  `}
`
export {
  BreakdownWrapper,
  BreakdownHeader,
  BreakdownBody,
  BreakdownFooter,
  ModalButton,
  Section,
  ParagraphStyled,
  SpanStyled,
}
