import React from 'react'
import Heading from 'components/Atoms/Heading'
import Icon from 'components/Atoms/LargeIcon'
import DivStyled from './style'

export interface HeadingWithIconProps {
  id: string
  iconKind: string
  iconSize?: number
  headingLevel?: string
  children: React.ReactNode
}

export const HeadingWithIcon: React.FC<HeadingWithIconProps> = ({
  id,
  iconKind,
  iconSize = 37,
  headingLevel = 'h2',
  children,
}) => (
  <DivStyled>
    <Icon
      id={`headingIcon_${id}`}
      kind={iconKind}
      size={iconSize}
      style={{ margin: 0, marginRight: '13px' }}
    />
    <Heading
      heading={headingLevel}
      id={`headingText_${id}`}
    >
      {children}
    </Heading>
  </DivStyled>
)
