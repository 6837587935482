import React from 'react'
import Label from 'components/Atoms/Label'
import {
  StyledInput, Text, InputWrapper, InputRightContainer,
} from './style'

export type IProps = {
  type: string,
  minLength?: number,
  maxLength?: number,
  id: string,
  hasErrored?: boolean,
  onChange: () => void,
  onBlur?: () => void,
  onKeyUp?: () => void,
  dataUxId?: string,
  pattern?: string,
  value?: string,
  labelText?: string,
  inputLabel: string,
  bottomBar?: boolean,
  guidance: boolean, // determines styles for either QQ or Guidance journey.
}

export const InputLabelRight: React.FC<IProps> = ({
  inputLabel,
  id,
  hasErrored,
  onChange,
  onBlur,
  dataUxId,
  pattern,
  value,
  labelText,
  bottomBar,
  guidance,
  type,
  minLength,
  maxLength,
  onKeyUp,
}) =>
  (
    <InputRightContainer>
      <Label
        htmlFor={id}
        id={`LabelRight_${id}`}
        text={labelText}
        dataUxId="InputRightLabel"
      />
      <InputWrapper guidance={guidance}>
        <div>
          <StyledInput
            guidance={guidance}
            type={type}
            name={id}
            minLength={minLength}
            maxLength={maxLength}
            id={id}
            hasErrored={hasErrored}
            dataUxId={`InputText_${dataUxId}`}
            pattern={pattern}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            bottomBar={bottomBar}
          />
        </div>
        {inputLabel && (
          <Text
            guidance={guidance}
          >
            {inputLabel}
          </Text>
        )}
      </InputWrapper>
    </InputRightContainer>
  )
