import borders from 'themes/tokens/borders'
import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'

export interface dropdownTypes {
    borderBottom?: string
    fontSize?: string
    color?: string
    padding?: string
    width?: string
    _option?: {
      fontSize?: string
    },
    pos?: {
      right?: string
      top?: string
    },
    fill?: string
}

const dropdown: dropdownTypes = {
  borderBottom: borders.border1pxSolidDarkCharcoal,
  fontSize: typography.fontSizes.fs26,
  color: colors.japaneseIndigo,
  padding: '5px 10px',
  width: '100%',
  _option: {
    fontSize: typography.fontSizes.fs20,
  },
  pos: {
    right: '10px',
    top: '17px',
  },
  fill: colors.amazonite,
}

export default dropdown
