import colors from 'themes/tokens/colors'
// import { TrustpilotRating } from './types'

export type TrustpilotRatingTokens = {
  textColor: string
  ratingTextColor: string
}

const trustpilotRating: TrustpilotRatingTokens = {
  textColor: colors.black,
  ratingTextColor: colors.amazonite,
}

export default trustpilotRating
