import borders from 'themes/tokens/borders'
import typography from 'themes/tokens/typography'

export interface confirmAddressTypes {
    heading?: {
      fontSizeSmall?: string
      lineHeight?: string
    },
    addressText?: {
      fontSize?: string
      borderBottom?: string
      padding?: string
    },
}

const confirmAddress: confirmAddressTypes = {
  heading: {
    fontSizeSmall: typography.fontSizes.fs22,
    lineHeight: typography.lineHeights.lh34,
  },
  addressText: {
    fontSize: typography.fontSizes.fs17,
    borderBottom: borders.border1pxSolidDarkCharcoal,
    padding: '10px 15px 15px 15px',
  },
}

export default confirmAddress
