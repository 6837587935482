import React from 'react'
import Input from 'components/Atoms/Input'
import Label from 'components/Atoms/Label'
import { Step, StepContainer, InputContainer } from './style'

export type IProps = {
  minLength: number,
  maxLength: number,
  onChange: () => void,
  onFocus?: () => void,
  onBlur?: () => void,
  value?: number,
  id: string,
  labelText: string,
  onIncrement: () => void,
  onDecrement: () => void,
}

export const StepInput: React.FC<IProps> = ({
  minLength,
  maxLength,
  value,
  id,
  labelText,
  onFocus,
  onChange,
  onBlur,
  onIncrement,
  onDecrement,
}) => {
  let decrementColour
  let incrementColour

  if (value === 1) decrementColour = '#E9DCF1'
  else decrementColour = '#8038A9'

  if (value === 99) incrementColour = '#E9DCF1'
  else incrementColour = '#8038A9'

  return (
    <StepContainer>
      <Label
        htmlFor={`Input_${id}`}
        text={labelText}
        dataUxId="StepLabel"
        css="margin-bottom: 0px;"
      />
      <InputContainer>
        <Step data-testid="decrement" className="steps" id="decrement" onClick={onDecrement}>
          <svg width="22" height="20" viewBox="0 0 22 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 1H1" stroke={decrementColour} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Step>
        <Step data-testid="increment" className="steps" id="increment" onClick={onIncrement}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1V21" stroke={incrementColour} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 11H1" stroke={incrementColour} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Step>
        <Input
          type="text"
          id={`Input_${id}`}
          bottomBar
          minLength={minLength}
          maxLength={maxLength}
          css="margin: 0!important; text-align: center; min-width: 30%;"
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          aria-label={labelText || undefined}
          name={id}
        />
      </InputContainer>
    </StepContainer>
  )
}
