import styled, { css } from 'styled-components'
import Input from '../../../Atoms/Input'

type InputProps = {
  borderColor: string,
  textAlign: string,
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 15px 0px;
`

const StyledInput = styled(Input) <InputProps>`
  ${({ theme: { components: { inputBox } } }) => css`
    border-radius: ${inputBox.borderRadius};
    padding: ${inputBox.padding};
    font-size: ${inputBox.fontSize};
    color: ${inputBox.color};
  `}
  border: 1px solid ${props => props.borderColor};
  text-align: ${props => props.textAlign};
  margin: 0;
`

const PoundSign = styled.span`
  margin-right: 15px;
  font-size: ${({ theme }) => theme.components.inputBox.poundSign.fontSize};
  color: ${({ theme }) => theme.components.inputBox.poundSign.color};
`

export {
  Wrapper,
  StyledInput,
  PoundSign,
}
