import styled, { css } from 'styled-components'

type ContainerProps = {
  width: string,
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: ${props => props.width || '50%'};
`

const IconWrapper = styled.div`
  // make icon central
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextWrapper = styled.div`
  p {
    margin: 0;
    ${({ theme: { components: { iconWithText: { textWrapper } } } }) => css`
      line-height: ${textWrapper.lineHeight};
      font-size: ${textWrapper.fontSize};
    `}
  }
`

export {
  Container,
  IconWrapper,
  TextWrapper,
}
