import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'
import radius from 'themes/tokens/radius'
import { PricingCardTokens } from './types'

const pricingCard: PricingCardTokens = {
  wrapperBoxShadow: `0 2px 14px 0 ${colors.columbiaBlue}`,
  backgroundColor: colors.white,
  borderRadius: radius.r5,
  headingColor: colors.japaneseIndigo,
  iqPriceColor: colors.japaneseIndigo,
  paragraphColor: colors.japaneseIndigo,
  spanColor: colors.japaneseIndigo,
  guidanceMobileTextColor: colors.japaneseIndigo,
  typography: {
    color: colors.japaneseIndigo,
    fontMobile: typography.fontSizes.fs14,
  },
}

export default pricingCard
