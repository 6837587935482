import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'
import radius from 'themes/tokens/radius'
import borders from 'themes/tokens/borders'

export interface tooltipTypes {
    tooltipLink?: {
      color?: string
      fontSize?: string
    },
    tooltipText?: {
      boxShadow?: string
      borderRadius?: string
      padding?: string
      border?: string
      fontSize?: string
      color?: string
      _before?: {
        background?: string
        borderLeft?: string
        borderBottom?: string
        borderBottomSmalllDevice?: string
        borderTop?: string
      },
    },
    basicTooltipWrapper?: {
      fontFamily?: string
      fontSize?: string
      padding?: string
      background?: string
      border?: string
      borderRadius?: string
      boxShadow?: string
      _before?: {
        border?: string
        borderColor?: string
        borderBottomColor?: string
        borderWidth?: string
      },
      _after?: {
        border?: string
        borderColor?: string
        borderBottomColor?: string
        borderWidth?: string
      },
    },
}

const tooltip: tooltipTypes = {
  tooltipLink: {
    color: colors.link,
    fontSize: typography.fontSizes.fs16,
  },
  tooltipText: {
    boxShadow: `0px 4px 10px ${colors.gainsboro}`,
    borderRadius: radius.r5,
    padding: '15px',
    border: borders.border1pxSolidGainsboro,
    fontSize: typography.fontSizes.fs14,
    color: colors.darkGrey,
    _before: {
      background: colors.white,
      borderLeft: borders.border1pxSolidGainsboro,
      borderBottom: borders.border1pxSolidGainsboro,
      borderBottomSmalllDevice: 'transparent',
      borderTop: borders.border1pxSolidGainsboro,
    },
  },
  basicTooltipWrapper: {
    fontFamily: typography.fontFamily,
    fontSize: typography.fontSizes.fs14,
    padding: '27px 9px',
    background: colors.white,
    border: borders.border1pxSolidSilverSandy,
    borderRadius: radius.r5,
    boxShadow: `0px 4px 10px ${colors.gainsboro}`,
    _before: {
      border: 'solid transparent',
      borderColor: colors.beauBlue0,
      borderBottomColor: colors.silverSand,
      borderWidth: '10px',
    },
    _after: {
      border: 'solid transparent',
      borderColor: colors.beauBlue0,
      borderBottomColor: colors.white,
      borderWidth: '9px',
    },
  },
}

export default tooltip
