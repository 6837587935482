import React from 'react'
import classNames from 'classnames'
import styles from './style.module.css'

export type RowProps = {
  children: React.ReactNode,
  className?: string,
}

export const Row: React.FC<RowProps> = ({
  children,
  className = '',
  ...props
}) =>
  (
    <div className={classNames(styles.row, className)} {...props}>{children}</div>
  )
