import React from 'react'
import { RadioItem } from './RadioItem'
import { Fieldset, Legend, Container } from './style'

type ItemProps = {
  title: string,
  value: string,
}

export type RadioSelectorProps = {
  items: any,
  dataUxId: string,
  group: string,
  displayName: string,
  onChange: () => void,
  selected: string,
}

export const RadioSelector: React.FC<RadioSelectorProps> = ({
  items,
  dataUxId,
  group,
  displayName,
  onChange,
  selected,
}) => (
  <Fieldset id={`RadioSelector_${dataUxId}`}>
    <Legend>{displayName}</Legend>
    <Container>
      {items && items.map((i: ItemProps) => (
        <RadioItem
          title={i.title}
          value={i.value}
          group={group}
          onChange={onChange}
          key={i.value}
          checked={(i.value === selected)}
        />
      ))}
    </Container>
  </Fieldset>
)
