import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'

export interface headingWithSubheadingTypes {
  subheadingFontSize?: string
  subtitleColor?: string
  subtitleFontSize?: string
}

const headingWithSubheading: headingWithSubheadingTypes = {
  subheadingFontSize: typography.fontSizes.fs16,
  subtitleColor: colors.amazonite,
  subtitleFontSize: typography.fontSizes.fs28,
}

export default headingWithSubheading
