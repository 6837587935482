import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'

export interface inputBoxTypes {
  borderRadius?: string
  padding?: string
  fontSize?: string
  color?: string
  poundSign?: {
    fontSize?: string
    color?: string
  },
}

const inputBox: inputBoxTypes = {
  borderRadius: radius.r3,
  padding: '10px',
  fontSize: typography.fontSizes.fs16,
  color: colors.black,
  poundSign: {
    fontSize: typography.fontSizes.fs16,
    color: colors.japaneseIndigo,
  },
}

export default inputBox
