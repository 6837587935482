import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'
import alignment from 'themes/tokens/alignment'
import borders from 'themes/tokens/borders'

export interface collapseTypes {
  fontSize?: string
  fontFamily?: string
  _toggle?: {
    svgColor?: string
    button?: {
      fontSize?: string
      textDecoration?: string
      color?: string
      border?: string
    },
    span?: {
      fontSize?: string
      textDecoration?: string
      color?: string
      border?: string
    },
  },
  _toggleArea?: {
    textAlign?: string
  },
  _value?: {
    color?: string
  },
  _linkText?: {
    textDecoration?: string
    color?: string
  },
  _div?: {
    paragraphFontSize?: string
    lineHeight?: string
    color?: string
    fontWeight?: number
  },
}

const collapse: collapseTypes = {
  fontSize: typography.fontSizes.fs16,
  fontFamily: typography.fontFamily,
  _toggle: {
    svgColor: colors.purpureus,
    button: {
      fontSize: typography.fontSizes.fs16,
      textDecoration: typography.textDecoration.underline,
      color: colors.darkGrey,
      border: borders.none,
    },
    span: {
      fontSize: typography.fontSizes.fs16,
      textDecoration: typography.textDecoration.underline,
      color: colors.purpureus,
      border: borders.none,
    },
  },
  _toggleArea: {
    textAlign: alignment.text.left,
  },
  _value: {
    color: colors.purpureus,
  },
  _linkText: {
    textDecoration: typography.textDecoration.underline,
    color: colors.purpureus,
  },
  _div: {
    paragraphFontSize: typography.fontSizes.fs16,
    lineHeight: typography.lineHeights.lh24,
    color: colors.black,
    fontWeight: typography.fontWeight.regular,
  },
}

export default collapse
