import styled, { css } from 'styled-components'
// import { theme } from 'themes'
import { Col } from 'components/Atoms/Grid'
import { themeTypes } from 'themes/themeTypes'

const StyledColumn = styled(Col)`
  overflow: auto;
  opacity: 0;
  transition: opacity ease-in 250ms;
  height: initial;
  align-self: flex-start;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;

  -ms-justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;

  max-height: 80%;
  min-height: 80%;
  max-width: 40%;
  min-width: 40%;
  top:-500px;
  @supports (justify-content: center) {
    max-height: none;
    min-height: 0px;
    max-width: none;
    min-width: 0px;
  }

  &.entered {
    top:0;
    opacity: 1;
    transition: opacity ease-in 250ms;
    margin-bottom: auto;
    margin-top: auto;
  }
`

const ModalContainer = styled.div`
  max-width: 560px;
  padding: 60px;
  width: 100%;
  position: relative;
  ${({ theme: { components: { modal: { container } } } }: themeTypes) => css`
    background: ${container?.background};
    box-shadow: ${container?.boxShadow};
    border-radius: ${container?.borderRadius};
  `}
`

const Heading = styled.h4`
  margin-top: 0;
  margin-bottom: 20px;
  margin-top: 5px;
  ${({ theme: { components: { modal: { heading } } } }: themeTypes) => css`
    font-weight: ${heading?.fontWeight};
    font-size: ${heading?.fontSize};
    line-height: ${heading?.lineHeight};
    text-align: ${heading?.textAlign};
    color: ${heading?.color};
  `}
`

const CloseBtn = styled.button`
  ${({ theme: { components: { modal: { closeBtn } } } }: themeTypes) => css`
    background: ${closeBtn?.background};
    border-radius: ${closeBtn?.borderRadius};
  `}
  width: 40px;
  height: 40px;
  border: 0;
  position: absolute;
  top: 19px;
  right: 15px;
  display: flex;
  font-size: 0;
  cursor: pointer;
  svg {
    ${({ theme: { components: { modal: { closeBtn } } } }: themeTypes) => css`
      fill: ${closeBtn?.svgFill};
    `}
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }
`

const Content = styled.form`
  width: 100%;
  p {
    ${({ theme: { components: { modal: { content } } } }: themeTypes) => css`
      color: ${content?.textColor};
    `}
  }
`

export {
  ModalContainer,
  Heading,
  CloseBtn,
  Content,
  StyledColumn as Column,
}
