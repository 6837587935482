import styled, { css } from 'styled-components'
import { query } from 'themes'

type InputHiddenProps = {
  type: string,
  name: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  id: string,
  checked?: boolean,
}

const InputHidden = styled.input <InputHiddenProps>`
  position: absolute;
  opacity: 0;
`

const LabelActiveState = css`
  ${({ theme: { components: { formInput: { labelActiveState } } } }) => css`
    background: ${labelActiveState.background};
    color: ${labelActiveState.color};
    border-radius: ${labelActiveState.borderRadius};
    font-style: ${labelActiveState.fontStyle};
    font-weight: ${labelActiveState.fontWeight};
    font-size: ${labelActiveState.fontSize};
    line-height: ${labelActiveState.lineHeight};
    border-color: ${labelActiveState.borderColor};
  `}

  svg {
    fill: ${props => props.theme.components.formInput.labelActiveState.svgFill};;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    flex: 1 0 auto;
    margin: 5px;
    ${query.extraSmall`
      margin: 3px;
    `}
  }
`

const StyledInputHidden = styled(InputHidden)`
  &:checked + label {
    ${LabelActiveState}
  }
  &:focus + label {
    ${LabelActiveState}
  }

  &:checked + label svg {
    display: block;
    position: absolute;
    left: 15px;

    ${({ theme: { components: { formInput: { selectedIcon } } } }) => css`
      border: ${selectedIcon.border};
      border-radius: ${selectedIcon.borderRadius};
      padding: ${selectedIcon.padding};
      background: ${selectedIcon.background};
      fill: ${selectedIcon.fill};
    `}
  }
`

const Item = styled.label`
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  ${({ theme: { components: { formInput: { item } } } }) => css`
    line-height: ${item.lineHeight};
    text-align: ${item.textAlign};
    background: ${item.background};
    border: ${item.border};
    border-radius: ${item.borderRadius};
    font-style: ${item.fontStyle};
    font-weight: ${item.fontWeight};
    font-size: ${item.fontSize};
    color: ${item.color};
  `}

  margin-bottom: 6px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  ${query.combine(['extraSmall', 'small'], css`
    font-size: ${props => props.theme.components.formInput.item.fontSizeSmall};
  `)}

  &:hover {
    ${LabelActiveState}
    ${query.combine(['extraSmall', 'small'], css`
      background: ${props => props.theme.components.formInput.item._hover.backgroundSmall};
      color: ${props => props.theme.components.formInput.item._hover.colorSmall};
  `)}
  }

  svg {
    display: none;
  }
`

const Legend = styled.legend`
  margin: 0 0 5px;
  font-size: 20px;
`

const FieldsetStyled = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`

export {
  Container,
  Item,
  StyledInputHidden as InputHidden,
  Legend,
  FieldsetStyled,
}
