import React from 'react'
import { Col } from 'components/Atoms/Grid'
import Heading from 'components/Atoms/Heading'
import Button from 'components/Atoms/Button'
import ReplaceWithError from 'components/Molecules/FormComponents/ReplaceWithError'
import InputWithLabelTransition from 'components/Molecules/InputWithLabelTransition'
import Paragraph from 'components/Atoms/Paragraph'
import { uniqueIdentifier } from 'utils'
import { AddressContainer } from './style'

export type IProps = {
  onChange: () => void,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onSubmit: () => void,
  errorVisible: boolean,
  errorMsg: string,
  addressNo: string,
  postCode: string,
  headingLabel: string,
  addressDisclaimerText?: string,
  value: string,
  children?: string,
}

export const FindAddress: React.FC<IProps> = ({
  onChange,
  handleChange,
  onSubmit,
  errorVisible,
  errorMsg,
  addressNo,
  postCode,
  headingLabel,
  addressDisclaimerText,
  value,
  children,
}) =>
  (
    <AddressContainer id="findAddress">
      <div style={{ position: 'relative' }}>
        <Col xs="12">
          <Heading id="findAddress" css="width: 100%; text-align: center;">{headingLabel}</Heading>
        </Col>
        <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }} style={{ position: 'relative', paddingTop: '20px' }}>
          <InputWithLabelTransition
            id="addressNo"
            type="text"
            pattern=".*\S.*"
            ariaLabel="House number/name"
            defaultValue={addressNo}
            hasErrored={errorVisible}
            onChange={handleChange}
            dataUxId={uniqueIdentifier('houseNo')}
            bottomBar
            value={value}
            labelText="House number/name"
          />
        </Col>
        <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          <InputWithLabelTransition
            id="addressPostCode"
            type="text"
            pattern=".*\S.*"
            ariaLabel="Postcodee"
            defaultValue={postCode}
            hasErrored={errorVisible}
            onChange={handleChange}
            dataUxId={uniqueIdentifier('postcode')}
            bottomBar
            labelText="Postcode"
            value={value}
            maxLength={8}
          />
        </Col>
        <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          {children}
        </Col>
        <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          <ReplaceWithError
            forceModalSubmit={false}
            isErrorVisible={errorVisible}
            errorMessage={errorMsg}
            dataUxId={uniqueIdentifier('FindAddress')}
            render={(
              <Button data-testid="testError" dataUxId={uniqueIdentifier('FindAddress')} onClick={onSubmit} style={{ marginTop: 20 }} id="continue" kind="primary" width="212px">Find</Button>
          )}
          />
          <Button id="enterManualAddress" dataUxId={uniqueIdentifier('enterManualAddress')} onClick={onChange} kind="underline" style={{ marginTop: 30 }}>Enter manually</Button>
        </Col>
        <Col xs="12" md={{ span: 6, offset: 3 }}>
          <Paragraph className="disclaimerPara" id={`FooterInfo_${uniqueIdentifier('address')}`} align="center">
            {addressDisclaimerText}
          </Paragraph>
        </Col>

      </div>
    </AddressContainer>

  )
