import React from 'react'
import { Label, StyledInput, Red } from './style'

export type IProps = {
  labelPosition: string,
  labelText: string,
  id: string,
  hasErrored?: boolean,
  onChange: () => void,
  dataUxId?: string,
  pattern?: string,
  value?: string,
  validation?: boolean,
  maxLength?: number,
}

export const InputWithLabelAbove: React.FC<IProps> = ({
  labelPosition,
  labelText,
  id,
  hasErrored,
  onChange,
  dataUxId,
  pattern,
  value,
  validation,
  maxLength,
}) =>
  (
    <Label hasErrored={hasErrored} labelPosition={labelPosition} htmlFor={id}>
      {labelText && (<span>{labelText}{validation && (<Red>*</Red>)}</span>)}
      <div>
        <StyledInput
          type="text"
          id={id}
          hasErrored={hasErrored}
          dataUxId={`InputText_${dataUxId}`}
          pattern={pattern}
          labelPosition={labelPosition}
          value={value}
          onInput={onChange}
          onChange={onChange}
          maxLength={maxLength}
        />
      </div>
    </Label>
  )
