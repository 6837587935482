import styled, { css } from 'styled-components'
import Input from 'components/Atoms/Input'

type SProps = {
  hasErrored?: boolean,
  value?: string | string[],
  guidance: boolean,
}

type TProps = {
  labelFontSize?: string,
  guidance: boolean,
}

type WProps = {
  guidance: boolean,
}

const InputRightContainer = styled.div`
  position: relative;
  label {
    position: relative;
    outline: none;
    ${({ theme: { components: { inputLabelRight: { inputRightContainer } } } }) => css`
      font-size: ${inputRightContainer.fontSize};
      line-height: ${inputRightContainer.lineHeight};
      color: ${inputRightContainer.color};
    `}
  }
`

const InputWrapper = styled.div<WProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  ${({ guidance }) => guidance && `
      justify-content: flex-start;
    `}
  > div {
    width: 45%;
    position: relative;
    margin-right: 3%;
    ${({ guidance }) => guidance && `
      width: 140px;
      max-width: 140px;
    `}
    i {
      bottom: 0px;
    }
  }
`

const StyledInput = styled(Input) <SProps>`
  ${({ theme: { components: { inputLabelRight: { styledInput } } } }) => css`
    border-bottom: ${styledInput.borderBottom};
    font-size: ${styledInput.fontSize};
  `}
  padding-bottom: 5px;
  background: transparent;
  width: 100%;
  margin: 0;
  text-align: center;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  &:required, &:invalid {
    outline: none;
    box-shadow: none;
  }
  ${({ guidance }) => guidance && css`
      font-size: ${props => props.theme.components.inputLabelRight.styledInput._guidance.fontSize};
  `}
  &:valid + svg {
    background: ${props => props.theme.components.inputLabelRight.styledInput._validSvg.background};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Text = styled.p<TProps>`
  font-size: ${props => props.theme.components.inputLabelRight.text.fontSize};
  margin: 0;
  width: 45%;
  ${({ guidance }) => guidance && css`
      font-size: ${props => props.theme.components.inputLabelRight.text._guidance.fontSize};
  `}
`

export {
  StyledInput,
  InputRightContainer,
  Text,
  InputWrapper,
}
