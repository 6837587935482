import styled, { css } from 'styled-components'

type CollapseProps = {
  css?: string,
  tooltip?: boolean,
  onClick?: () => void,
}

const CollapseComponent = styled.div<CollapseProps>`
  ${({ theme: { components: { collapse } } }) => css`
    font-size: ${collapse.fontSize};
    font-family: ${collapse.fontFamily || "'Montserrat', sans-serif"};
  `}
  position:relative;
  ${props => props.css}
  margin-top: 0;
`

const Toggle = styled.a<CollapseProps>`
  display:block;
  margin-left:${props => props.tooltip ? '35px' : '0'};
  svg {
    float:right;
    font-size:1.7em;
    margin-top:-0.2em;
    ${({ theme: { components: { collapse: { _toggle } } } }) => css`
      color: ${_toggle.svgColor};
    `}
  }

  button {
    cursor: pointer;
    margin-top:10px;
    background: none;
    ${({ theme: { components: { collapse: { _toggle } } } }) => css`
      color: ${_toggle.button.color};
      font-size: ${_toggle.button.fontSize};
      text-decoration: ${_toggle.button.textDecoration};
      border: ${_toggle.button.border};
    `}
  }

  span#viewCoverBtn {
    cursor: pointer;
    margin-top:10px;
    background: none;
    ${({ theme: { components: { collapse: { _toggle } } } }) => css`
      color: ${_toggle.span.color};
      font-size: ${_toggle.span.fontSize};
      text-decoration: ${_toggle.span.textDecoration};
      border: ${_toggle.span.border};
    `}
  }
`

const ToggleArea = styled.div<CollapseProps>`
  ${({ theme: { components: { collapse: { _toggleArea } } } }) => css`
    text-align: ${_toggleArea.textAlign};
  `}
`

const Label = styled.label`
  cursor: pointer;
`

const Value = styled.span`
  ${({ theme: { components: { collapse: { _value } } } }) => css`
    color: ${_value.color};
  `}
  margin-left:1em;
  float:right;
`

const LinkText = styled.span`
  /* &:before{ */
    cursor: pointer;
    ${({ theme: { components: { collapse: { _linkText } } } }) => css`
      color: ${_linkText.color};
      text-decoration: ${_linkText.textDecoration};
    `}
    margin-top:10px;
    display:block;
  /* } */
`

type CheckboxProps = {
  iconKind?: string
}

const Checkbox = styled.input<CheckboxProps>`
  position: absolute;
  opacity: 0;
  z-index: -1;
  &~ div {
    overflow:hidden;
    max-height: 0;
    transition: all .35s;
  }
  &:checked{
    &~ div {
      max-height: 100vh;
      padding-bottom: 2px;
      @media screen and (max-width: 767px) {
        padding-bottom: 10px;
        max-height: none;
      }
      @media (orientation: landscape) {
        max-height: none;
      }
    }
    &+label{
      a {
        ul {
          height: 10px;
          > li {
            height: 10px;
          }
        }
      }
      ${props => props.iconKind !== '' ? `
        svg:last-child {
          transform: rotate(180deg);
        }
      ` : `
        svg {
          transform: rotate(180deg);
        }
      `}
      ${LinkText} {
        :before{
          content:"Hide breakdown of cover";
        }
      }
    }
  }

`

type propsDiv = {
  breakdownText?: boolean
}

const DivStyled = styled.div<propsDiv>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${props => !props.breakdownText && css`
    justify-content: flex-end;
  `}

  span {
    margin-right: auto;
  }

  p {
    margin-top: 10px;
    ${({ theme: { components: { collapse: { _div } } } }) => css`
      color: ${_div.color};
      font-size: ${_div.fontSize};
      line-height: ${_div.lineHeight};
      color: ${_div.color};
      font-weight: ${_div.fontWeight};
    `}
  }
`

const BasicHeadingWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: auto;
  }
`
export {
  CollapseComponent,
  ToggleArea,
  Toggle,
  Label,
  Checkbox,
  Value,
  LinkText,
  DivStyled,
  BasicHeadingWrapper,
}
