import React from 'react'
import Wrap from './style'

export interface WrapperProps {
  className?: string,
  children?: React.ReactNode,
}

export const Wrapper: React.FC<WrapperProps> = ({
  className,
  children,
}) =>
  (
    <Wrap
      className={className}
    >
      {children}
    </Wrap>
  )
