import styled, { css } from 'styled-components'

interface Props {
  css?: string,
}

const Label = styled.label<Props>`
  outline: none;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;

  ${({ theme: { components: { label } } }) => css`
    color: ${label.color};
    font-size: ${label.fontSize};
    text-align: ${label.textAlign};
    font-weight: ${label.fontWeight};
  `}

  ${props => props.css};
`

export default Label
