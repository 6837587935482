import React from 'react'
import { Col, Row } from '../../Atoms/Grid'
import PricingCard from '../../Molecules/PricingCard'
import InputBreakdown from '../../Molecules/InputBreakdown'
import { IndicativeQuoteProps } from './types'

const IndicativeQuote: React.FC<IndicativeQuoteProps> = ({
  heading,
  description,
  fields,
  handleClick,
  price,
  expiry,
  url,
  loading,
  children,
  guidance = false,
  showInterstitialModal,
  sessioncamHide,
  sessioncamClassList,
}) => (
  <Row>
    <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }}>
      <InputBreakdown
        heading={heading}
        description={description}
        fields={fields}
        handleClick={handleClick}
        guidance={guidance}
        sessioncamHide={sessioncamHide}
        sessioncamClassList={sessioncamClassList}
      >
        {children}
      </InputBreakdown>
    </Col>
    <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }} style={{ marginRight: '-15px', padding: '0' }}>
      <PricingCard
        price={price}
        expiry={expiry}
        url={url}
        loading={loading}
        guidance={guidance}
        description={description}
        showInterstitialModal={showInterstitialModal}
      />
    </Col>
  </Row>
)

export default IndicativeQuote
