import React from 'react'
import Label from 'components/Atoms/Label'
import LargeIcon from 'components/Atoms/LargeIcon'
import {
  PolarQuestionContainer,
  StyledParagraph,
  StyleSubParagraph,
  QuestionWrapper,
  InputHidden,
  IconWrapper,
  IconText,
} from './style'

export type Props = {
  id: string
  labelText: string
  subParagraph?: string,
  icons: string[]
  labels: string[]
  size: string[],
  mobileSize?: string[],
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  values: string,
  textAlign?: 'left' | 'center' | 'right',
  iconAlign?: 'flex-start' | 'center' | 'flex-end',
  marginBtm?: string,
  borderTop?: boolean,
}

export const PolarQuestion: React.FC<Props> = ({
  id,
  labelText,
  subParagraph,
  icons,
  size,
  labels,
  onChange,
  values,
  textAlign,
  iconAlign,
  mobileSize,
  marginBtm,
  borderTop,
}) =>
  (
    <PolarQuestionContainer id={`PolarQuestion_${id}`} marginBtm={marginBtm} borderTop={borderTop}>
      {labelText && (
        <StyledParagraph
          id={`PolarHeading_${id}`}
          align={textAlign}
        >
          {labelText}
        </StyledParagraph>
      )}
      {subParagraph && (
        <StyleSubParagraph
          id={`PolarSubParagraph_${id}`}
          align={textAlign}
        >
          {subParagraph}
        </StyleSubParagraph>
      )}
      <QuestionWrapper align={iconAlign}>
        {icons.map((i, index) => (
          <IconWrapper key={i} mobileSize={mobileSize}>
            <InputHidden
              type="radio"
              id={`Polar_${i}`}
              name={i}
              value={i}
              checked={i === values} // needs onChange event to change state when clicked to return true
              onChange={onChange}
            />
            <Label htmlFor={`Polar_${i}`} id={`Label_${i}`}>
              <LargeIcon id={`LargeIcon_${i}`} kind={i} size={size} />
            </Label>
            <IconText size={size} className="PolarQuestion_label">{labels[index]}</IconText>
          </IconWrapper>
        ))}
      </QuestionWrapper>
    </PolarQuestionContainer>
  )
