import alignment from 'themes/tokens/alignment'
import colors from 'themes/tokens/colors'
import radius from 'themes/tokens/radius'
import typography from 'themes/tokens/typography'

export interface inputGroupTypes {
  textAlign?: string
  paddingLeft?: string
  paddingRight?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
  colorError?: string
  container?: {
    background?: string
    border?: string
    borderError?: string
    borderRadius?: string
    _active?: {
      borderColor?: string
    },
  },
  _position: {
    borderColor?: string
    borderColorError?: string
  },
  input?: {
    fontSize?: string
    lineHeight?: string
    textAlign?: string
    color?: string
    fontWeight?: number
  },
}

const inputGroup: inputGroupTypes = {
  textAlign: alignment.text.center,
  paddingLeft: '10px',
  paddingRight: '10px',
  fontWeight: typography.fontWeight.bold,
  fontSize: typography.fontSizes.fs28,
  lineHeight: typography.lineHeights.lh33,
  color: colors.cloudBurst,
  colorError: colors.milanoRed,
  container: {
    background: 'rgba(210, 217, 228, 0.25)',
    border: `1px solid ${colors.heather}`,
    borderError: `1px solid ${colors.milanoRed}`,
    borderRadius: radius.r3,
    _active: {
      borderColor: colors.japaneseIndigo,
    },
  },
  _position: {
    borderColor: colors.heather,
    borderColorError: colors.milanoRed,
  },
  input: {
    fontSize: typography.fontSizes.fs29,
    lineHeight: typography.lineHeights.none,
    textAlign: alignment.text.center,
    color: colors.darkGrey,
    fontWeight: typography.fontWeight.regular,
  },
}

export default inputGroup
