// testing
import styled, { css } from 'styled-components'

const Wrap = styled.div`
  ${({ theme: { components: { wrapper } } }) => css`
    border-radius: ${wrapper.borderRadius};
    box-shadow: ${wrapper.boxShadow};
    padding: ${wrapper.padding};
  `}
`

export default Wrap
