import colors from 'themes/tokens/colors'

export interface largeIconTypes {
  primaryPathFill?: string
  lightPathFill?: string
  circleFill?: string
  svgFill?: string
  circleFillActive?: string
  primaryPathFillActive?: string
  growPathWideFillActive?: string
  circleFillFocusMobile?: string
  growPathWideHoverMobile?: string
  shadowFill?: string
  strokeColor?: string,
  _hover?: {
    fill?: string
    primaryFill?: string
  }
}

const largeIcon: largeIconTypes = {
  primaryPathFill: colors.amazonite,
  lightPathFill: colors.pearlAqua,
  circleFill: colors.iconLightGreen,
  svgFill: colors.pearlAqua,
  circleFillActive: colors.amazonite,
  primaryPathFillActive: colors.japaneseIndigo,
  growPathWideFillActive: colors.cadetGrey,
  circleFillFocusMobile: colors.amazonite,
  growPathWideHoverMobile: colors.cadetGrey,
  shadowFill: colors.amazonite,
  strokeColor: colors.japaneseIndigo,
  _hover: {
    fill: colors.japaneseIndigo,
    primaryFill: colors.amazonite,
  },
}

export default largeIcon
