import styled, { css } from 'styled-components'
import Input from 'components/Atoms/Input'

type SProps = {
  hasErrored?: boolean,
  value?: string | string[]
  css?: string,
  guidance?: boolean,
}

const InputPoundContainer = styled.div`
  display: block;
  position: relative;
  label {
    position: relative;
    outline: none;
    ${({ theme: { components: { inputPound: { label } } } }) => css`
      font-size: ${label.fontSize};
      line-height: ${label.lineHeight};
      color: ${label.color};
    `}
  }

  &.largePound {
    .poundSign {
      ${({ theme: { components: { inputPound: { largePound } } } }) => css`
        color: ${largePound.signColor};
        font-size: ${largePound.signFontSize};
      `}

      position: absolute;
      left: 0;
      bottom: 0;
    }
    input {
      ${({ theme: { components: { inputPound: { largePound } } } }) => css`
        font-size: ${largePound.inputFontSize};
        padding: ${largePound.inputPadding};
      `}
    }
  }
`

const InputWrapper = styled.div`
  position: relative;

  > span {
    position: absolute;
    left: 0;
    bottom: 6px;
    ${({ theme: { components: { inputPound: { regularPound } } } }) => css`
      color: ${regularPound.signColor};
      font-size: ${regularPound.signFontSize};
    `}
  }
`

const StyledInput = styled(Input) <SProps>`
  ${({ theme: { components: { inputPound: { input } } } }) => css`
    font-size: ${input.fontSize};
    padding: ${input.padding};
    border-bottom: ${input.borderBottom};
    width: ${input.width};
    text-align: ${input.textAlign};
    color: ${input.color};
  `}

  background: transparent;
  margin: 0;
  -moz-appearance:textfield;
  -webkit-appearance: none;
  &:required, &:invalid {
    outline: none;
    box-shadow: none;
  }
  &:valid + svg {
    background: ${props => props.theme.components.inputPound.input._valid.background};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export {
  InputPoundContainer,
  InputWrapper,
  StyledInput,
}
