import colors from 'themes/tokens/colors'
import typography from 'themes/tokens/typography'

export interface iconToggleTypes {
  iconToggleElementFontSize?: string
  iconToggleFontFamily?: string
  toggleFontSize?: string
  toggleColor?: string
}

const iconToggle = {
  iconToggleElementFontSize: typography.fontSizes.fs16,
  iconToggleFontFamily: typography.fontFamily,
  toggleFontSize: typography.fontSizes.fs26,
  toggleColor: colors.purpureus,
}

export default iconToggle
