import React, { useState } from 'react'
import { StyledLabel, Text, StyledInput } from './style'

export type IProps = {
  type: string,
  pattern: string,
  id: string,
  defaultValue?: string,
  hasErrored?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  dataUxId?: string,
  alt?: boolean,
  bottomBar?: boolean,
  ariaLabel?: string,
  labelText?: string,
  maxLength?: number,
  value: string,
  name?: string,
}

export const InputWithLabelTransition: React.FC<IProps> = ({
  type,
  pattern,
  id,
  defaultValue,
  hasErrored,
  onChange,
  dataUxId,
  bottomBar,
  ariaLabel,
  labelText,
  maxLength,
  value,
  name,
  onKeyDown,
  alt,
}) => {
  const [isDirty, setIsDirty] = useState(false)

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => setIsDirty(e.target.value.length > 0)

  return (
    <StyledLabel
      htmlFor={id}
      className={alt ? 'alt' : undefined}
    >
      <StyledInput
        type={type}
        pattern={pattern}
        aria-label={ariaLabel}
        id={id}
        defaultValue={defaultValue}
        hasErrored={hasErrored}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={(e) => handleFocus(e)}
        onInput={(e) => handleFocus(e)}
        dataUxId={dataUxId}
        bottomBar={bottomBar}
        maxLength={maxLength}
        value={value}
        name={name}
        isDirty={isDirty}
      />
      <Text
        className="textHolder"
        hasErrored={hasErrored}
      >
        {labelText}
      </Text>
    </StyledLabel>
  )
}
