import colors from '../../../themes/tokens/colors'

export interface overlayTypes {
  background?: string
}

const overlay: overlayTypes = {
  background: colors.black60,
}

export default overlay
