import styled, { css } from 'styled-components'

const Step = styled.div`
  ${({ theme: { components: { stepInput: { step } } } }) => css`
    font-size: ${step.fontSize};
    font-weight: ${step.fontWeight};
  `}
  position: absolute;
  &#decrement {
    background-image: url('../../../../../common/minus.svg');
    left: 0;
  }
  &#increment {
    background-image: url('../../../../../common/plus.svg');
    right: 0;
  }
  &#increment,
  &#decrement {
    position: absolute;
    width: auto;
    bottom: -5px;
    cursor: pointer;
  }

  // make clickable 45px on mobile
  @media only screen and (max-width: 450px) {
    &#increment,
    &#decrement {
      min-width: 45px;
    }
    &#increment {
      text-align: right;
    }
  }
`

const StepContainer = styled.div`
  position: relative;
  label {
    position: relative;
    outline: none;
    ${({ theme: { components: { stepInput: { stepContainer } } } }) => css`
      font-size: ${stepContainer.fontSize};
      line-height: ${stepContainer.lineHeight};
      color: ${stepContainer.color};
    `}
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 140px;
  min-width: 140px;
  margin-top: 25px;
  input {
    padding-bottom: 5px;
  }
  i {
    bottom: 0;
  }
`

export {
  Step,
  StepContainer,
  InputContainer,
}
