const IconList = [
  'arrow-r',
  'check',
  'chevron-u',
  'clock',
  'cutlery',
  'home',
  'lifestyle',
  'partner',
  'plus',
  'loader',
  'refresh',
  'sort-desc',
  'close',
  'close-modal',
  'user',
  'users',
  'arrow-round-forward',
  'timer',
  'policytick',
  'popular',
  'calculator',
  'you-and-your-family',
  'your-finances',
  'phone',
  'paper',
]

const IconObjects = [
  { key: 'ArrowR', value: 'arrow-r' },
  { key: 'Check', value: 'check' },
  { key: 'ChevronUp', value: 'chevron-u' },
  { key: 'Clock', value: 'clock' },
  { key: 'Cutlery', value: 'cutlery' },
  { key: 'Home', value: 'home' },
  { key: 'Lifestyle', value: 'lifestyle' },
  { key: 'Partner', value: 'partner' },
  { key: 'Plus', value: 'plus' },
  { key: 'Refresh', value: 'refresh' },
  { key: 'Loader', value: 'loader' },
  { key: 'Plus', value: 'plus' },
  { key: 'SortDesc', value: 'sort-desc' },
  { key: 'Close', value: 'close' },
  { key: 'CloseModal', value: 'close-modal' },
  { key: 'User', value: 'user' },
  { key: 'Users', value: 'users' },
  { key: 'ArrowRoundForward', value: 'arrow-round-forward' },
  { key: 'Timer', value: 'timer' },
  { key: 'PolicyTick', value: 'policytick' },
  { key: 'ThumbsUp', value: 'popular' },
  { key: 'QuestionMark', value: 'calculator' },
  { key: 'YouAndYourFamily', value: 'you-and-your-family' },
  { key: 'YourFinances', value: 'your-finances' },
  { key: 'Phone', value: 'phone' },
  { key: 'Paper', value: 'paper' },
]

const LargeIconList = [
  'ci',
  'noci',
  'nocci',
  'cci',
  'mortgage',
  'has-mortgage',
  'no-mortgage',
  'children',
  'debts',
  'partner',
  'other',
  'not_sure',
  'repayment',
  'interest',
  'debtYes',
  'debtNo',
  'decreasingff',
  'increasingff',
  'popular',
  'calculator',
  'decreasing',
  'level',
  'childrenff',
  'nochildrenff',
  'smoker',
  'non-smoker',
  'single',
  'joint',
  'male',
  'female',
  'you-and-your-family',
  'your-finances',
  'podie',
  'poci',
  'trust-pilot',
  'will-writing',
  'paid-out',
  'hand',
  'early-pay-out',
  'info-icon',
  'calendar',
]

const LargeIconObjects = [
  { key: 'CI', value: 'ci' },
  { key: 'NoCI', value: 'noci' },
  { key: 'NoCCI', value: 'nocci' },
  { key: 'CCI', value: 'cci' },
  { key: 'Mortgage', value: 'mortgage' },
  { key: 'Children', value: 'children' },
  { key: 'Debts', value: 'debts' },
  { key: 'Partner', value: 'partner' },
  { key: 'Other', value: 'other' },
  { key: 'NotSure', value: 'not_sure' },
  { key: 'HasMortgage', value: 'has-mortgage' },
  { key: 'NoMortgage', value: 'no-mortgage' },
  { key: 'Repayment', value: 'repayment' },
  { key: 'Interest', value: 'interest' },
  { key: 'DebtYes', value: 'debtYes' },
  { key: 'DebtNo', value: 'debtNo' },
  { key: 'DecreasingFF', value: 'decreasingff' },
  { key: 'IncreasingFF', value: 'increasingff' },
  { key: 'ThumbsUp', value: 'popular' },
  { key: 'QuestionMark', value: 'calculator' },
  { key: 'Decreasing', value: 'decreasing' },
  { key: 'Level', value: 'level' },
  { key: 'ChildrenFF', value: 'childrenff' },
  { key: 'NoChildrenFF', value: 'nochildrenff' },
  { key: 'Smoker', value: 'smoker' },
  { key: 'NonSmoker', value: 'non-smoker' },
  { key: 'Single', value: 'single' },
  { key: 'Joint', value: 'joint' },
  { key: 'Male', value: 'male' },
  { key: 'Female', value: 'female' },
  { key: 'YouAndYourFamily', value: 'you-and-your-family' },
  { key: 'YourFinances', value: 'your-finances' },
  { key: 'PoDie', value: 'podie' },
  { key: 'PoCi', value: 'poci' },
  { key: 'TrustPilotRating', value: 'trust-pilot' },
  { key: 'WillWriting', value: 'will-writing' },
  { key: 'PaidOut', value: 'paid-out' },
  { key: 'Hand', value: 'hand' },
  { key: 'EarlyPayOut', value: 'early-pay-out' },
  { key: 'InfoIcon', value: 'info-icon' },
  { key: 'Calendar', value: 'calendar' },
]

export {
  LargeIconList,
  LargeIconObjects,
  IconList,
  IconObjects,
}
