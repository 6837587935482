import React from 'react'

import {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  HeadingFour,
  HeadingFive,
  HeadingSix,
} from './style'

export interface HeadingProps {
  className?: string,
  id?: string,
  children: React.ReactNode,
  fonts?: string,
  heading?: string,
  align?: 'left' | 'center' | 'right',
  colour?: string,
  css?: string,
  margin?: string,
  weight?: number,
  lineHeight?: string,
}

export const Heading: React.FC<HeadingProps> = ({
  className,
  id,
  fonts,
  heading,
  align,
  colour,
  children,
  css,
  margin,
  weight,
  lineHeight,
}) => {
  switch (heading) {
    case 'h1':
      return (
        <HeadingOne
          className={className}
          id={`Heading_${id}`}
          fonts={fonts}
          align={align}
          colour={colour}
          css={css}
          margin={margin}
          weight={weight}
          lineHeight={lineHeight}
        >
          {children}
        </HeadingOne>
      )
    case 'h2':
      return (
        <HeadingTwo
          className={className}
          id={`Heading_${id}`}
          fonts={fonts}
          align={align}
          colour={colour}
          css={css}
          margin={margin}
          weight={weight}
          lineHeight={lineHeight}
        >
          {children}
        </HeadingTwo>
      )
    case 'h3':
      return (
        <HeadingThree
          className={className}
          id={`Heading_${id}`}
          fonts={fonts}
          align={align}
          colour={colour}
          css={css}
          margin={margin}
          weight={weight}
          lineHeight={lineHeight}
        >
          {children}
        </HeadingThree>
      )
    case 'h4':
      return (
        <HeadingFour
          className={className}
          id={`Heading_${id}`}
          fonts={fonts}
          align={align}
          colour={colour}
          css={css}
          margin={margin}
          weight={weight}
          lineHeight={lineHeight}
        >
          {children}
        </HeadingFour>
      )
    case 'h5':
      return (
        <HeadingFive
          className={className}
          id={`Heading_${id}`}
          fonts={fonts}
          align={align}
          colour={colour}
          css={css}
          margin={margin}
          weight={weight}
          lineHeight={lineHeight}
        >
          {children}
        </HeadingFive>
      )
    case 'h6':
      return (
        <HeadingSix
          className={className}
          id={`Heading_${id}`}
          fonts={fonts}
          align={align}
          colour={colour}
          css={css}
          margin={margin}
          weight={weight}
          lineHeight={lineHeight}
        >
          {children}
        </HeadingSix>
      )
    default:
      return (
        <HeadingOne
          className={className}
          id={`Heading_${id}`}
          fonts={fonts}
          align={align}
          colour={colour}
          css={css}
          margin={margin}
          weight={weight}
          lineHeight={lineHeight}
        >
          {children}
        </HeadingOne>
      )
  }
}
