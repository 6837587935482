import deepmerge from 'deepmerge'

// Atoms
import trustpilotRating from 'components/Molecules/TrustpilotRating/tokens'
import anchor from '../components/Atoms/Anchor/tokens'
import button from '../components/Atoms/Button/tokens'
import buttonNew from '../components/Atoms/ButtonNew/tokens'
import dropdown from '../components/Atoms/DropDown/tokens'
import error from '../components/Atoms/Error/tokens'
import heading from '../components/Atoms/Heading/tokens'
import input from '../components/Atoms/Input/tokens'
import label from '../components/Atoms/Label/tokens'
import largeIcon from '../components/Atoms/LargeIcon/tokens'
import overlay from '../components/Atoms/Overlay/tokens'
import paragraph from '../components/Atoms/Paragraph/tokens'
import wrapper from '../components/Atoms/Wrapper/tokens'

// Molecules
import collapse from '../components/Molecules/Collapse/tokens'
import checkboxButton from '../components/Molecules/FormComponents/CheckboxButton/tokens'
import checkboxSelector from '../components/Molecules/FormComponents/CheckboxInput/tokens'
import DOB from '../components/Molecules/FormComponents/DOB/tokens'
import formInput from '../components/Molecules/FormComponents/Input/tokens'
import inputBox from '../components/Molecules/FormComponents/InputBox/tokens'
import inputPound from '../components/Molecules/FormComponents/InputPound/tokens'
import inputLabelRight from '../components/Molecules/FormComponents/InputLabelRight/tokens'
import radioInput from '../components/Molecules/FormComponents/RadioInput/tokens'
import stepInput from '../components/Molecules/FormComponents/StepInput/tokens'
import textInput from '../components/Molecules/FormComponents/TextInput/tokens'
import headingWithIcon from '../components/Molecules/HeadingWithIcon/tokens'
import headingWithSubheading from '../components/Molecules/HeadingWithSubheading/tokens'
import helpText from '../components/Molecules/HelpText/tokens'
import iconToggle from '../components/Molecules/IconToggle/tokens'
import iconWithLabel from '../components/Molecules/IconWithLabel/tokens'
import iconWithText from '../components/Molecules/IconWithText/tokens'
import inputGroup from '../components/Molecules/InputGroup/tokens'
import inputWithLabelAbove from '../components/Molecules/InputWithLabelAbove/tokens'
import inputWithLabelTransition from '../components/Molecules/InputWithLabelTransition/tokens'
import polarQuestion from '../components/Molecules/PolarQuestion/tokens'
import tabs from '../components/Molecules/Tabs/tokens'
import tooltip from '../components/Molecules/Tooltip/tokens'
import inputBreakdown from '../components/Molecules/InputBreakdown/tokens'
import pricingCard from '../components/Molecules/PricingCard/tokens'

// Organism
import confirmAddress from '../components/Organisms/Address/ConfirmAddress/tokens'
import findAddress from '../components/Organisms/Address/FindAddress/tokens'
import manualAddress from '../components/Organisms/Address/ManualAddress/tokens'
import modal from '../components/Organisms/Modal/tokens'
import { themeBuilderTypes } from './themeTypes'

class ThemeBuilder {
  theme: themeBuilderTypes

  constructor(name: string) {
    this.theme = {
      name,
      components: {
        anchor: { ...anchor },
        button: { ...button },
        buttonNew: { ...buttonNew },
        dropdown: { ...dropdown },
        error: { ...error },
        heading: { ...heading },
        input: { ...input },
        label: { ...label },
        largeIcon: { ...largeIcon },
        overlay: { ...overlay },
        paragraph: { ...paragraph },
        wrapper: { ...wrapper },
        collapse: { ...collapse },
        checkboxButton: { ...checkboxButton },
        checkboxSelector: { ...checkboxSelector },
        DOB: { ...DOB },
        formInput: { ...formInput },
        inputBox: { ...inputBox },
        inputLabelRight: { ...inputLabelRight },
        inputPound: { ...inputPound },
        radioInput: { ...radioInput },
        stepInput: { ...stepInput },
        textInput: { ...textInput },
        headingWithIcon: { ...headingWithIcon },
        headingWithSubheading: { ...headingWithSubheading },
        helpText: { ...helpText },
        iconToggle: { ...iconToggle },
        iconWithLabel: { ...iconWithLabel },
        iconWithText: { ...iconWithText },
        inputGroup: { ...inputGroup },
        inputWithLabelAbove: { ...inputWithLabelAbove },
        inputWithLabelTransition: { ...inputWithLabelTransition },
        polarQuestion: { ...polarQuestion },
        tabs: { ...tabs },
        tooltip: { ...tooltip },
        confirmAddress: { ...confirmAddress },
        findAddress: { ...findAddress },
        manualAddress: { ...manualAddress },
        modal: { ...modal },
        inputBreakdown: { ...inputBreakdown },
        pricingCard: { ...pricingCard },
        trustpilotRating: { ...trustpilotRating },
      },
    }
  }

  getTheme() {
    return this.theme
  }

  getComponentTokens(component: string) {
    return this.theme.components[component]
  }

  merge(overrides: any) {
    this.theme = deepmerge(this.theme, overrides)
    return this
  }
}

export default ThemeBuilder
