import styled, { css } from 'styled-components'

interface TabItemProps {
  isActive: boolean,
  activeClick?: (arg0: number) => void,
  activeColor?: string
}

const TabItem = styled.button<TabItemProps>`
  ${({ isActive, theme: { components: { tabs: { item } } } }) => css`
    font-family: ${item.fontFamily};
    font-style: ${item.fontStyle};
    font-weight: ${item.fontWeight};
    font-size:  ${item.fontSize};
    line-height:  ${item.lineHeight};
    border-radius:  ${item.borderRadius};
    color: ${isActive ? item.colorActive : item.color};
    padding-left:  ${item.paddingLeft};
    padding-right:  ${item.paddingRight};
    height:  ${item.height};
  `}
  border: 2px solid ${props => props.color};
  background-color: ${props => props.isActive ? props.color : 'transparent'};
  box-sizing: border-box;
  display: inline-block;

  ${props => props.isActive && `background-color: ${props.activeColor || props.color}; border-color: ${props.activeColor || props.color}; `};

  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:focus-visible {
    outline: 1px auto darkblue;
  }
`

const TabsList = styled.ul`
  display: flex;
  gap: 5px;
  padding: 0;
  margin: 0;
  list-style: none;

  flex-wrap: nowrap;
  overflow-x: scroll;

  li {
    flex-shrink: 0;
  }

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  scrollbar-width: none;
`

export {
  TabItem,
  TabsList,
}
