import typography from 'themes/tokens/typography'
import colors from 'themes/tokens/colors'
import alignment from 'themes/tokens/alignment'

export interface headingTypes {
    headingOne?: {
      fontSize?: string
      fontWeight?: number
      color?: string
      textAlign?: string
      lineHeight?: string
      _mediaMobile?: {
        fontSize?: string
      },
    },
    headingTwo?: {
      fontSize?: string
      fontWeight?: number
      color?: string
      textAlign?: string
      lineHeight?: string
    },
    headingThree?: {
      fontSize?: string
      fontWeight?: number
      color?: string
      textAlign?: string
      lineHeight?: string
    },
    headingFour?: {
      fontSize?: string
      fontWeight?: number
      color?: string
      textAlign?: string
      lineHeight?: string
    },
    headingFive?: {
      fontSize?: string
      fontWeight?: number
      color?: string
      textAlign?: string
      lineHeight?: string
    },
    headingSix?: {
      fontSize?: string
      fontWeight?: number
      color?: string
      textAlign?: string
      lineHeight?: string
    }
}

const heading: headingTypes = {
  headingOne: {
    fontSize: typography.fontSizes.fs29,
    fontWeight: typography.fontWeight.regular,
    color: colors.black,
    textAlign: alignment.text.center,
    lineHeight: typography.lineHeights.none,
    _mediaMobile: {
      fontSize: typography.fontSizes.fs22,
    },
  },
  headingTwo: {
    fontSize: typography.fontSizes.fs35,
    fontWeight: typography.fontWeight.regular,
    color: colors.black,
    textAlign: alignment.text.center,
    lineHeight: typography.lineHeights.none,
  },
  headingThree: {
    fontSize: typography.fontSizes.fs19,
    fontWeight: typography.fontWeight.regular,
    color: colors.black,
    textAlign: alignment.text.center,
    lineHeight: typography.lineHeights.none,
  },
  headingFour: {
    fontSize: typography.fontSizes.fs16,
    fontWeight: typography.fontWeight.regular,
    color: colors.black,
    textAlign: alignment.text.center,
    lineHeight: typography.lineHeights.none,
  },
  headingFive: {
    fontSize: typography.fontSizes.fs13,
    fontWeight: typography.fontWeight.regular,
    color: colors.black,
    textAlign: alignment.text.center,
    lineHeight: typography.lineHeights.none,
  },
  headingSix: {
    fontSize: typography.fontSizes.fs13,
    fontWeight: typography.fontWeight.regular,
    color: colors.black,
    textAlign: alignment.text.center,
    lineHeight: typography.lineHeights.none,
  },
}

export default heading
