import React, { useRef, useState, useEffect } from 'react'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import {
  IconToggleElement,
  Checkbox,
  ToggleArea,
  Label,
  Toggle,
} from './style'

export type IconToggleProps = {
  css?: string,
  children?: React.ReactNode,
  id: string,
  dlClick: (e: any) => void,
}

export const IconToggle: React.FC<IconToggleProps> = ({
  css,
  children,
  id,
  dlClick,
}) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const labelEl = useRef<HTMLInputElement>(null)

  const outsideOnClick = (e: any) => {
    const target = e.target as Element
    const { current: labelCurrent } = labelEl
    if (labelCurrent !== null) {
      if (labelCurrent.contains(target)) {
        // inside click
        return
      }
      // outside click
      setOpen(false)
    }
  }

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', outsideOnClick)
      document.addEventListener('touchstart', outsideOnClick)
    } else {
      document.removeEventListener('mousedown', outsideOnClick)
      document.removeEventListener('touchstart', outsideOnClick)
    }
    return () => {
      document.removeEventListener('mousedown', outsideOnClick)
      document.removeEventListener('touchstart', outsideOnClick)
    }
  }, [open])

  const handleClick = (e: any) => {
    setOpen(prevState => !prevState)
    dlClick(e)
  }

  return (
    <IconToggleElement css={css}>
      <Checkbox
        ref={inputEl}
        type="checkbox"
        name={`${id}_check`}
        id={`${id}_check`}
        checked={open}
        onChange={() => null}
      />
      <Label ref={labelEl} htmlFor={`${id}_check`}>
        <Toggle onClick={(e) => handleClick(e)}>
          <IoIosHelpCircleOutline />
        </Toggle>
      </Label>
      <ToggleArea>
        {children}
      </ToggleArea>
    </IconToggleElement>
  )
}
