import React from 'react'
import { InputHidden, Item } from './style'

export type RadioInputItemProps = {
  title?: string,
  value: string,
  group: string,
  onChange: () => void,
  checked: boolean,
}

export const RadioItem: React.FC<RadioInputItemProps & React.HTMLAttributes<HTMLInputElement>> = ({
  title,
  value,
  group,
  onChange,
  checked,
}) =>
  (
    <div id={value}>
      <InputHidden
        type="radio"
        name={group}
        value={value}
        onChange={onChange}
        onInput={onChange}
        id={group + value}
        checked={checked}
      />
      <Item htmlFor={group + value} id={group + value}>
        {title}
      </Item>
    </div>
  )
